import { useDispatch, useSelector } from "react-redux";
import TrainingsModal from "./TrainingsModal";
import TrainingsTableSearch from "./TrainingsTableSearch";
import TrainingsTable from "./TrainingsTable";
import "./styles/Trainings.css";
import { getAllTrainingsRequest, initiliseTrainingsReduerState } from "../../../../../../redux/actions/adminAccount-actions/trainings-actions";
import { useEffect, useState } from "react";
// import ModalMainContainer from "../../../../../ModalNotification/ModalMainContainer";
// import CircularProgressLoader from "../../../../../../components/common/CircularProgressLoader";
import AdminMainArticleLoader from "../../AdminMainArticleLoader/AdminMainArticleLoader";
import Success from "../SuccessFailureModal/Success";
import Failure from "../SuccessFailureModal/Failure";


export default function Trainings() {
    const [isTrainingsModalOpen, setTrainingsModalOpen] = useState(false);
    // const [isModalOpen, setIsModalOpen] = useState(false);
    const [rowData, setRowData] = useState({});
    const [isEditRequest, setIsEditRequest] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [afterSubmitModal, setAfterSubmitModal] = useState(false);
    // const [modalMessage, setModalMessage] = useState([]);
    // const [isSubmitSuceess, setIsSubmitSuccess] = useState(false);
    // const [isSubmitActive, setIsSubmitActive] = useState(false);
    // const [deleteTrainingId, setDeleteTrainingId] = useState(null);


    const dispatch = useDispatch();

    const { isRequestCompleted, isRequestSuccess, message, isLoader } = useSelector((state) => ({
        isRequestCompleted: state.TrainingsReducer.isRequestCompleted,
        isRequestSuccess: state.TrainingsReducer.isRequestSuccess,
        message: state.TrainingsReducer.message,
        isLoader: state.TrainingsReducer?.isLoader
    }));

    console.log(isRequestCompleted, isRequestSuccess, message, isLoader);

    useEffect(() => {
        dispatch(getAllTrainingsRequest());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    useEffect(() => {
        if (isRequestCompleted) {
            setAfterSubmitModal(true);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },
        [isRequestCompleted]);
    const editTrainingsModal = (rowData) => {
        setIsEditRequest(true);
        setRowData(rowData);
        setTrainingsModalOpen(true);
    };
    // const handleDelete = (trainingsId) => {
    //     setDeleteTrainingId(trainingsId);
    //     setIsSubmitActive(false);
    //     setModalMessage(["Are you sure you want to delete this Training?"]);
    //     setIsModalOpen(true);
    // };
    const toggleOpenTrainingsModal = () => {
        setIsEditRequest(false);
        setTrainingsModalOpen(!isTrainingsModalOpen);
    };

    // const handleReactivate = () => {
    //     setIsModalOpen(false);
    //     // dispatch(deleteTrainingsRequest(deleteTrainingId));
    // };
    const handelDataRefresh = () => {
        dispatch(initiliseTrainingsReduerState());
        dispatch(getAllTrainingsRequest());
        // dispatch(resetReducerCorrespondingToCountries());
    };

    if (isLoader) {
        return (<AdminMainArticleLoader />);
    }
    return (< div className="trainingsContainer" >
        <TrainingsTableSearch
            openTrainingsModal={toggleOpenTrainingsModal}
            setSearchText={setSearchText}
        />
        <TrainingsModal
            isModalOpen={isTrainingsModalOpen}
            toggleOpenTrainingsModal={toggleOpenTrainingsModal}
            rowData={rowData}
            isEditRequest={isEditRequest}
        />
        <TrainingsTable
            openTrainingsModal={editTrainingsModal}
            searchText={searchText}
        />
        {/* <ModalMainContainer
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            isSubmitActive={false}
            modalMessage={modalMessage}
            isSubmitSuceess={isSubmitSuceess}
            reactivate={false}
            handleReactivate={handleReactivate}
            toggleToLandingPage={() => {
                setIsModalOpen(false);
                dispatch(getAllTrainingsRequest());
            }}
        /> */}
        {isRequestCompleted ? isRequestSuccess ? (
            <Success
                isModalOpen={afterSubmitModal}
                setIsModalOpen={setAfterSubmitModal}
                modalMessage={message}
                refreshDataAction={handelDataRefresh}
                scrollerMessage={false}
            />
        ) : (
            <Failure
                isModalOpen={afterSubmitModal}
                setIsModalOpen={setAfterSubmitModal}
                modalMessage={message}
                refreshDataAction={handelDataRefresh}
                scrollerMessage={false}
            />
        ) : ''}
    </div >
    );




}
