import { selectAffiliate_actionType } from "../../actionTypes/userAction-actionTypes/selectAffiliate-actionType";
import { requiredFieldAvailableSelectAffiliate } from "./utility/requiredFieldsSelectAffiliate";

const initialState = {
  data: {
      selectedAffiliateType: "",
      selectedAffiliateTypeAbbrebiation:""
  },
  requiredFieldAvailable: false,
  error: undefined
};

const SelectAffiliateReducer = (state = initialState, action) => {
  switch (action.type) {
    case selectAffiliate_actionType.SET_SELECT_AFFILIATE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        requiredFieldAvailable: requiredFieldAvailableSelectAffiliate(
          action.payload
        ),
        error: undefined
      };
    case selectAffiliate_actionType.SET_SELECT_AFFILIATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        data: initialState
      };
    case selectAffiliate_actionType.GET_SELECT_AFFILIATE_SUCCESS:
      return {
        ...state.data
      };
      case selectAffiliate_actionType.RESET_SELECT_AFFILIATE_SUCCESS:
        return {
          ...initialState
        };
    case selectAffiliate_actionType.GET_SELECT_AFFILIATE_FAILURE:
      return {
        ...state.error
      };
    default:
      return state;
  }
};

export default SelectAffiliateReducer;
