import { axiosApigateway } from "../../../api";
import { affiliate_actionType } from "../../actionTypes/adminAction-actionTypes/affiliate-actionType";
import { setLoaderActive, setLoaderInActive } from "../loaderAction";

// seting loader on initially
export function setLoader() {
  return (dispatch) => {
    dispatch({
      type: affiliate_actionType.SET_LOADER
    });
  };
}

// reseting loader after getting api response
export function resetLoader() {
  return (dispatch) => {
    dispatch({
      type: affiliate_actionType.RESET_LOADER
    });
  };
}

function waitOneSecond() {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 3000);
  });
}

// initial call for getting table content
export function getAllAffilateData() {
  return async (dispatch) => {
    dispatch(setLoader());
    try {
      // calling hubs API

      const response = await axiosApigateway.get("/hubs");
      const data = response?.data?.data;
      dispatch({
        type: affiliate_actionType.GET_ALL_AFFILIATE_SUCCESS,
        data: data
      });
    } catch (error) {
      dispatch({
        type: affiliate_actionType.GET_ALL_AFFILIATE_FAILURE,
        data: error?.message || "Something went wrong, Please try Again!"
      });
    } finally {
      dispatch(resetLoader());
    }
  };
}

// get all countries
// export function getCountries() {
//     return async (dispatch) => {
//         try {
//             // calling hubs API
//             const response = await axiosApigateway.get("/countries?vamHubAbbreviation=null");
//             const data = response?.data?.data;
//             dispatch({
//                 type: affiliate_actionType.GET_ALL_COUNTRIES_SUCCESS,
//                 data: data
//             });

//         } catch (error) {
//             dispatch({
//                 type: affiliate_actionType.GET_ALL_COUNTRIES_FAILURE,
//                 data: error
//             });
//         }
//     };
// }

// add new affiliate by add click event
export function addAffiliate(postData) {
  return async (dispatch) => {
    dispatch({
      type: affiliate_actionType.SET_LOADER
    });
    try {
      // calling hubs API
      const response = await axiosApigateway.post("/hubs", postData);
      if (response) {
        dispatch({
          type: affiliate_actionType.ADD_AFFILIATE_SUCCESS,
          data: response?.data?.message || "Affiliate successfully added."
        });
      }
      // setTimeout(() => {
      await waitOneSecond(); // so next call will bring new data
      dispatch(getAllAffilateData());
      // }, 1000);
    } catch (error) {
      dispatch({
        type: affiliate_actionType.ADD_AFFILIATE_FAILURE,
        data: error?.message || "Something went wrong, Please try Again!"
      });
    } finally {
      dispatch({
        type: affiliate_actionType.RESET_LOADER
      });
    }
  };
}

export function editAffiliate(vamHubAbbreviation, postData) {
  return async (dispatch) => {
    dispatch({
      type: affiliate_actionType.SET_LOADER
    });
    try {
      // calling hubs API
      const response = await axiosApigateway.put(
        "/hubs/" + vamHubAbbreviation,
        postData
      );
      if (response) {
        dispatch({
          type: affiliate_actionType.ADD_AFFILIATE_SUCCESS,
          data: response?.data?.message || "Affiliate successfully added."
        });
      }
      // setTimeout(() => {
      await waitOneSecond(); // so next call will bring new data
      dispatch(getAllAffilateData());
      // }, 1000);
    } catch (error) {
      dispatch({
        type: affiliate_actionType.ADD_AFFILIATE_FAILURE,
        data: error?.message || "Something went wrong, Please try Again!"
      });
    } finally {
      dispatch({
        type: affiliate_actionType.RESET_LOADER
      });
    }
  };
}

// export function editAffiliateInCountrieList(markSelectedCountryIDList, markNotSelectedCountryIDList, vamHubAbbreviation) {
//     return async (dispatch) => {
//         dispatch(setLoaderActive());
//         let successForCountries = [];
//         let failureForCountries = [];
//         // calling hubs API for each country

//         // set vamHubAbbreviation to each country
//         markSelectedCountryIDList.forEach(async (countryObject) => {
//             try {
//                 await axiosApigateway.put(`/countries/${countryObject.countryId}`, vamHubAbbreviation);
//                 successForCountries.push(countryObject.countryName);
//             } catch (error) {
//                 failureForCountries.push(countryObject.countryName);
//             }
//         });

//         // reset vamHubAbbreviation to each country
//         markNotSelectedCountryIDList.forEach(async (countryObject) => {
//             try {
//                 await axiosApigateway.put(`/countries/${countryObject.countryId}`, { vamHubAbbreviation: null });
//                 successForCountries.push(countryObject.countryName);
//             } catch (error) {
//                 failureForCountries.push(countryObject.countryName);
//             }
//         });

//         if (failureForCountries.length === (markNotSelectedCountryIDList.length + markSelectedCountryIDList)) {
//             // request failed for all countries
//             dispatch({
//                 type: affiliate_actionType.EDIT_AFFILIATE_IN_COUNTRIES_FAILURE,
//                 data: "Request failed due to bad request."
//             });
//         } else {
//             dispatch({
//                 type: affiliate_actionType.EDIT_AFFILIATE_IN_COUNTRIES_SUCCESS,
//                 data: "Country(s) successfully updated.",
//                 successForCountries,
//                 failureForCountries
//             });
//         }
//         setTimeout(() => {
//             dispatch(setLoaderInActive());
//         }, 3000);
//         return true;
//     };
// }

export function resetReducerCorrespondingToCountries() {
  return async (dispatch) => {
    dispatch({
      type: affiliate_actionType.RESET_REDUCER_CORRESPONDING_TO_COUNTRIES
    });
  };
}

export function changeAffiliateActivationRequest(
  vamHubAbbreviation,
  activationStatus
) {
  return async (dispatch) => {
    const payload = {
      isActive: activationStatus
    };

    dispatch(setLoaderActive());
    try {
      const response = await axiosApigateway.put(
        "/hubs/" + vamHubAbbreviation,
        payload
      );
      if (response) {
        dispatch({
          type: affiliate_actionType.EDIT_AFFILIATE_ACTIVATION_SUCCESS,
          data: activationStatus
            ? "Affiliate is Activated Successfully"
            : "Affiliate is Deactivated Successfully"
          // data: response?.data?.message || 'Affiliate Successfully Edited!'
        });
      }
    } catch (error) {
      console.log(error?.response?.data?.data);
      dispatch({
        type: affiliate_actionType.EDIT_AFFILIATE_ACTIVATION_FAILURE,
        data:
          error?.response?.data?.data ||
          "Something went wrong, Please try Again!"
      });
    } finally {
      dispatch(setLoaderInActive());
      dispatch(getAllAffilateData());
    }
  };
}
