import { summary_actionType } from '../../actionTypes/userAction-actionTypes/summary-actionType';

const initialState = {
  trainings: [],
  comments: '',
  trainingCompletionDate: null,
  isExistingDataSet: false,
  existingState: [],
  trainingAvailable: false
};

const SummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case summary_actionType.SET_TRAININGS_SUCCESS:
      return {
        ...state,
        trainings: action.data
      };
    case summary_actionType.GET_TRAININGS_SUCCESS:
      return {
        ...state,
        trainings: action.data
      };
    case summary_actionType.SET_TRAINING_AVAILABLE_SUCCESS:
      return {
        ...state,
        trainingAvailable: action.data
      };
    case summary_actionType.SET_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: action.data
      };
    case summary_actionType.SET_TRAINING_COMPLETITION_DATE_SUCCESS:
      return {
        ...state,
        trainingCompletionDate: action.data
      };
    case summary_actionType.SET_EXISTINGSTATE_SUCCESS:
      return {
        ...state,
        existingState: JSON.stringify(action.data),
        isExistingDataSet: true
      };
    case summary_actionType.RESET_EXISTINGSTATE_SUCCESS:
      return {
        ...state,
        existingState: [],
        isExistingDataSet: false
      };
    case summary_actionType.RESET_SUMMARY_SUCCESS:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default SummaryReducer;