import { CircularProgress } from "@mui/material";
import React from "react";
import "./style.css";

const CircularProgressLoader = () => {
  return (
    <div className="loader-container-circular">
      <CircularProgress color="inherit" />
    </div>
  );
};

export default CircularProgressLoader;
