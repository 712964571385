import { GATEWAY_SCOPES_TYPES } from './gateway-vars';

const defaultOpenIdCallback = '/auth/callback';
const defaultPostLogOutRedirectUri = '/logout';
const defaultAuthority = 'https://login.microsoftonline.com/18a59a81-eea8-4c30-948a-d8824cdc2580';

const DEFAULT_PKCE_CONFIG_VARS = {
  AUTH: {
    OPENID_CALLBACK: defaultOpenIdCallback,
    AUTHORITY: defaultAuthority,
    SCOPES: ['openid', 'profile', 'user.read', 'email'],
    OPENID_POST_LOGOUT_REDIRECT_URI: defaultPostLogOutRedirectUri
  },
  CACHE: {
    CACHE_LOCATION: 'sessionStorage',
    STORE_AUTH_STATE_IN_COOKIE: 'false'
  },
  GATEWAY: {
    IS_GATEWAY_SCOPE_REQUIRED: false,
    GATEWAY_SCOPE_TYPE: GATEWAY_SCOPES_TYPES.NON_PRODUCTION
  },
  LOGGING: {
    LOG_LEVEL: 'INFO'
  }
};

Object.freeze(DEFAULT_PKCE_CONFIG_VARS);

export { DEFAULT_PKCE_CONFIG_VARS };