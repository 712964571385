import React, { useEffect, useState } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Button, Chip, Stack } from "@mui/material";

const RolesFilterModalNotice = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    filterOptions,
    filteredOptionsInitialState,
    setFilterOptions
  } = props;
  const [data, setData] = useState(filteredOptionsInitialState);

  useEffect(() => {
    const localDataAvailable = JSON.stringify(filterOptions);
    setData(JSON.parse(localDataAvailable));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  const clearFilterHandler = () => {
    let tempData = [...data];
    tempData.forEach((primaryDataObject) => {
      primaryDataObject.values.forEach(
        (secondaryDataObject) => (secondaryDataObject.selected = false)
      );
    });
    setData(tempData);
  };

  const optionHandler = (primaryIndex, secondaryIndex) => {
    let tempData = [...data];
    tempData[primaryIndex].values[secondaryIndex].selected =
      !tempData[primaryIndex].values[secondaryIndex].selected;
    setData(tempData);
  };

  const applyFilterHandler = () => {
    setFilterOptions(data);
    setIsModalOpen(false);
  };

  return (
    <div className="roles_modalCard">
      <div className="roles_modalCardHeader">
        <span>Search Filter</span>
        <span>
          <CancelOutlinedIcon
            sx={{ fontSize: "1.5rem", cursor: "pointer" }}
            onClick={() => setIsModalOpen(false)}
          />
        </span>
      </div>
      <div className="roles_modalCardBody">
        <div className="roles_Type">
          {data.map((primaryDataObject, primaryIndex) => {
            return (
              <>
                <span className="roles_TypeHeading">
                  {primaryDataObject.name}
                </span>
                <Stack
                  direction="row"
                  sx={{
                    flexWrap: "wrap",
                    maxHeight: "100px",
                    overflowY: "scroll"
                  }}
                >
                  {primaryDataObject.values.map(
                    (secondaryDataObject, secondaryIndex) => (
                      <Chip
                        key={secondaryIndex}
                        label={secondaryDataObject.name}
                        variant="outlined"
                        onClick={() =>
                          optionHandler(primaryIndex, secondaryIndex)
                        }
                        className={`roles_TypeChip ${
                          data[primaryIndex].values[secondaryIndex].selected
                            ? "roles_TypeChipSelected"
                            : ""
                        }`}
                      ></Chip>
                    )
                  )}
                </Stack>
              </>
            );
          })}
        </div>
      </div>
      <div className="roles_modalCardFooter">
        <Button
          variant="outlined"
          color="error"
          className="modalBtnClear"
          onClick={clearFilterHandler}
        >
          Clear
        </Button>
        <Button
          variant="outlined"
          color="success"
          className="modalBtnApply"
          onClick={applyFilterHandler}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default RolesFilterModalNotice;
