import { setAdminDashboardLoaderActive, setAdminDashboardLoaderInActive } from "../loaderAction";
import { adminDashboard_actionType } from "../../actionTypes/adminAction-actionTypes/adminDashboard-actionType";
import { axiosApigateway } from "../../../api";

export function resetAdminDashboardReducer() {
    return (dispatch) => {
        dispatch({
            type: adminDashboard_actionType.RESET_DASHBOARD_SUCCESS
        });
    };
}

const getRequestType = (responseRequestType) => {
    switch (responseRequestType) {
        case "create":
            return "Create Account";
        case "modify":
            return "Modify Account";
        case "reactivate":
            return "Reactivate Account";
        case "deactivate":
            return "Deactivate Account";
        default:
            return "Unknown";
    }
};

const getCurrentStatus = (responseCurrentStatus) => {
    switch (responseCurrentStatus) {
        case "complete":
            return "Completed";
        case "pending":
            return "Pending";
        case "rejected":
            return "Rejected";
        case "failed":
            return "Failed";
        case "approved":
            return "Approved";
        case "terminated":
            return "Terminated";
        default:
            return "Unknown";

    }
};

const createFormatedData = (responseData) => {
    let data = [];
    responseData.data.forEach(element => {
        let object = {
            requestID: element.request_id,
            userName: element.request_payload?.email,
            fullName: element.request_payload?.display_name,
            globalID: element.request_payload?.federated_id,
            userType: element.request_payload?.user_type,
            requestType: getRequestType(element.requestType),
            currentStatus: getCurrentStatus(element.request_status),
            affiliate: element.request_payload?.users_access_payload?.name,
            lastActionDate: element.updatedAt
        };
        data.push(object);
    });

    return data;
};

export function getAdminDashboardAllRequests() {
    return async (dispatch) => {
        dispatch(setAdminDashboardLoaderActive());

        try {
            // calling API
            const response = await axiosApigateway.get('/admin-vvmc-dashboard');
            const responseAdminRequestDetail = await axiosApigateway.get('/admin-vvmc-request-details');
            if (response && responseAdminRequestDetail) {
                let requiredData = createFormatedData(response.data);
                dispatch({
                    type: adminDashboard_actionType.GET_DASHBOARD_SUCCESS,
                    data: requiredData,
                    responseStore: response.data,
                    filterQuery: getUniqueFilterData(requiredData)
                });
                dispatch({
                    type: adminDashboard_actionType.GET_ADMIN_REQUEST_DETAIL_SUCCESS,
                    data: responseAdminRequestDetail.data
                });
                dispatch(setAdminDashboardLoaderInActive());
                return { response, success: true };
            }
        } catch (error) {
            dispatch({
                type: adminDashboard_actionType.GET_DASHBOARD_FAILURE,
                data: error
            });
            dispatch(setAdminDashboardLoaderInActive());
            return { success: false };
        }
    };
}

const getUniqueFilterData = (allRequests) => {
    let uniqueAffiliateType = new Set();
    let uniqueUserType = new Set();
    let uniqueRequestType = new Set();
    let uniqueCurrentStatus = new Set();

    let uniqueAffiliateTypeList = [];
    let uniqueUserTypeList = [];
    let uniqueRequestTypeList = [];
    let uniqueCurrentStatusList = [];
    let filterQuery = filterQueryInitialState;

    // getting unique values
    allRequests.forEach(element => {
        uniqueAffiliateType.add(element.affiliate);
        uniqueUserType.add(element.userType);
        uniqueRequestType.add(element.requestType);
        uniqueCurrentStatus.add(element.currentStatus);
    });

    //converting to list
    uniqueAffiliateType.forEach(element => {
        if (element)
            uniqueAffiliateTypeList.push(element);
    });
    uniqueUserType.forEach(element => {
        if (element)
            uniqueUserTypeList.push(element);
    });
    uniqueRequestType.forEach(element => {
        if (element)
            uniqueRequestTypeList.push(element);
    });
    uniqueCurrentStatus.forEach(element => {
        if (element)
            uniqueCurrentStatusList.push(element);
    });

    filterQuery[0].value = uniqueAffiliateTypeList.map((object) => {
        return {
            name: object,
            checked: false
        };
    });
    filterQuery[1].value = uniqueUserTypeList.map((object) => {
        return {
            name: object,
            checked: false
        };
    });
    filterQuery[2].value = uniqueRequestTypeList.map((object) => {
        return {
            name: object,
            checked: false
        };
    });
    filterQuery[3].value = uniqueCurrentStatusList.map((object) => {
        return {
            name: object,
            checked: false
        };
    });

    return filterQuery;
};

const filterQueryInitialState = [
    {
        abbrebiation: "affiliateType",
        name: "Affiliate Type",
        value: []
    },
    {
        abbrebiation: "userType",
        name: "User Type",
        value: []
    },
    {
        abbrebiation: "requestType",
        name: "Request Type",
        value: []
    },
    {
        abbrebiation: "currentStatus",
        name: "Status",
        value: []
    }
];