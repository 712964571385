/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import { useDispatch } from "react-redux";
import {
  setSelectedWorkFlowRoles,
  setUserAccessRolesModified
} from "../../redux/actions/userAccount-actions/workFlowRoles-action";

export const WorkFlow = (props) => {
  const {
    workFlowType,
    roles,
    affiliateType,
    selectedRoles,
    userAccessResources,
    allAffiliatesResources
  } = props;
  const dispatch = useDispatch();
  const [rolesSelected, setRolesSelected] = useState([]);
  const [activeRoleSection, setActiveRoleSection] = useState(1);
  const totalRoles = roles?.length;
  const rolesPerSection = 15;
  const totalRoleSection = Math.ceil(totalRoles / rolesPerSection);
  const displayRoles = roles?.slice(
    (activeRoleSection - 1) * rolesPerSection,
    activeRoleSection * rolesPerSection
  );

  const nextRoleSectionHandler = () => {
    setActiveRoleSection(activeRoleSection + 1);
  };
  const previousRoleSectionHandler = () => {
    setActiveRoleSection(activeRoleSection - 1);
  };

  let userAccessRoles = [];

  if (
    userAccessResources &&
    Array.isArray(userAccessResources) &&
    userAccessResources.length &&
    allAffiliatesResources &&
    Array.isArray(allAffiliatesResources) &&
    allAffiliatesResources.length
  ) {
    userAccessResources.map((resource) => {
      const affiliate = allAffiliatesResources?.find(
        (affiliate) =>
          affiliate?.affiliateType?.abbreviation ===
          resource?.user?.vamHubAbbreviation
      );
      const affiliateType = affiliate?.affiliateType?.name;

      resource.roles.map((role) => {
        const roleMapToWorkFlowType = {
          workFlowType: affiliateType,
          role: role
        };
        userAccessRoles.push(roleMapToWorkFlowType);
      });
    });
  }

  useEffect(() => {
    dispatch(setSelectedWorkFlowRoles([...selectedRoles]));
    setRolesSelected(selectedRoles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modifiedWorkFlow = JSON.stringify({
    roles: rolesSelected
  });

  useEffect(() => {
    const modifiedWorkFlowParsed = JSON.parse(modifiedWorkFlow);
    const payload = modifiedWorkFlowParsed.roles;
    // console.log(payload);
    dispatch(setSelectedWorkFlowRoles([...payload]));
  }, [modifiedWorkFlow]);

  const changeRoleHandler = (event, role) => {
    const { checked } = event.target;
    let newArr;
    if (typeof checked === "undefined") {
      newArr = rolesSelected?.filter(
        (rle) => rle.role.vam_role_id !== role.vam_role_id
      );
    } else {
      newArr = checked
        ? [...rolesSelected, { workFlowType, role }]
        : rolesSelected?.filter(
            (rle) =>
              rle.workFlowType === workFlowType &&
              rle.role.vam_role_id !== role.vam_role_id
          );
    }
    setRolesSelected(newArr);
    dispatch(setUserAccessRolesModified(true));
  };

  const renderSelectUserRoles = () => {
    return (
      <div className="workflow_selectUserRoles" style={{ margin: "1px" }}>
        <span className="workflow_selectUserRolesHead">Select User Roles</span>
        <div className="workFlow_body">
          <>
            <div className="workflow_arrowContainer">
              {activeRoleSection > 1 ? (
                <ArrowCircleLeftRoundedIcon
                  className="workflow_arrowIcon"
                  onClick={previousRoleSectionHandler}
                  sx={{ fontSize: "2.8rem" }}
                />
              ) : null}
            </div>
            <Grid container className="workflow_rolesContainer">
              {displayRoles && displayRoles?.length
                ? displayRoles.map((role) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={role.vam_role_id}
                      className="workFlow_element"
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                rolesSelected?.find(
                                  (rle) =>
                                    rle.workFlowType === workFlowType &&
                                    rle.role.vam_role_id === role.vam_role_id
                                )
                                  ? true
                                  : false
                              }
                              onChange={(event) =>
                                changeRoleHandler(event, role)
                              }
                              sx={{
                                "&.Mui-checked": {
                                  color: "#00AE43"
                                }
                              }}
                              className="rolesCheckboxClass"
                            />
                          }
                          label={
                            <Typography
                              className="rolesCheckboxLabelClass"
                              style={{
                                fontSize: "0.95rem",
                                "&:hover": { color: "red" }
                              }}
                            >
                              <Tooltip
                                arrow
                                title={role?.description}
                                placement="top"
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      color: "#000",
                                      backgroundColor: "#fff",
                                      fontSize: "0.9rem",
                                      border: "2px solid #707070"
                                    }
                                  }
                                }}
                              >
                                {role.name}
                              </Tooltip>
                            </Typography>
                          }
                        />
                      </FormGroup>
                    </Grid>
                  ))
                : null}
            </Grid>
            <div className="workflow_arrowContainer">
              {activeRoleSection < totalRoleSection ? (
                <ArrowCircleRightRoundedIcon
                  className="workflow_arrowIcon"
                  sx={{
                    fontSize: "2.8rem"
                  }}
                  onClick={nextRoleSectionHandler}
                />
              ) : null}
            </div>
          </>
        </div>
        {rolesSelected &&
        rolesSelected?.length &&
        affiliateType === workFlowType ? (
          <div className="workflow_userRolesSelected">
            <div className="workflow_selectUserRolesParent">
              <span className="workflow_selectUserRolesHead">
                Selected User Roles
              </span>
              <span className="workflow_selectUserRolesAction">
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    setRolesSelected([]);
                  }}
                  sx={{
                    padding: "0.5rem 1rem",
                    ":hover": {
                      bgcolor: "red",
                      color: "white"
                    }
                  }}
                >
                  Delete All
                </Button>
              </span>
            </div>
            <Stack
              direction="row"
              key={roles?.id}
              sx={{ flexWrap: "wrap", width: "97%", margin: "0.5rem auto" }}
            >
              {rolesSelected
                .filter((role) => role.workFlowType === workFlowType)
                .map((role, idx) => (
                  <Chip
                    key={idx}
                    deleteIcon={
                      <CancelOutlinedIcon
                        sx={{
                          "&.MuiChip-deleteIcon": {
                            color: "#df271b",
                            fontSize: "1.4rem",
                            "&:hover": {
                              color: "#df271b"
                            }
                          }
                        }}
                      />
                    }
                    label={role.role.name}
                    variant="outlined"
                    onDelete={(event) => changeRoleHandler(event, role.role)}
                    sx={{ margin: "0.5rem" }}
                    className="rolesChipClass"
                  />
                ))}
            </Stack>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="workFlowCard">
      <div className="workFlowCard_header">
        <span className="workflow_headWithSelection">
          <span>Affiliate</span>
          <span className="workFlow_selected">
            {workFlowType?.toUpperCase()}
          </span>
        </span>
      </div>
      {roles?.length ? renderSelectUserRoles() : null}
    </div>
  );
};
