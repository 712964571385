import { axiosApigateway } from "../../../../api";
import { rolesRequestList_actionType } from "../../../actionTypes/adminAction-actionTypes/role-actionTypes/rolesRequestList-actionType";

export function rolesRequestList() {
  return {
    type: rolesRequestList_actionType.ROLES_REQUEST_LIST_START
  };
}

export function rolesRequestListSuccess(data) {
  return {
    type: rolesRequestList_actionType.ROLES_REQUEST_LIST_SUCCESS,
    payload: data,
    csvData: getCSVData(data),
    filterOptions: getUniqueFilterOptions(data)
  };
}

export function rolesRequestListError(error) {
  return {
    type: rolesRequestList_actionType.ROLES_REQUEST_LIST_ERROR,
    payload: error
  };
}

function getGroups(data) {
  let dataList = [];
  data.forEach((dataObject) => {
    const formattedDataObject = {
      idPk: dataObject?.id_pk ?? "",
      name: dataObject?.name ?? "",
      description: dataObject?.description ?? "",
      isDynamicProduct: dataObject?.isDynamicProduct,
      isDynamicCountry: dataObject?.isDynamicCountry
    };
    dataList.push(formattedDataObject);
  });
  return dataList;
}

function getTrainings(data) {
  let dataList = [];
  data.forEach((dataObject) => {
    const formattedDataObject = {
      id: dataObject?.id ?? "",
      isActive: dataObject?.isActive,
      description: dataObject?.description ?? "",
      isCurriculam: dataObject?.isCurriculam ?? "",
      trainingName: dataObject?.trainingName ?? ""
    };
    dataList.push(formattedDataObject);
  });
  return dataList;
}

function getRolesRequestList(data) {
  let dataList = [];
  data.forEach((dataObject) => {
    const formattedDataObject = {
      vamRoleId: dataObject?.vam_role_id ?? "",
      name: dataObject?.name ?? "",
      description: dataObject?.description ?? "",
      isElevatedRole: dataObject?.isElevatedRole,
      isRequireProducts: dataObject?.isRequireProducts,
      roleAudience: dataObject?.roleAudience,
      license: dataObject?.license,
      securityProfile: dataObject?.securityProfile,
      securityPolicy: dataObject?.securityPolicy,
      isActive: dataObject?.isActive,
      // affiliateName: dataObject?.vam_hub?.name ?? "",
      vamHubAbbreviation: dataObject?.vamHubAbbreviation ?? "",
      vamGroups: dataObject?.vam_groups ? getGroups(dataObject?.vam_groups) : [],
      vamTrainings: dataObject?.vam_trainings ? getTrainings(dataObject?.vam_trainings) : []
    };
    dataList.push(formattedDataObject);
  });
  return dataList;
}

export function rolesRequestListCall() {
  return async (dispatch) => {
    dispatch(rolesRequestList());
    try {
      const response = await axiosApigateway.get("/roles");
      if (response) {
        const responseData = response.data.data;
        const data = getRolesRequestList(responseData);
        console.log(data);
        dispatch(rolesRequestListSuccess(data));
      }
    } catch (error) {
      dispatch(rolesRequestListError(error));
    }
  };
}

function getCSVData(allRequests) {
  let data = [];
  const headers = [
    { label: "Sr No.", key: "srNo" },
    { label: "Name", key: "name" },
    { label: "Description", key: "description" },
    { label: "Elevated Role", key: "isElevatedRole" },
    { label: "User Type", key: "roleAudience" },
    { label: "License", key: "license" },
    { label: "Security Profile", key: "securityProfile" },
    // { label: "Security Policy", key: "securityPolicy" },
    { label: "Affiliate", key: "vamHubAbbreviation" },
    { label: "Active", key: "isActive" },
    { label: "Groups", key: "vamGroups" },
    { label: "Trainings", key: "vamTrainings" }
  ];

  allRequests.map((reqObj, reqObjIdx) => {
    const vamGroupsNameList = reqObj?.vamGroups.map(grpObj => {
      return grpObj.name;
    }).join(", ") ?? "";

    const vamTrainingsNameList = reqObj?.vamTrainings.map(trObj => {
      return trObj.trainingName;
    }).join(", ") ?? "";

    const obj = {
      srNo: reqObjIdx + 1,
      name: reqObj?.name ?? "",
      description: reqObj?.description ?? "",
      isElevatedRole: reqObj?.isElevatedRole ? "Yes" : "No",
      isRequireProducts: reqObj?.isRequireProducts ? "Yes" : "No",
      roleAudience: reqObj?.roleAudience,
      license: reqObj?.license,
      securityProfile: reqObj?.securityProfile,
      // securityPolicy: reqObj?.securityPolicy,
      isActive: reqObj?.isActive ? "Yes" : "No",
      // affiliateName: reqObj?.vamHubAbbreviation ?? "",
      vamHubAbbreviation: reqObj?.vamHubAbbreviation ?? "",
      vamGroups: vamGroupsNameList,
      vamTrainings: vamTrainingsNameList
    };
    data.push(obj);
  });

  return {
    headers,
    data
  };
}


const getUniqueFilterOptions = (allRequests) => {
  // let uniqueRoleAudience = new Set();
  let uniqueLicense = new Set();
  let uniqueSecurityProfile = new Set();
  let uniqueAffiliate = new Set();
  // let uniqueSecurityPolicy = new Set();

  // let uniqueRoleAudienceList = [];
  let uniqueLicenseList = [];
  let uniqueSecurityProfileList = [];
  let uniqueAffiliateList = [];
  // let uniqueSecurityPolicyList = [];
  let filterOptions = filteredOptionsInitialState;

  // getting unique values
  allRequests.forEach(element => {
    // uniqueRoleAudience.add(element.roleAudience);
    uniqueLicense.add(element.license);
    uniqueSecurityProfile.add(element.securityProfile);
    uniqueAffiliate.add(element.vamHubAbbreviation);
    // uniqueSecurityPolicy.add(element.securityPolicy);
  });

  //converting to list
  // uniqueRoleAudience.forEach(element => {
  //   if (element)
  //     uniqueRoleAudienceList.push(element);
  // });
  uniqueLicense.forEach(element => {
    if (element)
      uniqueLicenseList.push(element);
  });
  uniqueSecurityProfile.forEach(element => {
    if (element)
      uniqueSecurityProfileList.push(element);
  });
  uniqueAffiliate.forEach(element => {
    if (element)
      uniqueAffiliateList.push(element);
  });
  // uniqueSecurityPolicy.forEach(element => {
  //   if (element)
  //   uniqueSecurityPolicyList.push(element);
  // });

  // filterOptions[0].values = uniqueRoleAudienceList.map((object) => {
  //   return {
  //     name: object,
  //     value: object,
  //     selected: false
  //   };
  // });
  filterOptions[0].values = uniqueLicenseList.map((object) => {
    return {
      name: object,
      value: object,
      selected: false
    };
  });
  filterOptions[1].values = uniqueSecurityProfileList.map((object) => {
    return {
      name: object,
      value: object,
      selected: false
    };
  });
  filterOptions[2].values = uniqueAffiliateList.map((object) => {
    return {
      name: object,
      value: object,
      selected: false
    };
  });
  // filterOptions[3].values = uniqueSecurityPolicyList.map((object) => {
  //   return {
  //     name: object,
  //     value: object,
  //     selected: false
  //   };
  // });

  return filterOptions;
};


const filteredOptionsInitialState = [
  // {
  //   name: "User Type",
  //   accessor: "roleAudience",
  //   values: []
  // },
  {
    name: "License",
    accessor: "license",
    values: []
  },
  {
    name: "Security Profile",
    accessor: "securityProfile",
    values: []
  },
  {
    name: "Affiliate",
    accessor: "vamHubAbbreviation",
    values: []
  }
  // {
  //   name: "Security Policy",
  //   accessor: "securityPolicy",
  //   values: []
  // }
];