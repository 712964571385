import { axiosApigateway, axiosMsGraphBeta } from "../../../api";
import { approvar_actionType } from "../../actionTypes/approvar-actionTypes/approvar-actionType";
// import { setLoaderActive, setLoaderInActive } from "../loaderAction";

export function requesetData() {
  return {
    type: approvar_actionType.GET_REQUEST_DATA_SUCCESS
  };
}

export function requestSetDataSuccess(data) {
  return {
    type: approvar_actionType.SET_REQUEST_DATA_SUCCESS,
    payload: data
  };
}

export function requestAllRolesSuccess(data) {
  return {
    type: approvar_actionType.HUB_ROLES_SUCCESS,
    payload: data
  };
}

export function requestAllProductsSuccess(data) {
  return {
    type: approvar_actionType.HUB_PRODUCTS_SUCCESS,
    payload: data
  };
}

export function requestSelectedRolesSuccess(data) {
  return {
    type: approvar_actionType.SELECTED_ROLES_SUCCESS,
    payload: data || []
  };
}

export function requestSelectedProductsSuccess(data) {
  return {
    type: approvar_actionType.SELECTED_PRODUCTS_SUCCESS,
    payload: data || []
  };
}

export function requestAllRolesError(error) {
  return {
    type: approvar_actionType.HUB_ROLES_AND_PRODUCTS_FAILURE,
    payload: error
  };
}

export function getSponsorDetailsSuccess(data) {
  return {
    type: approvar_actionType.GET_SPONSOR_DATA_SUCCESS,
    payload: data
  };
}

export function getSponsorDetailsFailure(error) {
  return {
    type: approvar_actionType.GET_SPONSOR_DATA_FAILURE,
    payload: error
  };
}

export function requestSetDateError(error) {
  return {
    type: approvar_actionType.SET_REQUEST_DATA_FAILURE,
    payload: error
  };
}

export function resetLoader() {
  return {
    type: approvar_actionType.RESET_LOADER
  };
}
export function setLoader() {
  return {
    type: approvar_actionType.SET_LOADER
  };
}
export function setActionButtonFlags(data) {
  return {
    type: approvar_actionType.SET_ACTION_BUTTON_FLAGS,
    payload: data
  };
}

async function isUserExistInAdGroup(viewerADGroupArr) {
  try {
    // const body1 = { groupIds: viewerADGroupArr };
    const response = await axiosMsGraphBeta.post(`/me/checkMemberGroups`, {
      groupIds: [viewerADGroupArr]
    });
    if (response) {
      return response?.data?.value.length > 0 ? true : false;
    } else {
      return false;
    }
  } catch (error) {
    console.log("error", error);
    return false;
  }
}

const getUserDatails = (responseData) => {
  return {
    display_name: responseData?.display_name,
    email: responseData?.email,
    first_name: responseData?.first_name,
    language: responseData?.language,
    last_name: responseData?.last_name,
    locale: responseData?.locale,
    user_id: responseData?.user_id,
    user_type: responseData?.user_type,
    veeva_username: responseData?.veeva_username,
    federated_id: responseData?.federated_id,
    timezone: responseData?.timezone
  };
};

export function getRequestData(taskId, loggedUserMail) {
  // loggedUserMail is showing undefined please check
  return async (dispatch) => {
    // dispatch(setLoaderActive());
    dispatch(requesetData());
    try {
      const response = await axiosApigateway.get(`/approval-tasks/${taskId}`);
      const responseData = response?.data?.data;
      const userDetails = getUserDatails(
        responseData?.vam_request?.request_payload
      );
      const returnData = {
        ...responseData,
        userDetails
      };
      // dispatch(setLoaderInActive());
      dispatch(requestSetDataSuccess(returnData));
      // if (returnData?.taskType === "helpDesk") {
      //   let userHaveAccess = false;
      //   if (responseData?.viewerADGroup !== null) {
      //     userHaveAccess = await isUserExistInAdGroup(
      //       responseData?.viewerADGroup
      //     );
      //   }
      //   if (userHaveAccess) {
      //     dispatch({
      //       type: approvar_actionType.ACCESS_CHECK_FOR_EXCEPTION,
      //       data: true
      //     });
      //   }
      //   else {
      //     dispatch({
      //       type: approvar_actionType.ACCESS_CHECK_FOR_EXCEPTION,
      //       data: false
      //     });
      //   }
      //   dispatch(resetLoader());
      //   return;
      // }
      const useAccessPayload =
        responseData?.vam_request?.request_payload?.users_access_payload;
      dispatch(
        requestSelectedRolesSuccess(
          useAccessPayload?.vam_roles
          // ?.filter(
          //   (role) =>
          //     role.roleAudience !== null &&
          //     (role.roleAudience === userDetails?.user_type || role.roleAudience === "All")
          // )
        )
      );
      if (useAccessPayload.vam_roles.length > 0) {
        dispatch(setTrainings(useAccessPayload));
      }
      // dispatch(requestSelectedProductsSuccess(useAccessPayload?.vvpm_products));

      // task assigned to details and button flags
      let isUserPresentInAdGroup = false;
      if (responseData?.viewerADGroup !== null) {
        isUserPresentInAdGroup = await isUserExistInAdGroup(
          responseData?.viewerADGroup
        );
      }
      // if (responseData?.taskAssignedTo) {
      // sponsor details should be shoown by calling bellow api
      // dispatch(getSponsor(responseData?.taskAssignedTo));
      // console.log('sopnsor is available now.');
      // }
      if (
        responseData?.taskAssignedTo &&
        responseData?.taskAssignedTo === loggedUserMail
      ) {
        // if (responseData?.viewerADGroup !== null && isUserPresentInAdGroup) {

        // buttons enabled(approve and reject )
        const flags = {
          isApproveEnabled: true,
          isRejectEnabled: true,
          isEditEnabled: true,
          isApproveHidden: false,
          isRejectHidden: false,
          isEditHidden: false
        };
        dispatch(setActionButtonFlags(flags));
        // } else {
        //   // buttons enabled(approve and reject)
        //   const flags = {
        //     isApproveEnabled: true,
        //     isRejectEnabled: true,
        //     isReassignEnabled: false,
        //     isEditEnabled: true,
        //     isApproveHidden: false,
        //     isRejectHidden: false,
        //     isEditHidden: false,
        //     isReassignedHidden: true
        //   };
        //   dispatch(setActionButtonFlags(flags));
        // }
      } else if (responseData?.viewerADGroup !== null) {
        if (isUserPresentInAdGroup) {
          // if (responseData?.isTaskReassignable) {
          //buttons enabled(reject and approve)
          const flags = {
            isApproveEnabled: true,
            isRejectEnabled: true,
            isEditEnabled: true,
            isApproveHidden: false,
            isRejectHidden: false,
            isEditHidden: false
          };
          dispatch(setActionButtonFlags(flags));
          // } else {
          //   //buttons enabled(approve and reject)
          //   const flags = {
          //     isApproveEnabled: true,
          //     isRejectEnabled: true,
          //     isReassignEnabled: false,
          //     isEditEnabled: true,
          //     isApproveHidden: false,
          //     isRejectHidden: false,
          //     isEditHidden: false,
          //     isReassignedHidden: true
          //   };
          //   dispatch(setActionButtonFlags(flags));
          // }
        } else {
          //buttons disabled (including edit)
          const flags = {
            isApproveEnabled: false,
            isRejectEnabled: false,
            isEditEnabled: false,
            isApproveHidden: true,
            isRejectHidden: true,
            isEditHidden: true
          };
          dispatch(setActionButtonFlags(flags));
        }
      } else {
        // button disabled (including edit)
        const flags = {
          isApproveEnabled: false,
          isRejectEnabled: false,
          isEditEnabled: false,
          isApproveHidden: true,
          isRejectHidden: true,
          isEditHidden: true
        };
        dispatch(setActionButtonFlags(flags));
      }

      dispatch(resetLoader());
    } catch (error) {
      console.log(error);
      // dispatch(setLoaderInActive());
      dispatch(resetLoader());
      dispatch(requestSetDateError(error?.message));
    }
  };
}

// export function getProducts(productCategory, selectedProducts) {
//   console.log(selectedProducts);
//   return productCategory.map((productCategoryObj) => {
//     return {
//       productName: productCategoryObj.name,
//       productList: productCategoryObj.vvpm_products.map((productObj) => {
//         return {
//           text: productObj.product_name,
//           checked: selectedProducts.some(
//             (ele) => ele.product_name === productObj.product_name
//           ),
//           product_abbreviation: productObj.product_abbreviation,
//           product_active_flg: productObj.product_active_flg,
//           product_id: productObj.product_id,
//           product_name: productObj.product_name,
//           vamProductGroupId: productObj.vamProductGroupId,
//           applicableAffiliates: productObj.applicableAffiliates || []
//         };
//       }),
//       checked: false,
//       description: productCategoryObj.description,
//       id: productCategoryObj.id,
//       name: productCategoryObj.name,
//       vamhubs_vamproductgroup_junction:
//         productCategoryObj.vamhubs_vamproductgroup_junction
//     };
//   });
// }

// export function getFreshProducts(productCategory) {
//   return async (dispatch) => {
//     const newProductsArr = productCategory.map((productCategoryObj) => {
//       return {
//         ...productCategoryObj,
//         productList: productCategoryObj.productList.map((productObj) => {
//           return {
//             ...productObj,
//             checked: false
//           };
//         }),
//         checked: false
//       };
//     });
//     dispatch(requestAllProductsSuccess(newProductsArr));
//   };
// }

// async function getAllUsersInGraph(searchedUser) {
//   const toSelect = ["displayName", "mail", "employeeId"].join(",");
//   // const orderBy = ["displayName", "mail"].join(",");
//   // const queryString = `$filter=employeeId ne null&$search="mail:${searchedUser}"&$select=${toSelect}&$orderby=${orderBy}&$count=true`;
//   // const finalURL = `/users?${queryString}`;
//   const finalURL = `/users/${searchedUser}?$select=${toSelect}`;
//   //https://graph.microsoft.com/v1.0/users/${searchedUser}?$select=${toSelect}
//   return axiosMsGraph.get(finalURL);
// }

// export function getSponsor(sponsorMail) {
//   return async (dispatch) => {
//     try {
//       const toSelect = ["displayName", "mail", "employeeId"].join(",");
//       const finalURL = `/users/${sponsorMail}?$select=${toSelect}`;

//       const res = await axiosMsGraph.get(finalURL);
//       // const sponsor = res?.data?.value[0];
//       // console.log('/////////////////////');
//       const sponsor = res?.data;
//       dispatch(getSponsorDetailsSuccess(sponsor));
//     } catch {
//       (error) => {
//         console.log(error?.message);
//         dispatch(getSponsorDetailsFailure(error?.message));
//       };
//     }
//   };
// }
// export function updateTrainingCompletionDate(dateString) {
//   return async (dispatch) => {
//     try {

//     }
//   };
// }
export function setTrainings(userAccessPayLoad) {
  return async (dispatch) => {
    try {
      const response = await axiosApigateway.get(
        `/hubs/${userAccessPayLoad?.abbreviation}`
      );
      const vam_roles = response?.data?.data
        ?.map((hub) => {
          if (hub?.vam_roles) return hub?.vam_roles;
        })
        ?.flat();
      const roles = vam_roles.filter((o) =>
        userAccessPayLoad.vam_roles.some(
          ({ vam_role_id }) => o?.vam_role_id === vam_role_id
        )
      );
      let rolesTraingings = roles
        ?.map((role) => role?.vam_trainings)
        ?.flat()
        ?.reduce((unique, o) => {
          if (!unique.some((obj) => obj?.id === o?.id)) {
            unique.push(o);
          }
          return unique;
        }, []);
      // if (C0103873 and C0103872 both trainings exist then remove C0103872 from trainings list)
      const isBothTrainingsPresent =
        rolesTraingings.filter(
          (trObj) => trObj.id === "C0103873" || trObj.id === "C0103872"
        ).length == 2;
      console.log(isBothTrainingsPresent);
      if (isBothTrainingsPresent) {
        // rolesTraingings;
        rolesTraingings = rolesTraingings.filter(
          (trObj) => trObj.id !== "C0103872"
        );
      }
      if (rolesTraingings.length > 0) {
        dispatch({
          type: approvar_actionType.SET_TRAINGINGS,
          payload: rolesTraingings
        });
      }
      dispatch({
        type: approvar_actionType.SET_HUB_DATA,
        payload: response?.data?.data
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function getAllApprovalRoles(hubsData) {
  // console.log("Hub", affiliate);
  // console.log(affiliate);
  return async (dispatch) => {
    try {
      dispatch(setLoader());
      // const response = await axiosApigateway.get(`/hubs/${affiliate}`);
      // const responseData = response?.data?.data;
      const responseData = hubsData;
      if (!responseData) {
        dispatch({
          type: approvar_actionType.EDIT_OR_UPDATE_FAILURE,
          error: "Something went wrong, Please try again!"
        });
        return;
      }
      const vam_roles = responseData
        ?.map((hub) => {
          if (hub?.vam_roles) return hub?.vam_roles;
        })
        ?.flat()
        .filter((r) => r !== undefined)

        // ?.filter(
        //   (role) =>
        //     role.roleAudience !== null &&
        //     (role.roleAudience === userType || role.roleAudience === "Onboarded")
        // )
        ?.map((role) => {
          // console.log('role');
          // console.log(role);
          const roleData = {
            vam_role_id: role?.vam_role_id,
            name: role?.name,
            description: role?.description,
            isElevatedRole: role?.isElevatedRole,
            roleAudience: role?.roleAudience,
            // approvers_ad_groups: role.approvers_ad_groups, (will remove)
            // isRequireProducts: role?.isRequireProducts,
            license: role?.license,
            securityProfile: role?.securityProfile,
            securityPolicy: role?.securityPolicy,
            isActive: role?.isActive,
            vamHubAbbreviation: role?.vamHubAbbreviation,
            trainings: role?.vam_trainings.map((training) => ({
              id: training?.id,
              name: training?.trainingName,
              description: training?.description,
              isCurriculam: training?.isCurriculam,
              isActive: training?.isActive
            }))
          };
          return roleData;
        });
      dispatch(requestAllRolesSuccess(vam_roles));
      // const allProducts = getProducts(
      //   responseData?.vam_product_groups,
      //   selectedProducts
      // );
      // dispatch(requestAllProductsSuccess(allProducts));
    } catch (error) {
      dispatch({
        type: approvar_actionType.EDIT_OR_UPDATE_FAILURE,
        error:
          error?.response?.data?.errormessage ||
          "Something went wrong, Please try again!"
      });
      dispatch(requestAllRolesError(error?.message));
    } finally {
      dispatch(resetLoader());
    }
  };
}

// const fetchSelectedProducts = (productObject) => {
//   let tempProduct = [];

//   productObject.forEach((product) => {
//     product.productList.forEach((element) => {
//       if (element.checked) {
//         tempProduct.push({
//           product_id: element.product_id,
//           product_name: element.text,
//           product_abbreviation: element.product_abbreviation,
//           product_active_flg: element.product_active_flg,
//           vamProductGroupId: product.id,
//           applicableAffiliates: element?.applicableAffiliates || []
//         });
//       }
//     });
//   });
//   return tempProduct;
// };

const fetchNewRequestPayLoad = (completeState) => {
  const userAccessPayLoad = {
    ...completeState.ApprovarReducer.data.vam_request.request_payload
      .users_access_payload,
    vam_roles: completeState.ApprovarReducer.selectedRoles
    // vvpm_products: fetchSelectedProducts(
    //   completeState.ApprovarReducer.allProducts
    // )
  };
  return userAccessPayLoad;
};

const fetchRequredData = (requestId, completeState) => {
  const updateData = {
    users_access_payload: fetchNewRequestPayLoad(completeState),
    taskAction: "updateRequest"
  };
  return updateData;
};

export function changeTrainingCompletionDateHandler(requestId, newDate) {
  return async (dispatch) => {
    dispatch(setLoader());
    try {
      const requiredData = {
        taskAction: "updateTrainingCompletionDate",
        trainingCompletionDate: newDate
      };
      // calling API
      const response = await axiosApigateway.put(
        `/approval-tasks/${requestId}`,
        requiredData
      );

      if (response) {
        dispatch({
          type: approvar_actionType.UPDATE_TRAINING_COMPLETION_DATE_SUCCESS,
          payload: newDate,
          msg: response?.data?.data
        });

        dispatch(resetLoader());
        // return { response, success: true };
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: approvar_actionType.UPDATE_TRAINING_COMPLETION_DATE_FAILURE,
        error:
          error?.response?.data?.errormessage ||
          "Something went wrong, Please try again!"
      });
      dispatch(resetLoader());
      // console.log("error", error);
    }
  };
}

export function updateApprovarRequestData(requestId, completeState) {
  return async (dispatch) => {
    dispatch(setLoader());
    try {
      const requiredData = fetchRequredData(requestId, completeState);
      // calling API
      const response = await axiosApigateway.put(
        `/approval-tasks/${requestId}`,
        requiredData
      );
      // const userType = completeState?.ApprovarReducer.data?.userDetails?.email?.toLowerCase()?.includes("@lilly.com") ? "Lilly" : "Non-Lilly";
      if (response) {
        dispatch(
          getRequestData(
            requestId,
            completeState?.LoggedUserReducer?.loggedUser?.username
          )
        );
        dispatch(resetLoader());
        return { response, success: true };
      }
    } catch (error) {
      dispatch({
        type: approvar_actionType.EDIT_OR_UPDATE_FAILURE,
        error:
          error?.response?.data?.data ||
          "Something went wrong, Please try again!"
      });
      dispatch(
        requestSelectedRolesSuccess(
          completeState?.ApprovarReducer?.data?.vam_request?.request_payload
            ?.users_access_payload?.vam_roles
        )
      );
      dispatch(resetLoader());
      // console.log("error", error);
    }
  };
}

// export function retryTrainingVarify(id) {
//   return async (dispatch) => {
//     dispatch(setLoaderActive());
//     try {
//       const res = await axiosApigateway.get(`/retry-training-verify/${id}`);
//       const resData = res?.data?.data;
//       if (resData?.helpdesk_message?.length > 0) {
//         dispatch({
//           type: approvar_actionType.UPDATE_EXCEPTION_MESSAGE_SUCCESS,
//           data: resData?.helpdesk_message?.join('\n')
//         });
//       }
//       else if (typeof (resData) === 'string') {
//         dispatch({
//           type: approvar_actionType.RETRY_TRAINING_SUCCESS,
//           data: resData
//         });
//       }

//       dispatch(setLoaderInActive());
//     } catch (err) {
//       // console.log(err);
//       dispatch({
//         type: approvar_actionType.EDIT_OR_UPDATE_FAILURE,
//         error: err?.response?.data?.errormessage || 'Something went wrong, Please try again!'
//       });
//       dispatch(setLoaderInActive());
//     }
//   };
// }
