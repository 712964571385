import React from "react";
import { useSelector } from "react-redux";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Tooltip from "@mui/material/Tooltip";

function VAMRequestStatus({ viewAdminRequestId }) {
  const {
    viewAdminRequesterName,
    requestStatus,
    requestModifiedDate,
    requesterComment
  } = useSelector((state) => ({
    viewAdminRequesterName: state.ViewAdminDashboardReducer.FullName,
    requestStatus: state.ViewAdminDashboardReducer.requestStatus,
    requestModifiedDate: state.ViewAdminDashboardReducer.requestCreateDate,
    requesterComment: state.ViewAdminDashboardReducer.requesterComment
  }));
  const creationRequest = {
    approverName: "Request Raised",
    comment: requesterComment,
    cureentStatus: null,
    lastModifiedDate: requestModifiedDate
  };
  return (
    <div className="viewAdminCardContainer">
      <div>
        <div className="addNewProductContainerCard">
          <div className="adminAddProductCardHeader">
            <span className="cardHeaderText">
              Status for VVMC Account Request #{viewAdminRequestId} -{" "}
              {viewAdminRequesterName}
            </span>
          </div>
          <div className="adminViewDashboardCardStatusForm">
            {/* <div className="adminDashboardRequestCreation">
              {requestStatus.length > 0 ? (
                <>
                  <Approved requestStatus={creationRequest} />
                  <div className="dashedLine"></div>
                </>
              ) : (
                <>
                  <Approved requestStatus={creationRequest} />
                  <div className="dashedLine"></div>
                </>
              )}
            </div> */}
            <div className="adminDashboardRequestProgress">
              <div
                className="adminDashboardRequestProgressTask"
                key={"creationTask"}
              >
                <Approved requestStatus={creationRequest} />
                <div className="dashedLineInProgress"></div>
              </div>
              {requestStatus.map((requestStatusObject, requestStatusObjKey) => {
                return (
                  <div
                    className="adminDashboardRequestProgressTask"
                    key={requestStatusObjKey}
                  >
                    {taskDetail(requestStatusObject)}
                    {requestStatusObjKey !== requestStatus.length - 1 ? (
                      <div className="dashedLineInProgress"></div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const taskDetail = (requestStatus) => {
  switch (requestStatus.cureentStatus) {
    case "draft":
      return <Pending requestStatus={requestStatus} />;
    case "active":
      return <Pending requestStatus={requestStatus} />;
    case "approved":
      return <Approved requestStatus={requestStatus} />;
    case "rejected":
      return <Rejected requestStatus={requestStatus} />;
    case "auto-rejected":
      return <Rejected requestStatus={requestStatus} />;
    case "auto-approved":
      return <Approved requestStatus={requestStatus} />;
    case "pending":
      return <Pending requestStatus={requestStatus} />;
    default:
      return "Unknown taskStatus";
  }
};

const copyTaskLink = async (taskId) => {
  await navigator.clipboard.writeText(
    `${window.location?.origin}/approval/${taskId}`
  );
};

const commentsContent = (requestStatus) => {
  if (
    requestStatus?.comment &&
    requestStatus?.comment?.startsWith("Final Status -")
  ) {
    return requestStatus?.comment;
  } else {
    switch (requestStatus.cureentStatus) {
      case "approved": {
        const approvedBy = requestStatus.approvedOrRejectedBy
          ? `Approver: ${requestStatus.approvedOrRejectedBy}`
          : null;
        const comments = `Comments: ${
          requestStatus?.comment ? requestStatus?.comment : "-"
        }`;
        return approvedBy ? `${approvedBy}\n${comments}` : comments;
      }
      case "rejected": {
        const approvedBy = requestStatus.approvedOrRejectedBy
          ? `Rejected By: ${requestStatus.approvedOrRejectedBy}`
          : null;
        const comments = `Comments: ${
          requestStatus?.comment ? requestStatus?.comment : "-"
        }`;
        return approvedBy ? `${approvedBy}\n${comments}` : comments;
      }
      case "auto-rejected":
        return `Task Auto Rejected.\n${
          requestStatus?.comment ? requestStatus?.comment : ""
        }`;
      case "auto-approved":
        return `Task Auto Approved.\n${
          requestStatus?.comment ? requestStatus?.comment : ""
        }`;
      case "pending":
        return requestStatus?.comment;
      default:
        return requestStatus?.comment;
    }
  }
};

const Approved = ({ requestStatus }) => {
  return (
    <div className="adminDashboardRequestStatus">
      <div className="requestStatusOuterSuccessCircle">
        {requestStatus?.taskId ? (
          <Tooltip
            title={`${requestStatus?.taskId} - Click to Copy`}
            placement="right-start"
          >
            <CheckCircleOutlineOutlinedIcon
              onClick={async () => {
                await copyTaskLink(requestStatus?.taskId);
              }}
              sx={{
                width: "45px",
                height: "45px",
                color: "green",
                cursor: "pointer"
              }}
            />
          </Tooltip>
        ) : (
          <CheckCircleOutlineOutlinedIcon
            sx={{
              width: "45px",
              height: "45px",
              color: "green"
            }}
          />
        )}
      </div>
      <div className="approverName">
        {requestStatus.approverName?.length > 28 ? (
          <Tooltip title={requestStatus.approverName} placement="right-start">
            {requestStatus.approverName?.slice(0, 26)}...
          </Tooltip>
        ) : (
          requestStatus.approverName
        )}
      </div>
      <div className="modifiedDate">
        {requestStatus.lastModifiedDate
          ? requestStatus.lastModifiedDate.slice(0, 10)
          : "null"}
      </div>
      <div
        className="approvarComment"
        style={{
          whiteSpace: "pre-wrap"
        }}
      >
        <p
          style={{
            marginLeft: "5px",
            marginRight: "5px",
            marginTop: "-25px",
            height: "inherit",
            alignItems: "center"
          }}
        >
          {commentsContent(requestStatus)}
        </p>
      </div>
    </div>
  );
};

const Rejected = ({ requestStatus }) => {
  return (
    <div className="adminDashboardRequestStatus">
      <div className="requestStatusOuterErrorCircle">
        {requestStatus?.taskId ? (
          <Tooltip
            title={`${requestStatus?.taskId} - Click to Copy`}
            placement="right-start"
          >
            <CancelOutlinedIcon
              onClick={async () => {
                await copyTaskLink(requestStatus?.taskId);
              }}
              sx={{
                width: "45px",
                height: "45px",
                color: "red",
                cursor: "pointer"
              }}
            />
          </Tooltip>
        ) : (
          <CancelOutlinedIcon
            sx={{
              width: "45px",
              height: "45px",
              color: "red"
            }}
          />
        )}
      </div>
      <div className="approverName">
        {requestStatus.approverName?.length > 28 ? (
          <Tooltip title={requestStatus.approverName} placement="right-start">
            {requestStatus.approverName?.slice(0, 26)}...
          </Tooltip>
        ) : (
          requestStatus.approverName
        )}
      </div>
      <div className="modifiedDate">
        {requestStatus.lastModifiedDate
          ? requestStatus.lastModifiedDate.slice(0, 10)
          : "null"}
      </div>

      <div
        className="approvarComment"
        style={{
          whiteSpace: "pre-wrap"
        }}
      >
        <p
          style={{
            marginLeft: "5px",
            marginRight: "5px",
            marginTop: "-25px",
            height: "inherit",
            alignItems: "center"
          }}
        >
          {commentsContent(requestStatus)}
        </p>
      </div>
    </div>
  );
};

const Pending = ({ requestStatus }) => {
  return (
    <div className="adminDashboardRequestStatus">
      <div className="requestStatusOuterPendingCircle">
        {requestStatus?.taskId ? (
          <Tooltip
            title={`${requestStatus?.taskId} - Click to Copy`}
            placement="right-start"
          >
            <AccessTimeOutlinedIcon
              onClick={async () => {
                await copyTaskLink(requestStatus?.taskId);
              }}
              sx={{
                width: "45px",
                height: "45px",
                color: "#b3b303",
                cursor: "pointer"
              }}
            />
          </Tooltip>
        ) : (
          <AccessTimeOutlinedIcon
            sx={{
              width: "45px",
              height: "45px",
              color: "#b3b303"
            }}
          />
        )}
      </div>
      <div className="approverName">
        {requestStatus.approverName?.length > 28 ? (
          <Tooltip title={requestStatus.approverName} placement="right-start">
            {requestStatus.approverName?.slice(0, 26)}...
          </Tooltip>
        ) : (
          requestStatus.approverName
        )}
      </div>
      <div className="modifiedDate">
        {requestStatus.lastModifiedDate
          ? requestStatus.lastModifiedDate.slice(0, 10)
          : "null"}
      </div>
      <div
        className="approvarComment"
        style={{
          whiteSpace: "pre-wrap"
        }}
      >
        <p
          style={{
            marginLeft: "5px",
            marginRight: "5px",
            marginTop: "-25px",
            height: "inherit",
            alignItems: "center"
          }}
        >
          {commentsContent(requestStatus)}
        </p>
      </div>
    </div>
  );
};

export default VAMRequestStatus;
