import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableHead,
  Stack,
  Tooltip,
  Switch
} from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { toLower } from "lodash";

import "./styles/Affiliate.css";
import { useDispatch, useSelector } from "react-redux";
import Success from "../SuccessFailureModal/Success";
import Failure from "../SuccessFailureModal/Failure";
import { changeAffiliateActivationRequest } from "../../../../../../redux/actions/adminAccount-actions/affiliate-action";
import { affiliate_actionType } from "../../../../../../redux/actionTypes/adminAction-actionTypes/affiliate-actionType";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#f9ccb5",
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: 12,
    borderRight: "1px solid #80808040",
    borderBottom: "0px",
    padding: 3
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    color: "black",
    fontWeight: 400,
    borderRight: "1px solid #80808040",
    borderBottom: "0px",
    padding: 3
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "rgb(0 255 205 / 4%)"
  }
  // hide last border
  // "&:last-child td, &:last-child th": {
  //   border: 0
  // }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "abbreviation",
    label: "Affiliate Code"
  },
  {
    id: "name",
    label: "Affiliate Name"
  },
  {
    id: "description",
    label: "Description"
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
        <StyledTableCell>Action</StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const AffiliateTable = ({ searchText,
  handelToggleAddNewModal,
  setCurrentSelection }) => {
  const [filterData, setFilterData] = useState([]);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [modalMessage, setModalMessage] = useState('');
  // const [isSubmitActive, setIsSubmitActive] = useState(false);
  // const [isActivationActionSuccess, setIsActivationActionSuccess] = useState(false);
  const dispatch = useDispatch();
  const { allRequest, message, isEditRequestCompleted, isRequestSuccess } = useSelector((state) => ({
    allRequest: state?.AdminAffiliateReducer?.data,
    message: state?.AdminAffiliateReducer?.message,
    isEditRequestCompleted: state?.AdminAffiliateReducer?.isEditRequestCompleted,
    isRequestSuccess: state?.AdminAffiliateReducer?.isRequestSuccess
  }));
  const trHeigth = 31;

  useEffect(() => {
    if (isEditRequestCompleted) {
      // setModalMessage(message);
      setIsModalOpen(true);
      // dispatch(initiliseCompanyReduerState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditRequestCompleted, isRequestSuccess]);

  useEffect(() => {
    let tempFilterData = [...allRequest];
    setFilterData(tempFilterData);
  }, [allRequest]);

  useEffect(() => {
    let tempFilterData = [...allRequest];
    if (searchText?.length > 0) {
      tempFilterData = tempFilterData.filter((obj) => {
        return (toLower(obj.name).includes(toLower(searchText)) || toLower(obj.abbreviation).includes(toLower(searchText)));
      });
    }
    setFilterData(tempFilterData);
  }, [allRequest, searchText]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const changeActivationHandler = (e) => {
    dispatch(changeAffiliateActivationRequest(e.target?.id, e.target.checked));
  };

  const visibleRows = useMemo(
    () => stableSort(filterData, getComparator(order, orderBy)),
    [order, orderBy, filterData]
  );

  const tableHeigth = filterData.length * trHeigth + trHeigth;

  return (
    <>
      {isEditRequestCompleted ? isRequestSuccess ? <Success
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalMessage={message}
        refreshDataAction={() => {
          dispatch({
            type: affiliate_actionType.RESET_AFFILIATE_EDIT_MODAL_STATE
          });
        }}
      /> :
        <Failure
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          modalMessage={message}
          refreshDataAction={() => {
            dispatch({
              type: affiliate_actionType.RESET_AFFILIATE_EDIT_MODAL_STATE
            });
          }}
        /> : ''}
      <div className="affiliateTableContainer">
        <div className="affiliateTableHeader">
          <span>Existing Affiliates</span>
        </div>
        <div className="affiliateTableSectionContainer">
          <div className="affiliateTableSection">
            <TableContainer
              sx={{ height: `${tableHeigth > 31 ? tableHeigth : tableHeigth + trHeigth}px`, maxHeight: "65vh" }}
              component={Paper}
            >
              <Table stickyHeader aria-label="Affiliate table">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={filterData.length}
                />
                <TableBody>
                  {visibleRows &&
                    visibleRows.map((row) => (
                      <StyledTableRow key={row.id_pk}>
                        <StyledTableCell>{row.abbreviation}</StyledTableCell>
                        <StyledTableCell>{row.name}</StyledTableCell>
                        <StyledTableCell>{row.description}</StyledTableCell>
                        <StyledTableCell width={10} alignItems={"center"}  >
                          <Stack id={'editDelete'} spacing={1} direction={"row"} width={12} alignItems={"center"}
                          // style={{ display: 'none' }}
                          >
                            <Tooltip title={row?.isActive ? "Click To Deactivate" : 'Click To Activate'} placement="left">
                              <Switch size="small"
                                // checked={true}
                                id={row?.abbreviation}
                                checked={row?.isActive}
                                sx={
                                  {
                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                      color: 'green'
                                    },
                                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                      backgroundColor: 'lightGreen'
                                    },
                                    '& .MuiSwitch-switchBase': {
                                      color: 'red'
                                    }, '& .MuiSwitch-track': {
                                      backgroundColor: '#FF7276'
                                    }
                                  }
                                }
                                onChange={changeActivationHandler} />

                            </Tooltip>
                            <EditOutlinedIcon
                              className="clickable"
                              alignItems={"center"}
                              onClick={() => {
                                handelToggleAddNewModal("edit");
                                setCurrentSelection(row);
                              }}
                            />
                            {/* <DeleteForeverOutlinedIcon 
                                                                                className="deleteCompanyIcon" 
                                                                                onClick={(event) => { 
                                                                                    event.preventDefault();
                                                                                    handleDelete(companyInnerObject.id);
                                                                                }} 
                                                                          /> */}
                          </Stack>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}


                  {visibleRows?.length <= 0 ? <StyledTableRow key={"noRecordsFound"}>
                    <StyledTableCell key="noRecordsFound" style={{
                      textAlign: "center"
                    }} colSpan={4}>No records found!
                    </StyledTableCell>
                  </StyledTableRow> : ''}


                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div >
      </div >
    </>
  );
};

export default AffiliateTable;
