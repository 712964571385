import { combineReducers } from 'redux';
import LoggedUserReducer from './loggedUser-reducer';
import SearchUserResourcesReducer from './userAccount-reducers/searchUserResources-reducer';
import UserDetailReducer from './userAccount-reducers/userDetail-reducer';
import SelectAffiliateReducer from './userAccount-reducers/selectAffiliate-reducer';
import WorkFlowRolesReducer from './userAccount-reducers/workFlowRoles-reducer';
import SubmitUserAccountReducer from './userAccount-reducers/submitUserAccount-reducer';
import SummaryReducer from './userAccount-reducers/summary-reducer';
import AffiliateResourcesReducer from './userAccount-reducers/affiliateResources-reducer';
import UserAccessResourcesReducer from './userAccount-reducers/userAccessResources-reducer';
import AllUserDetailResourcesReducer from './userAccount-reducers/allUserDetailResources-reducer';
import CheckAccountStatusResourcesReducer from './userAccount-reducers/checkAccountStatusResources-reducer';
import ApprovarReducer from './approvar-reducers/approvar-reducer';
import SubmitApprovarRequestReducer from './approvar-reducers/submitApprovaRequest-reducer';
import AdminDashboardReducer from './adminAccount-reducers/adminDashboard-reducer';
import ViewAdminDashboardReducer from './adminAccount-reducers/viewAdminDashboard-reducer';
import GroupReducer from './adminAccount-reducers/group-reducer';
import AdminAffiliateReducer from './adminAccount-reducers/affiliate-reducer';
import TrainingsReducer from './adminAccount-reducers/trainings-reducer';
// import CompanyReducer from './adminAccount-reducers/company-reducer';
import RolesReducer from './adminAccount-reducers/role-reducer';
import CheckUserRoleReducer from './checkUserRole-reducer';
import LoaderReducer from './loader-reducer';
import ReassignAndTerminateReducer from './adminAccount-reducers/reassignAndTerminate';

const rootReducer = combineReducers({
    /*common*/
    LoaderReducer,
    LoggedUserReducer,
    CheckUserRoleReducer,
    /*user account reducers*/
    CheckAccountStatusResourcesReducer,
    SearchUserResourcesReducer,
    AffiliateResourcesReducer,
    AllUserDetailResourcesReducer,
    UserAccessResourcesReducer,
    UserDetailReducer,
    SelectAffiliateReducer,
    WorkFlowRolesReducer,
    SummaryReducer,
    SubmitUserAccountReducer,
    /*approver reducers*/
    ApprovarReducer,
    SubmitApprovarRequestReducer,
    /*admin account reducers*/
    AdminDashboardReducer,
    ViewAdminDashboardReducer,
    GroupReducer,
    AdminAffiliateReducer,
    TrainingsReducer,
    RolesReducer,
    ReassignAndTerminateReducer
});

export default rootReducer;