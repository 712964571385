import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  TextareaAutosize,
  styled
} from "@mui/material";
// import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addorModifyRolesRequestCall } from "../../../../../../../redux/actions/adminAccount-actions/roles-action/rolesRequest-action";
const Textarea = styled(TextareaAutosize)(
  () => `
  box-sizing: border-box;
  width: 320px;  
  padding: 8px 12px;
  border-radius: 8px;
  line-height: 1.5;
  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const RoleTab = (props) => {
  // const {allRoles} = props;
  const {
    mode,
    currentSelectedRole,
    setIsModalOpen,
    setActiveModalNotice,
    allAffiliteData
  } = props;
  const dispatch = useDispatch();
  const [roleId, setRoleId] = useState("");
  const [roleName, setRoleName] = useState("");
  const [roleAudience, setRoleAudience] = useState("");
  const [license, setLicense] = useState("");
  const [securityProfile, setSecurityProfile] = useState("");
  // const [securityPolicy, setSecurityPolicy] = useState("");
  const [vamHubAbbr, setVamHubAbr] = useState("");
  const [elevatedRole, setElevatedRole] = useState(null);
  // const [requireProducts, setRequireProducts] = useState(null);
  const [roleDesc, setRoleDesc] = useState("");

  useEffect(() => {
    if (mode) {
      switch (mode) {
        case "edit":
          setRoleId(currentSelectedRole?.vamRoleId);
          setRoleName(currentSelectedRole?.name);
          setRoleAudience(currentSelectedRole?.roleAudience);
          setLicense(currentSelectedRole?.license);
          setSecurityProfile(currentSelectedRole?.securityProfile);
          setVamHubAbr(currentSelectedRole?.vamHubAbbreviation || "generic");
          setElevatedRole(currentSelectedRole?.isElevatedRole);
          // setRequireProducts(currentSelectedRole?.isRequireProducts);
          setRoleDesc(currentSelectedRole?.description);
          // setSecurityPolicy(currentSelectedRole?.securityPolicy);
          break;
        case "create":
          setRoleId("");
          setRoleName("");
          setRoleAudience("");
          setLicense("");
          setSecurityProfile("");
          setVamHubAbr("");
          setElevatedRole(null);
          // setRequireProducts(null);
          setRoleDesc("");
          // setSecurityPolicy("");
          break;
        default:
          setRoleId("");
          setRoleName("");
          setRoleAudience("");
          setLicense("");
          setSecurityProfile("");
          setVamHubAbr("");
          setElevatedRole(null);
          // setRequireProducts(null);
          setRoleDesc("");
        // setSecurityPolicy("");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const ROLE_AUDIENCE_DROPDOWN = [
    {
      title: "Onboarded",
      value: "Onboarded"
    }
  ];
  const SECURITY_PROFILE_DROPDOWN = [
    {
      title: "System Administrator",
      value: "system_admin__v"
    },
    {
      title: "Business Administrator",
      value: "business_admin__v"
    },
    {
      title: "Custom View Sharing",
      value: "custom_view_sharing__c"
    },
    {
      title: "Document User",
      value: "document_user__v"
    },
    {
      title: "External User",
      value: "external_user__v"
    },
    {
      title: "Read Only User",
      value: "read_only_user__v"
    }
  ];
  const LICENSE_DROPDOWN = [
    {
      title: "Full",
      value: "full__v"
    },
    {
      title: "Read-Only-User",
      value: "read_only__v"
    }
  ];
  // const SECURITY_POLICIES_DROPDOWN = [
  //   {
  //     title: 'SSO and TEST Integration',
  //     value: 'SSO and TEST Integration'
  //   },
  //   {
  //     title: 'Lilly Domain Level MFA-SSO',
  //     value: 'Lilly Domain Level MFA-SSO'
  //   }
  // ];

  const AFFILIATES_DROPDOWN = allAffiliteData.map((affObj) => {
    return {
      title: affObj.name,
      value: affObj.abbreviation
    };
  });

  const cancelCreateModifyRoleHandler = () => {
    setIsModalOpen(false);
  };

  const createModifyRoleHandler = () => {
    let payload;
    payload = {
      name: roleName,
      description: roleDesc,
      isElevatedRole: elevatedRole,
      // isRequireProducts: requireProducts,
      roleAudience: roleAudience,
      license: license,
      securityProfile: securityProfile,
      vamHubAbbreviation: vamHubAbbr === "generic" ? null : vamHubAbbr
      // securityPolicy: securityPolicy
    };
    switch (mode) {
      case "edit":
        dispatch(addorModifyRolesRequestCall("edit", payload, roleId));
        setIsModalOpen(false);
        setActiveModalNotice("message");
        break;
      case "create":
        dispatch(addorModifyRolesRequestCall("create", payload));
        setIsModalOpen(false);
        setActiveModalNotice("message");
        break;
      default:
        null;
    }
  };

  console.log(roleId);
  return (
    <div className="role_addOrModifyRoleContainer">
      <Grid container spacing={1} md={12} className="role_addOrModifyRole">
        <Grid item sm={4}>
          <FormControl>
            <FormLabel id="is-elevated-role">
              <span className="role_formLabel">Role Name</span>
              <span className="dangerColor">*</span>
            </FormLabel>
            <TextField
              sx={{ width: "18rem", marginTop: "0.5rem" }}
              id="is-elevated-role"
              type="text"
              size="small"
              variant="outlined"
              autoComplete="off"
              value={roleName}
              onChange={(event) => {
                setRoleName(event.target.value);
              }}
              focused={false}
            />
          </FormControl>
        </Grid>
        <Grid item sm={4}>
          <FormControl>
            <FormLabel id="user-type">
              <span className="role_formLabel">User Type</span>
              <span className="dangerColor">*</span>
            </FormLabel>
            <Select
              sx={{ width: "18rem", marginTop: "0.5rem" }}
              placeholder="User Type"
              size="small"
              aria-labelledby="user-type"
              value={roleAudience}
              onChange={(event) => {
                setRoleAudience(event.target.value);
              }}
            >
              {ROLE_AUDIENCE_DROPDOWN.map((object, objectKey) => {
                return (
                  <MenuItem key={objectKey} value={object.value}>
                    {object.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={4}>
          <FormControl>
            <FormLabel id="license">
              <span className="role_formLabel">License</span>
              <span className="dangerColor">*</span>
            </FormLabel>
            <Select
              sx={{ width: "18rem", marginTop: "0.5rem" }}
              placeholder="License"
              size="small"
              aria-labelledby="license"
              value={license}
              onChange={(event) => {
                setLicense(event.target.value);
              }}
            >
              {LICENSE_DROPDOWN.map((object, objectKey) => {
                return (
                  <MenuItem key={objectKey} value={object.value}>
                    {object.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={4}>
          <FormControl>
            <FormLabel id="security-profile">
              <span className="role_formLabel">Security Profile</span>
              <span className="dangerColor">*</span>
            </FormLabel>
            <Select
              sx={{ width: "18rem", marginTop: "0.5rem" }}
              placeholder="Security Profile"
              size="small"
              aria-labelledby="security-profile"
              value={securityProfile}
              onChange={(event) => {
                setSecurityProfile(event.target.value);
              }}
            >
              {SECURITY_PROFILE_DROPDOWN.map((object, objectKey) => {
                return (
                  <MenuItem key={objectKey} value={object.value}>
                    {object.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={4}>
          <FormControl>
            <FormLabel id="vam-hub-abbreviation">
              <span className="role_formLabel">Affiliate</span>
              <span className="dangerColor">*</span>
            </FormLabel>
            <Select
              sx={{ width: "18rem", marginTop: "0.5rem" }}
              placeholder="Affiliate"
              size="small"
              aria-labelledby="affiliate"
              value={vamHubAbbr}
              disabled={mode === "edit" ? true : false}
              onChange={(event) => {
                setVamHubAbr(event.target.value);
              }}
            >
              <MenuItem value={"generic"}>{"Generic"}</MenuItem>
              {AFFILIATES_DROPDOWN.map((object, objectKey) => {
                return (
                  <MenuItem key={objectKey} value={object.value}>
                    {object.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={4}>
          {/* <FormControl>
            <FormLabel id="security-policy">
              <span className='role_formLabel'>Security Policy</span>
            </FormLabel>
            <Select
              sx={{ width: "18rem", marginTop: "0.5rem" }}
              placeholder="Security Policy"
              size='small'
              aria-labelledby="security-policy"
              value={securityPolicy}
              onChange={(event) => {
                setSecurityPolicy(event.target.value);
              }}
            >
              {
                SECURITY_POLICIES_DROPDOWN.map((object, objectKey) => {
                  return (
                    <MenuItem key={objectKey} value={object.value}>{object.title}</MenuItem>
                  );
                })
              }
            </Select>
          </FormControl> */}
        </Grid>
        <Grid item sm={4}>
          <FormControl>
            <FormLabel id="is-elevated-role">
              <span className="role_formLabel">Elevated Role</span>
              <span className="dangerColor">*</span>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="is-elevated-role"
              name="row-radio-buttons-group"
              value={elevatedRole}
              onChange={(event) => {
                setElevatedRole(event.target.value);
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid>
        {/* <Grid item sm={8}>
          <FormControl>
            <FormLabel id="is-require-products">
              <span className='role_formLabel'>Require Products</span>
              <span className='dangerColor'>*</span>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="is-require-products"
              name="row-radio-buttons-group"
              value={requireProducts}
              onChange={(event) => {
                setRequireProducts(event.target.value);
              }}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </Grid> */}
        <Grid item sm={12}>
          <FormControl>
            <FormLabel id="description">
              <span className="role_formLabel">Description</span>
            </FormLabel>
            <Textarea
              className="role_textArea"
              aria-label="description"
              // maxRows={6}
              minRows={6}
              sx={{ maxWidth: "500px", maxHeight: "100px" }}
              placeholder="Enter role description here"
              value={roleDesc}
              onChange={(event) => {
                setRoleDesc(event.target.value);
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} md={12} className="role_actionButons">
        <Button
          variant="outlined"
          color="error"
          className="btnRole btnRoleCancel"
          onClick={cancelCreateModifyRoleHandler}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="success"
          className="btnRole"
          onClick={createModifyRoleHandler}
          disabled={
            !roleName ||
            !roleAudience ||
            !license ||
            !securityProfile ||
            elevatedRole === null
          }
        >
          Submit
        </Button>
      </Grid>
    </div>
  );
};

export default RoleTab;
