export function requiredFieldAvailableUserDetail(data) {
  const { firstName, lastName, emailId, language, locale, timezone } = data;
  return (
    firstName?.length > 0 &&
    lastName?.length > 0 &&
    emailId?.length > 0 &&
    language?.length > 0 &&
    locale?.length > 0 &&
    timezone?.length > 0
  );
}
