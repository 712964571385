export const approvar_actionType = {
    GET_REQUEST_DATA_SUCCESS: 'GET_REQUEST_DATA_SUCCESS',
    SET_REQUEST_DATA_SUCCESS: 'SET_REQUEST_DATA_SUCCESS',
    SET_REQUEST_DATA_FAILURE: 'SET_REQUEST_DATA_FAILURE',
    UPDATE_APPROVAR_DATA_REQUEST: 'UPDATE_APPROVAR_DATA_REQUEST',
    UPDATE_APPROVAR_DATA_SUCCESS: 'UPDATE_APPROVAR_DATA_SUCCESS',
    UPDATE_APPROVAR_DATA_ERROR: 'UPDATE_APPROVAR_DATA_ERROR',
    HUB_ROLES_SUCCESS: 'HUB_ROLES_SUCCESS',
    HUB_PRODUCTS_SUCCESS: 'HUB_PRODUCTS_SUCCESS',
    HUB_ROLES_AND_PRODUCTS_FAILURE: 'HUB_ROLES_AND_PRODUCTS_FAILURE',
    SELECTED_PRODUCTS_SUCCESS: 'SELECTED_PRODUCTS_SUCCESS',
    SELECTED_ROLES_SUCCESS: 'SELECTED_ROLES_SUCCESS',
    GET_SPONSOR_DATA_SUCCESS: 'GET_SPONSOR_DATA_SUCCESS',
    GET_SPONSOR_DATA_FAILURE: 'GET_SPONSOR_DATA_FAILURE',
    RESET_LOADER: 'RESET_LOADER',
    SET_ACTION_BUTTON_FLAGS: 'SET_ACTION_BUTTON_FLAGS',
    SET_TRAINGINGS: 'SET_TRAINGINGS',
    UPDATE_EXCEPTION_MESSAGE_FAILURE: 'UPDATE_EXCEPTION_MESSAGE_FAILURE',
    UPDATE_EXCEPTION_MESSAGE_SUCCESS: 'UPDATE_EXCEPTION_MESSAGE_SUCCESS',
    EDIT_OR_UPDATE_FAILURE: 'EDIT_OR_UPDATE_FAILURE',
    RESET_ERROR_MODEL: 'RESET_ERROR_MODEL',
    SET_HUB_DATA: 'SET_HUB_DATA',
    RETRY_TRAINING_SUCCESS: 'RETRY_TRAINING_SUCCESS',
    ACCESS_CHECK_FOR_EXCEPTION: 'ACCESS_CHECK_FOR_EXCEPTION',
    UPDATE_TRAINING_COMPLETION_DATE_SUCCESS: 'UPDATE_TRAINING_COMPLETION_DATE_SUCCESS',
    UPDATE_TRAINING_COMPLETION_DATE_FAILURE: 'UPDATE_TRAINING_COMPLETION_DATE_FAILURE',
    RESET_TRAINING_COMPLETION: 'RESET_TRAINING_COMPLETION',
    SET_LOADER: 'SET_LOADER'
};