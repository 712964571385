import React from "react";
// import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import "./styles/ReassignAndTerminateTableBody.css";
import moment from "moment";
import { useDispatch } from "react-redux";
// import { reassignAndTerminateRequestTasksCall } from "../../../../../../redux/actions/adminAccount-actions/reassignAndTerminate/reassignAndTerminateRequestTasks-action";
import { reassignAndTerminateRequestListSelectRequest } from "../../../../../../redux/actions/adminAccount-actions/reassignAndTerminate/reassignAndTerminateRequestList-action";
// import { resetSearchApprover } from "../../../../../../redux/actions/adminAccount-actions/reassignAndTerminate/reassignAndTerminateSearchApprover-action";

const ReassignAndTerminateTableBody = ({
  setIsModalOpen,
  setActiveModalNotice,
  rows,
  columns
}) => {
  const dispatch = useDispatch();

  // const editActionHandler = (row) => {
  //   setIsModalOpen(true);
  //   setActiveModalNotice("edit");
  //   dispatch(reassignAndTerminateRequestListSelectRequest(row));
  //   dispatch(reassignAndTerminateRequestTasksCall(row.request_id));
  //   dispatch(resetSearchApprover());
  // };

  const deleteActionHandler = (row) => {
    setIsModalOpen(true);
    setActiveModalNotice("delete");
    dispatch(reassignAndTerminateRequestListSelectRequest(row));
  };

  return (
    <tbody className="reassignAndTerminate_tableBody">
      {rows?.length ? rows.map((row, idx) => {
        return (
          <tr key={idx} className="reassignAndTerminate_tableRow">
            {columns.map((column) => {
              const tData =
                column.accessor === "request_type"
                  ? row[column.accessor][0].toUpperCase() +
                  row[column.accessor].slice(1, row[column.accessor].length) +
                  " Account"
                  : column.accessor === "last_action_date"
                    ? moment(row[column.accessor]).format("MM/DD/YYYY hh:mm a")
                    : row[column.accessor];

              if (column.actionable)
                return (
                  <td key={column.accessor} className="reassignAndTerminate_tableCell">
                    <div className="reassignAndValidate_actionIconGroup">
                      {/* <span>
                        <VisibilityIcon
                          className="reassignAndValidate_actionIconGroupIcon clickable"
                          onClick={() => editActionHandler(row)}
                        />
                      </span> */}
                      <span>
                        <DeleteForeverOutlinedIcon
                          className="reassignAndValidate_actionIconGroupIcon clickable"

                          onClick={() => deleteActionHandler(row)}
                        />
                      </span>
                    </div>
                  </td>
                );

              return <td key={column.accessor} className="reassignAndTerminate_tableCell">{tData}</td>;
            })}
          </tr>
        );
      }) : <td key="noRecordsFound" className="reassignAndTerminate_tableCell reassignAndTerminate_tableCellNoRecords" colSpan={9}>No records found!</td>}
    </tbody>
  );
};

export default ReassignAndTerminateTableBody;
