import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import "./styles/AdminDashboard.css";

import AdminDashboardTable from './AdminDashboardTable/AdminDashboardTable';
import ViewAdminDashboard from './ViewAdminDashboard/ViewAdminDashboard';
import { getAdminDashboardAllRequests } from '../../../../../redux/actions/adminAccount-actions/adminDashboard-action';

function AdminDashboard() {
  const [isViewAdminDashboardActive, setIsViewAdminDashboardActive] = useState(false);
  const [viewAdminRequestId, setViewAdminRequestId] = useState(null);
  const dispatch = useDispatch();

  const hadelToggleIsViewAdminDashboardActive = () => {
    setIsViewAdminDashboardActive(!isViewAdminDashboardActive);
  };

  useEffect(() => {
    dispatch(getAdminDashboardAllRequests());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className='adminDashboardContainer'>
      {
        !isViewAdminDashboardActive ?
        <AdminDashboardTable 
          hadelToggleIsViewAdminDashboardActive={hadelToggleIsViewAdminDashboardActive}
          setViewAdminRequestId={setViewAdminRequestId}
        />
        :<ViewAdminDashboard
          viewAdminRequestId={viewAdminRequestId}
          hadelToggleIsViewAdminDashboardActive={hadelToggleIsViewAdminDashboardActive}
        />
      }
    </div>
  );
}

export default AdminDashboard;