import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Cookies from "js-cookie";
import { ThemeProvider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import theme from "./config/theme";
import SearchUser from "./pages/SearchUser/SearchUser";
import { useAuth } from "./contexts/auth/AuthContext";
import { getLoggedUser } from "./redux/actions/loggedUser-action";
import "./App.css";
import AuthCallback from "./pages/AuthCallback";
import Layout from "./components/layouts/Layout";
import Approvar from "./pages/Approvar";
import AdminPortal from "./pages/AdminPortal/AdminPortal";
import AccessDenied from "./components/common/AccessDenied/AccessDenied";
import currentEnvVars from "./lib/utils/getEnvVars";
import { checkUserRoleCall } from "./redux/actions/userRole-action";

function App() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const {loading, role, error} = useSelector((state) =>{
    return state.CheckUserRoleReducer;
  });
  const groupIdMapToGrp = currentEnvVars.groupIds;
  const groupVal = groupIdMapToGrp.flatMap(grp => Object.values(grp));
  
  const { pathname, search } = window.location;
  const adminUserRoles = ["superAdmin", "admin"];
  console.log(userLoggedIn);

  useEffect(() => {
    async function checkAuth() {
      // console.log('pathname', pathname)
      const isUserLoggedIn = await auth.isLoggedIn();
      setUserLoggedIn(isUserLoggedIn);

      if (!isUserLoggedIn) {
        Cookies.set("initialroute", pathname + search);
        // console.log("User not logged in");
        auth.login();
      } else {
        // console.log("User is logged in");
        dispatch(getLoggedUser(auth.getIdTokenClaims()));
        // dispatch(setuser(auth.getIdTokenClaims()));
        // console.log("getidtokenClaims", auth.getIdTokenClaims());
      }
    }
    if (pathname === "/auth-callback") return;
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn]);

  useEffect(()=>{
    dispatch(checkUserRoleCall({
      groupIds: groupVal
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  console.log(!loading && !error && role && adminUserRoles.includes(role));

  return (
    <Router key={userLoggedIn}>
      <ThemeProvider theme={theme}>
        {!userLoggedIn && (
          <Routes>
            <Route path="/" element={<div />}></Route>
            <Route
              exact
              path="/auth-callback"
              element={<AuthCallback />}
            ></Route>
          </Routes>
        )}
        {userLoggedIn && (
          <Layout>
            <Routes>
              <Route exact path="/" element={<SearchUser />}></Route>
              <Route exact path="/approval/:id" element={<Approvar />}></Route>
              <Route exact path="/admin" element={<AdminPortal/>}></Route>
              <Route exact path="/access-denied" element={<AccessDenied />}></Route>
              <Route
                path="*"
                element={
                  <div>
                    Reqested page is not available, got to{" "}
                    <Link to="/">Home</Link>{" "}
                  </div>
                }
              />
            </Routes>
          </Layout>
        )}
      </ThemeProvider>
    </Router>
  );
}

export default App;
