import React, { useState } from 'react';
// import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Tab, Tabs } from '@mui/material';
import RoleTab from '../Tabs/RoleTab';
import GroupsTab from '../Tabs/GroupsTab';
import TrainingsTab from '../Tabs/TrainingsTab';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";


const AddUpdateRoleModalNotice = (props) => {
  const { setIsModalOpen, mode, allAffiliteData, allRoles, allGroups, allTrainings, selectedRole, setActiveModalNotice } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const TAB_HEADS_NAME = {
    ROLE: "ROLE",
    GROUPS: "GROUPS",
    TRAININGS: "TRAININGS"
  };
  const tabHeaderArr = [
    TAB_HEADS_NAME.ROLE, TAB_HEADS_NAME.GROUPS, TAB_HEADS_NAME.TRAININGS
  ];

  const changeTabHandler = (event, newTabIdx) => {
    setTabIndex(newTabIdx);
  };

  const renderCurrentTab = (tabIdx) => {
    switch (tabHeaderArr[tabIdx]) {
      case TAB_HEADS_NAME.ROLE:
        return <RoleTab
          allRoles={allRoles}
          allAffiliteData={allAffiliteData}
          currentSelectedRole={selectedRole}
          selectedRoleId={selectedRole?.vamRoleId}
          setIsModalOpen={setIsModalOpen}
          setActiveModalNotice={setActiveModalNotice}
          mode={mode} />;
      case TAB_HEADS_NAME.GROUPS:
        return <GroupsTab
          allGroups={allGroups}
          setIsModalOpen={setIsModalOpen}
          selectedRoleId={selectedRole?.vamRoleId}
          setActiveModalNotice={setActiveModalNotice}
          currentSelectedGroups={selectedRole?.vamGroups}
          mode={mode} />;
      case TAB_HEADS_NAME.TRAININGS:
        return <TrainingsTab
          allTrainings={allTrainings}
          setIsModalOpen={setIsModalOpen}
          selectedRoleId={selectedRole?.vamRoleId}
          setActiveModalNotice={setActiveModalNotice}
          currentSelectedTrainings={selectedRole?.vamTrainings}
          mode={mode} />;

    }
  };

  return (
    <div className="roles_modalCard addUpdateRoleContainer">
      <div className="roles_modalCardHeader">
        <span>{mode === "create" ? "Create Role" : `Modify Role - "${selectedRole?.name}"`}</span>
        <span>
          <HighlightOffIcon
            sx={{
              mr: "5px",
              "&:hover": {
                color: "gray",
                transition: "color 0.2s"
              }
            }}
            onClick={() => setIsModalOpen(false)}
          ></HighlightOffIcon></span>
      </div>
      <div className="roles_modalCardBody">
        <Tabs
          value={tabIndex}
          centered
          onChange={changeTabHandler}
          sx={{
            '& .MuiTabs-indicator': { backgroundColor: "#f39b6e" },
            '& .MuiTab-root': { color: "#f39b6e" },
            '& .Mui-selected': { color: "#000" },
            '&:hover': {
              cursor: "pointer"
            }
          }}
        >
          {
            tabHeaderArr.map((tabName, tabIdx) => {
              return (
                <Tab key={tabIdx} label={tabName} disabled={mode === "edit" ? false : true} />);
            })
          }
        </Tabs>
        {
          renderCurrentTab(tabIndex)
        }
      </div>
    </div>
  );
};

export default AddUpdateRoleModalNotice;