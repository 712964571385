import { adminDashboard_actionType } from "../../actionTypes/adminAction-actionTypes/adminDashboard-actionType";

const initialState = {
  responseStore: [],
  requests: [],
  filterQuery: [],
  active_users: {
    lilly_users: 0,
    non_lilly_users: 0,
    total_count: 0
  },
  inactive_users: {
    lilly_users: 0,
    non_lilly_users: 0,
    total_count: 0
  },
  total_requests: {
    account_creations: 0,
    account_modifications: 0,
    total_count: 0
  },
  ageing_request: 0,
  test: null
};

const AdminDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case adminDashboard_actionType.RESET_DASHBOARD_SUCCESS:
      return state;
    case adminDashboard_actionType.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        requests: action.data,
        responseStore: action.responseStore,
        filterQuery: action.filterQuery
      };
    case adminDashboard_actionType.GET_DASHBOARD_FAILURE:
      return {
        ...state,
        error: action.data
      };
    case adminDashboard_actionType.GET_ADMIN_REQUEST_DETAIL_SUCCESS:
      return {
        ...state,
        active_users: action.data?.data?.active_users,
        ageing_request: action.data?.data?.ageing_request,
        inactive_users: action.data?.data?.inactive_users,
        total_requests: action.data?.data?.total_requests
      };
    default:
      return state;
  }
};

export default AdminDashboardReducer;