import React from "react";
import { Card, Grid } from "@mui/material";
import "./styles/Approval.css";

const SelectedRolesCard = ({ workflow, selectedRoles, gridHeight }) => {
  return (
    <Grid
      item
      sm={12}
      md={12}
      xs={12}
      height={gridHeight}
      className="approval_mainItemSection"
    >
      <Card
        className="approval_rolesProductsParent"
        height={"100%"}
        maxHeight={"96%"}
      >
        <div className="approval_header">
          <Grid container spacing={2}>
            <Grid item md={11} className="approval_mainHeading">
              Selected Roles
            </Grid>
          </Grid>
        </div>
        <div className="approval_section userDetailsSection">
          <Grid
            container
            spacing={2}
            className="userDetailsContainer"
            height={"100%"}
          >
            <Grid item md={2}>
              <strong>Affiliate</strong>
            </Grid>
            <Grid item md={10}>
              {workflow}
            </Grid>
            <Grid item md={2} marginTop={"10px"}>
              <strong>Roles</strong>
            </Grid>
            <Grid
              item
              md={10}
              height={"200px"}
              maxHeight={"200px"}
              overflow={"auto"}
              marginTop={"10px"}
            >
              {selectedRoles?.map((x) => x.name).join(" | ")}
            </Grid>
          </Grid>
        </div>
      </Card>
    </Grid>
  );
};

export default SelectedRolesCard;
