import { submitUserAccountRequest_actionType } from "../../actionTypes/userAction-actionTypes/submitUserAccountRequest-actionType";

const initialState = {
  isSubmitSuccess: false,
  message: []
};

const requestTypeValue = {
  "create" : "Create",
  "modify" : "Modify",
  "deactivate" : "Deactivate"
};

const fetchErrorMessage = (data) => {
  let msg = [];
  if(data){
    msg.push(data);
  }else{
    msg.push("Something went Wrong, Please try after some time!");
  }

  return msg;
};

const SubmitUserAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case submitUserAccountRequest_actionType.SUBMIT_ACTION_SUCCESS:
      return {
        ...state,
        isSubmitSuccess: true,
        message: [
            "Request Number " + action.data,
            requestTypeValue[action.requestType] + " Account Request Has Been Submitted."
        ]
      };
    case submitUserAccountRequest_actionType.SUBMIT_ACTION_FAILURE:
        return {
            ...state,
            isSubmitSuccess: false,
            message: fetchErrorMessage(action.data.response.data.errormessage)
        };
    default:
      return state;
  }
};

export default SubmitUserAccountReducer;
