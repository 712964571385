import { Grid, Card } from "@mui/material";
import React from "react";
import './styles/Approval.css';
// import { approvar_actionTypes } from "../utils/approval_actionType";
// const trainingsDummyData = [
//   {
//     id: 1,
//     trainingName: 'tr1'
//   },
//   {
//     id: 1,
//     trainingName: 'tr1'
//   },
//   {
//     id: 1,
//     trainingName: 'tr1'
//   },
//   {
//     id: 1,
//     trainingName: 'tr1'
//   }, {
//     id: 1,
//     trainingName: 'tr1'
//   }, {
//     id: 1,
//     trainingName: 'tr1'
//   }, {
//     id: 1,
//     trainingName: 'tr1'
//   }, {
//     id: 1,
//     trainingName: 'tr1'
//   },
//   {
//     id: 1,
//     trainingName: 'tr1'
//   }, {
//     id: 1,
//     trainingName: 'tr1'
//   }];
const Trainings = ({

  trainings
}) => {
  console.log(trainings);
  // console.log(editRequest);
  const trainingsRows = trainings?.map(function (columnValObj) {

    return (
      <tr key={columnValObj?.id}>
        <td width="30%" className="approval_tableHeading">
          {columnValObj?.id}
        </td>
        <td width="70%">{columnValObj?.trainingName}</td>
      </tr>
    );
  });

  return (
    <Grid
      item
      sm={12}
      xs={12}
      md={
        12
      }
      className="approval_mainItemSection"
      style={{ height: "165px" }}
      maxHeight={'165px'}
    >
      <Card
        className="approval_userDetailsParent"
        style={{ height: "100%" }}
        maxHeight={'70%'}
      >
        <div
          className="approval_header"

        >
          <Grid container spacing={2}>
            <Grid item md={11} className="approval_mainHeading">
              <div className="header_text_container"><div className="approvar_workFlowCard_header">
                <span className="workflow_Text">Trainings</span>
              </div>
                {/* <div className="approvar_workFlowCard_Text_Container">
                  <span className="approval_note">
                    <em>Note:</em>
                  </span>{" "}
                  <span className="approval_red"> ** </span>{" "}
                  <span className="note_text">
                    <em>Access will be provided only after completion of bellow training. </em>
                  </span>
                </div> */}
              </div>
            </Grid>
          </Grid>
        </div>
        <div
          className="approval_section"
          style={
            { overflowY: "auto", maxHeight: "115px", minHeight: "100px" }
          }
        >
          <table
            width="100%"
            className="approval_trainingsTable"
            style={{ maxHeight: "115px", minHeight: "100px", overflowY: "auto" }}
          >
            <thead>
              <tr align="left">
                <th width="30%">Curriculum ID</th>
                <th width="70%">Curriculum Name</th>
              </tr>
            </thead>
            <tbody>{trainingsRows}</tbody>
          </table>
        </div>
        {/* {(actionType === approvar_actionTypes.CONFIRM_TRAINING_COMPLETION && !editRequest) && (
          <div className="approvar_trainingsCheckbox">
            <FormControlLabel
              // className="approval_mainHeading"
              sx={{ marginLeft: "5px", position: "absolute" }}
              control={
                <Checkbox
                  checked={isTrainingConfirmed}
                  onChange={onTrainingConfirmation}
                  name="check"
                  sx={{
                    "&.Mui-checked": {
                      color: "#00AE43"
                    }
                  }}
                />
              }
              label={
                <span
                  style={{
                    fontSize: "12px",
                    fontStyle: "italic",
                    justifyContent: "center"
                  }}
                >
                  The user has completed all the mandatory trainings.
                </span>
              }
            />
          </div>
        )} */}
      </Card>
    </Grid>
  );
};

export default Trainings;
