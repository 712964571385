import config from "../../../authConfig.json";
const getCurrentEnv = () => {
  let env = "";
  const currentHostName = window.location.hostname;
  if (
    currentHostName === "dev.vam-vvmc.lilly.com" ||
    currentHostName === "localhost"
  )
    env = "dev";
  else if (currentHostName === "qa.vam-vvmc.lilly.com") env = "qa";
  else if (currentHostName === "vam-vvmc.lilly.com") env = "prod";
  else env = "";
  return env;
};
export default config[getCurrentEnv()];
