/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { pageTitle_actionTypes } from "../utils/pageTitle_actionType";
import { WorkFlow } from "./WorkFlow";
import "./styles/WorkFlow.css";
import { useSelector } from "react-redux";

const WorkFlowLoader = (props) => {
  const {
    pageTitle,
    currentTask,
    allAffiliatesResources,
    userAccessResources
  } = props;
  const { affiliateType, workFlowType, roles, selectedRoles } = useSelector(
    (state) => {
      return {
        affiliateType: state.SelectAffiliateReducer?.data.selectedAffiliateType,
        workFlowType: state.WorkFlowRolesReducer?.data.workFlowType,
        roles: state.WorkFlowRolesReducer?.data.roles,
        selectedRoles: state.WorkFlowRolesReducer?.selectedRoles
      };
    }
  );

  const stateToPropsWF = {
    affiliateType,
    workFlowType,
    roles: roles.sort((a, b) => a.name.localeCompare(b.name)),
    allAffiliatesResources,
    userAccessResources,
    selectedRoles
  };

  if (
    (pageTitle === pageTitle_actionTypes.CREATE_USER_ACCOUNT ||
      pageTitle === pageTitle_actionTypes.MODIFY_USER_ACCOUNT) &&
    currentTask == 1
  ) {
    return <WorkFlow {...stateToPropsWF} />;
  }
};

export default WorkFlowLoader;
