import { reassignAndTerminateRequestList_actionType } from "../../../actionTypes/adminAction-actionTypes/reassignAndTerminate/reassignAndTerminateRequestList-actionType";

const filterQueryInitialState = [
    {
        name: "Affiliate Type",
        accessor: "affiliate_type",
        values: []
    },
    {
        name: "Request Type",
        accessor: "request_type",
        values: [
            { name: "Create Account", value: "create", selected: false },
            { name: "Modify Account", value: "modify", selected: false },
            { name: "Deactivate Account", value: "deactivate", selected: false }
        ]
    }
];
const initialState = {
    loading: false,
    data: [],
    error: undefined,
    selectedRequest: {},
    filterData: filterQueryInitialState
};

const ReassignAndTerminateRequestListReducer = (state = initialState, action) => {
    switch (action.type) {
        case reassignAndTerminateRequestList_actionType.REASSIGN_AND_TERMINATE_REQUEST_LIST:
            return {
                ...state,
                loading: true
            };
        case reassignAndTerminateRequestList_actionType.REASSIGN_AND_TERMINATE_REQUEST_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        case reassignAndTerminateRequestList_actionType.SET_REASSIGN_AND_TERMINATE_FILTER_DATA:
            return {
                ...state,
                filterData: action.data
            };
        case reassignAndTerminateRequestList_actionType.REASSIGN_AND_TERMINATE_REQUEST_LIST_SELECT_REQUEST:
            return {
                ...state,
                selectedRequest: action.payload
            };
        case reassignAndTerminateRequestList_actionType.REASSIGN_AND_TERMINATE_REQUEST_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default ReassignAndTerminateRequestListReducer;