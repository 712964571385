import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Button, Paper, Stack } from "@mui/material";
import React, { useState } from "react";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { CSVLink } from "react-csv";
import "./styles/Groups.css";
import { useSelector } from "react-redux";

const GroupsTableSearch = ({ openGroupsModal, setSearchText }) => {
  const { groupData } = useSelector((state) => ({
    groupData: state.GroupReducer.groupData
  }));
  const [searchLocalText, setSearchLocalText] = useState("");

  const onChangeSearchLocalText = (event) => {
    setSearchLocalText(event.target.value);
    if (event.target.value === "") {
      handelClearSearchText();
    }
  };

  const handelClearSearchText = () => {
    setSearchLocalText("");
    setSearchText("");
  };

  const handelSearchSelected = () => {
    setSearchText(searchLocalText);
  };
  return (
    <div className="groupTableSearchContainer">
      <div style={{ display: "flex", alignItems: "center" }}>
        <Paper
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            width: 300,
            height: 35,
            borderRadius: "5px 0px 0px 5px",
            boxShadow: 0
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1, fontSize: "15px" }}
            placeholder="Search By Group Name"
            inputProps={{ "aria-label": "search by full name" }}
            value={searchLocalText}
            onChange={onChangeSearchLocalText}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.target.blur();
                handelSearchSelected();
              }
            }}
          />
          {searchLocalText.length > 0 ? (
            <IconButton
              type="button"
              sx={{ p: "5px" }}
              aria-label="search"
              onClick={handelClearSearchText}
            >
              <CloseIcon sx={{ fontSize: "1.6rem" }} />
            </IconButton>
          ) : null}
        </Paper>
        <Button
          //  disabled={searchLocalText.length >3 ? false:true}
          variant="contained"
          className={"group_searchButton"}
          onClick={handelSearchSelected}
          sx={{ height: "35px", width: "35px" }}
        >
          <SearchOutlinedIcon size="medium" className="searchIcon" />
        </Button>
      </div>

      <div className="reassignAndTerminate_FilterDownload">
        <Stack spacing={1} direction={"row"}>
          <Button
            sx={{
              height: 35,
              width: 120
            }}
            variant="contained"
            className="btn"
            size="medium"
            onClick={openGroupsModal}
          >
            Add Group
          </Button>
          <CSVLink
            data={
              groupData
                ? groupData.map(
                    ({ description, id, isDynamicCountry, name }) => {
                      return { id, name, description, isDynamicCountry };
                    }
                  )
                : []
            }
            filename={"groups.csv"}
          >
            <Button variant="contained" className="btn btn--download">
              <FileDownloadOutlinedIcon className="donwloadIcon" />
              <span>.csv</span>
            </Button>
          </CSVLink>
        </Stack>
      </div>
    </div>
  );
};

export default GroupsTableSearch;
