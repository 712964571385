import React from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
// import moment from "moment";
import "./styles/RolesTableBody.css";

const RolesTableBody = ({
    setMode,
    setModalMessage,
    setIsModalOpen,
    setActiveModalNotice,
    setSelectedRole,
    setIsError,
    setTwoButton,
    rows,
    columns
}) => {

    const editActionHandler = (row) => {
        setSelectedRole(row);
        setIsModalOpen(true);
        setMode("edit");
        setActiveModalNotice("addUpdate");
    };

    const deleteActionHandler = (row) => {
        setSelectedRole(row);
        setIsModalOpen(true);
        setMode("delete");
        setActiveModalNotice("message");
        setIsError(true);
        setTwoButton(true);
        setModalMessage([`"${row.name}"`, `Are you sure you want to delete this role ?`]);
    };
    console.log('//////');
    console.log(rows.length);
    console.log('//////');

    return (
        <tbody className="roles_tableBody">
            {rows.length > 0 ?
                rows.map((row, idx) => {
                    return (
                        <tr key={idx} className="roles_tableRow">
                            {columns.map((column) => {
                                const tData = column.accessor === "isElevatedRole" || column.accessor === "isActive" ? row[column.accessor] ? "Yes" : "No" : row[column.accessor];
                                if (column.actionable)
                                    return (
                                        <td key={column.accessor} className="roles_tableCell">
                                            <div className="roles_actionIconGroup">
                                                <span>
                                                    <EditOutlinedIcon
                                                        className="roles_actionIconGroupIcon"
                                                        onClick={() => editActionHandler(row)}
                                                    />
                                                </span>
                                                <span>
                                                    <DeleteForeverOutlinedIcon
                                                        className="roles_actionIconGroupIcon"
                                                        onClick={() => deleteActionHandler(row)}
                                                    />
                                                </span>
                                            </div>
                                        </td>
                                    );

                                return <td key={column.accessor} className="roles_tableCell">{tData}</td>;
                            })}
                        </tr>
                    );
                }) : <tr key={"noRecordsFound"} className="roles_tableRow"><td colSpan={9} style={{
                    textAlign: "center"
                }}>No records found!</td></tr>}
        </tbody>
    );
};

export default RolesTableBody;
