import { userDetail_actionType } from "../../actionTypes/userAction-actionTypes/userDetail-actionType";
import { errMsgValidatorUserDetail } from "./utility/errMsgValidatorUserDetail";
import { requiredFieldAvailableUserDetail } from "./utility/requiredFieldsUserDetail";

const initialState = {
  data: {
    id: "",
    veevaUserName: "",
    firstName: "",
    lastName: "",
    userType: "",
    emailId: "",
    timezone: "",
    language: "",
    locale: "",
    deactivated: false,
    isLilly: false,
    actionType: "CREATE",
    isExist: false,
    systemID: null,
    globalId: null,
    languageName:"",
    localeName:"",
    isOnboardedUser: false
  },
  requiredFieldAvailable: false,
  errorMsg:{},
  error: undefined
};

const UserDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case userDetail_actionType.SET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        data: action.payload,
        requiredFieldAvailable: requiredFieldAvailableUserDetail(
          action.payload
        ),
        errorMsg: errMsgValidatorUserDetail(
          action.payload
        ),
        error: undefined
      };
    case userDetail_actionType.SET_USER_DETAIL_FAILURE:
      return {
        ...state,
        error: action.payload,
        data: initialState
      };
    case userDetail_actionType.RESET_USER_DETAIL_SUCCESS:
      return{
        ...initialState
      };
    case userDetail_actionType.GET_USER_DETAIL_SUCCESS:
      return {
        ...state.data
      };
    case userDetail_actionType.GET_USER_DETAIL_FAILURE:
      return {
        ...state.error
      };
    default:
      return state;
  }
};

export default UserDetailReducer;
