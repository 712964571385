import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ROLES_TABLE_COLUMNS } from "./RolesTableColumn";
import RolesTableHeader from "./RolesTableHeader";
import RolesTableBody from "./RolesTableBody";
import "./styles/RolesTable.css";
import { Paper, Table, TableContainer } from "@mui/material";

const RolesTable = ({
  searchKeyword,
  // setSearchKeyword,
  setMode,
  filterOptions,
  setIsModalOpen,
  setSelectedRole,
  setModalMessage,
  setIsError,
  setTwoButton,
  setActiveModalNotice
}) => {
  const { allRoles } = useSelector((state) => {
    return {
      allRoles: state.RolesReducer.RolesListReducer.data
    };
  });

  const [rows, setRows] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let tempRows = [];
    if (allRoles?.length) {
      tempRows = [...allRoles];
    }
    setRows(tempRows);
  }, [allRoles]);

  useEffect(() => {
    let tempFilteredData = [...rows];
    setFilteredData(tempFilteredData);
  }, [rows]);

  useEffect(() => {
    let tempFilterData = [...rows];
    let isFilterApplied = false;

    if (filterOptions.length > 0) {
      filterOptions.forEach((filterOptionObj) => {
        let tempFilteredDataIndex = new Set();
        isFilterApplied = false;
        filterOptionObj.values.forEach((element) => {
          if (element.selected) {
            isFilterApplied = true;
            switch (filterOptionObj.accessor) {
              case "roleAudience":
                tempFilterData.map((dataObj, dataObjIdx) => {
                  if (
                    dataObj["roleAudience"].toLowerCase() ===
                    element.value.toLowerCase()
                  )
                    tempFilteredDataIndex.add(dataObjIdx);
                });
                break;
              case "license":
                tempFilterData.map((dataObj, dataObjIdx) => {
                  if (
                    dataObj["license"].toLowerCase() ===
                    element.value.toLowerCase()
                  )
                    tempFilteredDataIndex.add(dataObjIdx);
                });
                break;
              case "securityProfile":
                tempFilterData.map((dataObj, dataObjIdx) => {
                  if (
                    dataObj["securityProfile"].toLowerCase() ===
                    element.value.toLowerCase()
                  )
                    tempFilteredDataIndex.add(dataObjIdx);
                });
                break;
              case "securityPolicy":
                tempFilterData.map((dataObj, dataObjIdx) => {
                  if (
                    dataObj["securityPolicy"].toLowerCase() ===
                    element.value.toLowerCase()
                  )
                    tempFilteredDataIndex.add(dataObjIdx);
                });
                break;
              case "vamHubAbbreviation":
                tempFilterData.map((dataObj, dataObjIdx) => {
                  if (
                    dataObj["vamHubAbbreviation"].toLowerCase() ===
                    element.value.toLowerCase()
                  )
                    tempFilteredDataIndex.add(dataObjIdx);
                });
                break;
            }
          }
        });
        if (isFilterApplied) {
          tempFilterData = tempFilterData.filter(
            (filterDataObj, filterDataObjIdx) => {
              return (
                filterDataObj && tempFilteredDataIndex.has(filterDataObjIdx)
              );
            }
          );
        }
      });
    }

    if (searchKeyword.length) {
      tempFilterData = tempFilterData.filter((filterDataObj) => {
        return Object.values(filterDataObj)
          .join(" ")
          .toLowerCase()
          .includes(searchKeyword.toLowerCase());
      });
    }

    setFilteredData(tempFilterData);
  }, [rows, searchKeyword, filterOptions]);

  const sortHandler = (sortField, sortBy) => {
    if (sortField) {
      const sorted = [...filteredData].sort((a, b) => {
        if (a[sortField] === "") return 1;
        if (b[sortField] === "") return -1;
        if (a[sortField] === "" && b[sortField] === "") return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true
          }) * (sortBy === "asc" ? 1 : -1)
        );
      });
      setFilteredData(sorted);
    }
  };

  return (
    <div className="roles_tableContainer">
      <div className="roles_existingRolesCardHeader">
        <span>Existing Roles</span>
      </div>
      <div className="groupTableSectionContainer">
        <div className="groupTableSection">
          <TableContainer
            sx={{
              // height: `${tableHeigth > 40 ? tableHeigth : tableHeigth + 25}px`,
              maxHeight: "65vh",
              "::-webkit-scrollbar": {
                height: "4px"
              },
              overflowX: "auto"
            }}
            component={Paper}
          >
            <Table stickyHeader aria-label="Roles table">
              <RolesTableHeader
                columns={ROLES_TABLE_COLUMNS.columns}
                sortHandler={sortHandler}
              />
              <RolesTableBody
                rows={filteredData}
                columns={ROLES_TABLE_COLUMNS.columns}
                setMode={setMode}
                setIsModalOpen={setIsModalOpen}
                setModalMessage={setModalMessage}
                setSelectedRole={setSelectedRole}
                setIsError={setIsError}
                setTwoButton={setTwoButton}
                setActiveModalNotice={setActiveModalNotice}
              />
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default RolesTable;
