export const summary_actionType = {
    SET_TRAININGS_SUCCESS : 'SET_TRAININGS_SUCCESS',
    GET_TRAININGS_SUCCESS : 'GET_TRAININGS_SUCCESS',
    SET_TRAINING_AVAILABLE_SUCCESS : 'SET_TRAINING_AVAILABLE_SUCCESS',
    RESET_TRAININGS_SUCCESS : 'RESET_TRAININGS_SUCCESS',
    SET_TRAINING_COMPLETITION_DATE_SUCCESS: 'SET_TRAINING_COMPLETITION_DATE_SUCCESS',
    SET_COMMENTS_SUCCESS : 'SET_COMMENTS_SUCCESS',
    SET_EXISTINGSTATE_SUCCESS : 'SET_EXISTINGSTATE_SUCCESS',
    RESET_EXISTINGSTATE_SUCCESS: 'RESET_EXISTINGSTATE_SUCCESS',
    RESET_SUMMARY_SUCCESS: 'RESET_SUMMARY_SUCCESS'
};