import { React, useState } from 'react';
import "./styles/AdminMainSection.css";

import { adminPortalPageNavigation_actionType } from '../../utils/adminPortalPageNavigation_actionType';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useSelector } from 'react-redux';

const adminSection = [
    {
        categoryName: adminPortalPageNavigation_actionType.DASHBOARD,
        categoryTag: adminPortalPageNavigation_actionType.ADMIN_DASHBOARD,
        categoryList: []
    },
    {
        categoryName: adminPortalPageNavigation_actionType.DATA_CONFIGURATION,
        categoryTag: adminPortalPageNavigation_actionType.DATA_CONFIGURATION,
        categoryList: [
            {
                text: adminPortalPageNavigation_actionType.AFFLIATE,
                tag: adminPortalPageNavigation_actionType.AFFLIATE
            },
            {
                text: adminPortalPageNavigation_actionType.GROUPS,
                tag: adminPortalPageNavigation_actionType.GROUPS
            },
            {
                text: adminPortalPageNavigation_actionType.ROLES,
                tag: adminPortalPageNavigation_actionType.ROLES
            },
            {
                text: adminPortalPageNavigation_actionType.TRAINING,
                tag: adminPortalPageNavigation_actionType.TRAINING
            }, {
                text: "Terminate",
                tag: adminPortalPageNavigation_actionType.TERMINATE
            }
        ]
    }
];

function AdminMainSection({
    setPageNavigationList,
    setActiveSection,
    activeSection
}) {
    const { userRole } = useSelector((state) => {
        return {
            userRole: state.CheckUserRoleReducer.role
        };
    });
    const [activeSectionCategory, setActiveSectionCategory] = useState("");
    const isLoggedUserSuperAdmin = userRole === "superAdmin" ? true : false;

    // all JS Function
    const handelNavigationClicked = (text) => {
        switch (text) {
            case adminPortalPageNavigation_actionType.ADMIN_DASHBOARD:
                setActiveSectionCategory(adminPortalPageNavigation_actionType.ADMIN_DASHBOARD);
                setActiveSection(adminPortalPageNavigation_actionType.ADMIN_DASHBOARD);
                setPageNavigationList([adminPortalPageNavigation_actionType.ADMIN_DASHBOARD]);
                break;
            case adminPortalPageNavigation_actionType.DATA_CONFIGURATION:
                setActiveSectionCategory(adminPortalPageNavigation_actionType.DATA_CONFIGURATION);
                setActiveSection(adminPortalPageNavigation_actionType.AFFLIATE);
                setPageNavigationList([
                    adminPortalPageNavigation_actionType.DATA_CONFIGURATION,
                    adminPortalPageNavigation_actionType.AFFLIATE
                ]);
                break;
            // case adminPortalPageNavigation_actionType.COMPANY:
            //     setActiveSection(adminPortalPageNavigation_actionType.COMPANY);
            //     setPageNavigationList([
            //         adminPortalPageNavigation_actionType.DATA_CONFIGURATION,
            //         adminPortalPageNavigation_actionType.COMPANY
            //     ]);
            //     break;
            case adminPortalPageNavigation_actionType.AFFLIATE:
                setActiveSection(adminPortalPageNavigation_actionType.AFFLIATE);
                setPageNavigationList([
                    adminPortalPageNavigation_actionType.DATA_CONFIGURATION,
                    adminPortalPageNavigation_actionType.AFFLIATE
                ]);
                break;
            case adminPortalPageNavigation_actionType.GROUPS:
                setActiveSection(adminPortalPageNavigation_actionType.GROUPS);
                setPageNavigationList([
                    adminPortalPageNavigation_actionType.DATA_CONFIGURATION,
                    adminPortalPageNavigation_actionType.GROUPS
                ]);
                break;
            case adminPortalPageNavigation_actionType.ROLES:
                setActiveSection(adminPortalPageNavigation_actionType.ROLES);
                setPageNavigationList([
                    adminPortalPageNavigation_actionType.DATA_CONFIGURATION,
                    adminPortalPageNavigation_actionType.ROLES
                ]);
                break;
            case adminPortalPageNavigation_actionType.TERMINATE:
                setActiveSection(adminPortalPageNavigation_actionType.TERMINATE);
                setPageNavigationList([
                    adminPortalPageNavigation_actionType.DATA_CONFIGURATION,
                    adminPortalPageNavigation_actionType.TERMINATE
                ]);
                break;
            case adminPortalPageNavigation_actionType.TRAINING:
                setActiveSection(adminPortalPageNavigation_actionType.TRAINING);
                setPageNavigationList([
                    adminPortalPageNavigation_actionType.DATA_CONFIGURATION,
                    adminPortalPageNavigation_actionType.TRAINING
                ]);
                break;

        }
    };
    return (
        <div className='adminMainSection'>
            {
                adminSection.map((adminSectionObject, adminSectionKey) => {
                    if (!isLoggedUserSuperAdmin && adminSectionKey >= 1)
                        return null;
                    return (
                        <div
                            key={adminSectionKey}
                        >
                            <div
                                onClick={() => handelNavigationClicked(adminSectionObject.categoryTag)}
                                className={'clickable adminMainSectionCategory borderBottom' + ((activeSection === adminSectionObject.categoryTag) ? " adminMainSectionCategorySelected" : "")}
                            >
                                <span>
                                    <span>{adminSectionObject.categoryName}</span>
                                    {
                                        adminSectionObject.categoryList.length > 0 ?
                                            <span className='adminMainSectionArrow'>
                                                {
                                                    activeSectionCategory === adminSectionObject.categoryTag ?
                                                        <ArrowDropDownIcon /> : <ArrowRightIcon />
                                                }
                                            </span>
                                            : null
                                    }
                                </span>
                            </div>
                            {
                                activeSectionCategory === adminSectionObject.categoryTag ?
                                    adminSectionObject.categoryList.map((adminSectionInnerObject, adminSectionInnerKey) => {
                                        if (adminSectionInnerKey === 7)
                                            return null;
                                        return (
                                            <div
                                                key={adminSectionInnerKey}
                                                onClick={() => handelNavigationClicked(adminSectionInnerObject.tag)}
                                                className={
                                                    adminSectionObject.categoryList.length - 1 === adminSectionInnerKey ?
                                                        ('clickable adminMainSectionInnerCategory' + ((activeSection === adminSectionInnerObject.tag) ? " adminMainSectionCategorySelected" : ""))
                                                        : ('clickable adminMainSectionInnerCategory borderBottom' + ((activeSection === adminSectionInnerObject.tag) ? " adminMainSectionCategorySelected" : ""))
                                                }
                                            >{adminSectionInnerObject.text}</div>
                                        );
                                    }) : null
                            }
                        </div>
                    );
                })
            }
        </div>
    );
}

export default AdminMainSection;