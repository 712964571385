import { axiosApigateway } from "../../../api";
import { axiosMsGraph } from "../../../api";
import { searchUserResources_actionType } from "../../actionTypes/userAction-actionTypes/searchUserResources-actionType";

export function resetSearchUserResources() {
  return {
    type: searchUserResources_actionType.RESET_SEARCH_USER_RESOURCES
  };
}

/* MS GRAPH API */
async function getAllUsersInGraph(data) {
  const toSelect = [
    "id",
    "displayName",
    "preferredLanguage",
    "country",
    "usageLocation",
    "officeLocation",
    "givenname",
    "surname",
    "jobtitle",
    "mail",
    "onPremisesSamAccountName",
    "onPremisesDomainName",
    "onPremisesImmutableId",
    "onPremisesLastSyncDateTime",
    "onPremisesUserPrincipalName",
    "employeeId"
  ].join(",");
  const orderBy = ["displayName", "mail"].join(",");
  const queryString = `$filter=accountEnabled eq true AND employeeId ne null&$search="displayName: ${data}" OR "mail:${data}"&$select=${toSelect}&$orderby=${orderBy}&$count=true`;
  const finalURL = `/users?${queryString}`;
  return axiosMsGraph.get(finalURL);
}

/* DB API */
async function getAllUsersInDB(data) {
  const finalEndPoint = `/users?$search=*${data}*`;
  return axiosApigateway
    .get(finalEndPoint)
    .then((res) => res.data)
    .catch((err) => err);
}

async function getAllUserInBoth(data) {
  const combinedResponse = await Promise.all([
    getAllUsersInGraph(data),
    getAllUsersInDB(data)
  ]);

  let adUserList = combinedResponse[0]?.data?.value;
  let dbUserList = combinedResponse[1]?.data;

  let adAndDbUserList = [];

  dbUserList.forEach((userDb) => {
    const adUser = adUserList.find(
      (userAd) => userAd?.mail?.toLowerCase() === userDb?.email?.toLowerCase()
    );
    const globalId = adUser ? adUser.employeeId : "";
    adAndDbUserList.push({
      ...userDb,
      isExist: true,
      employeeId: globalId
    });
  });

  adUserList.forEach((userAd) => {
    const adUserExist = dbUserList.find(
      (userDb) => userDb?.email?.toLowerCase() === userAd?.mail?.toLowerCase()
    );
    if (!adUserExist) {
      adAndDbUserList.push({
        ...userAd,
        isExist: false
      });
    }
  });
  return adAndDbUserList;
}

export function searchUserResources(data) {
  return async (dispatch) => {
    dispatch({
      type: searchUserResources_actionType.SEARCH_USER_RESOURCES
    });
    try {
      const response = await getAllUserInBoth(data);
      if (response) {
        dispatch({
          type: searchUserResources_actionType.SEARCH_USER_RESOURCES_SUCCESS,
          payload: response
        });
      }
    } catch (error) {
      dispatch({
        type: searchUserResources_actionType.SEARCH_USER_RESOURCES_ERROR,
        payload: error || "Something went wrong, Please try after sometime!"
      });
    }
  };
}
