import { axiosApigateway } from "../../../../api";
import { reassignAndTerminateReassignTask_actionType } from "../../../actionTypes/adminAction-actionTypes/reassignAndTerminate/reassignAndTerminateReassignTask-actionType";

export function reassignAndTerminateReassignTask() {
  return {
    type: reassignAndTerminateReassignTask_actionType.REASSIGN_AND_TERMINATE_REASSIGN_TASK
  };
}

export function reassignAndTerminateReassignTaskSuccess(data) {
  return {
    type: reassignAndTerminateReassignTask_actionType.REASSIGN_AND_TERMINATE_REASSIGN_TASK_SUCCESS,
    payload: data
  };
}

export function reassignAndTerminateReassignTaskError(error) {
  return {
    type: reassignAndTerminateReassignTask_actionType.REASSIGN_AND_TERMINATE_REASSIGN_TASK_ERROR,
    payload: error
  };
}

export function reassignAndTerminateReassignTaskReset() {
  return {
    type: reassignAndTerminateReassignTask_actionType.REASSIGN_AND_TERMINATE_REASSIGN_TASK_RESET
  };
}

export function reassignAndTerminateReassignTaskCall(data) {
  // console.log(data, "reassignTask");
  return async (dispatch) => {
    dispatch(reassignAndTerminateReassignTask());

    try {
      const response = await axiosApigateway.put("/admin-assign-approval", data);
      if (response) {
        const responseData = response.data.message;
        dispatch(reassignAndTerminateReassignTaskSuccess(responseData));
      }
    } catch (error) {
      dispatch(reassignAndTerminateReassignTaskError(error));
    }
  };
}
