import { summary_actionType } from '../../actionTypes/userAction-actionTypes/summary-actionType';

export function resetTrainingsReducer() {
    return (dispatch) => {
        dispatch({
            type: summary_actionType.RESET_TRAININGS_SUCCESS
        });
    };
}

// export function setTrainingsToReducer(trainingsData) {
//     return (dispatch) => {
//         dispatch({
//             type: summary_actionType.SET_TRAININGS_SUCCESS,
//             data: trainingsData
//         });
//     };
// }

export function setCommentsToReducer(commentsData) {
    return (dispatch) => {
        dispatch({
            type: summary_actionType.SET_COMMENTS_SUCCESS,
            data: commentsData
        });
    };
}

export function setTrainingAvailableToReducer(trnAvailData) {
    return (dispatch) => {
        dispatch({
            type: summary_actionType.SET_TRAINING_AVAILABLE_SUCCESS,
            data: trnAvailData
        });
    };
}

export function setTrainingCompletitionToReducer(trCompleData) {
    return (dispatch) => {
        dispatch({
            type: summary_actionType.SET_TRAINING_COMPLETITION_DATE_SUCCESS,
            data: trCompleData
        });
    };
}

export function setExistingStateToReducer(existingData) {
    return (dispatch) => {
        dispatch({
            type: summary_actionType.SET_EXISTINGSTATE_SUCCESS,
            data: existingData
        });
    };
}

export function resetExistingStateToReducer() {
    return (dispatch) => {
        dispatch({
            type: summary_actionType.RESET_EXISTINGSTATE_SUCCESS
        });
    };
}

export function resetSummaryReducer() {
    return (dispatch) => {
        dispatch({
            type: summary_actionType.RESET_SUMMARY_SUCCESS
        });
    };
}