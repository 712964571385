import React, { useState } from "react";
import {
  Button,
  // Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
// import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import "./Workflow.css";
import { useDispatch } from "react-redux";
import { requestSelectedRolesSuccess } from "../../../redux/actions/approvar-actions/approvar-action";

const WorkflowCard = (props) => {
  const { workFlowType, roles, selectedRoles } = props;
  const [rolesSelected, setRolesSelected] = useState(selectedRoles);
  const dispatch = useDispatch();
  // const { allProducts } = useSelector(state => ({
  //   allProducts: state.ApprovarReducer?.allProducts
  // }));

  // useEffect(() => {
  //   setRolesSelected(selectedRoles);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const changeRoleHandler = (event, role) => {
    const { checked } = event.target;
    const newArr = checked
      ? [...rolesSelected, role]
      : rolesSelected?.filter((rle) => rle.vam_role_id !== role.vam_role_id);
    setRolesSelected(newArr);
    dispatch(requestSelectedRolesSuccess(newArr));
    // dispatch(getFreshProducts(allProducts));
  };

  const renderSelectUserRoles = () => {
    return (
      <div className="" style={{ padding: "16px" }}>
        <span>
          <strong>Select User Roles</strong>
        </span>
        <Grid
          container
          style={{
            paddingTop: "8px",
            maxHeight: "200px",
            overflowY: "scroll"
          }}
        >
          {roles && roles.length
            ? roles.map((label) => (
                <Grid item xs={12} sm={6} md={4} key={label.id} className="">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rolesSelected?.some(
                            (role) => role.vam_role_id === label.vam_role_id
                          )}
                          onChange={(event) => changeRoleHandler(event, label)}
                          sx={{
                            "&.Mui-checked": {
                              color: "#00AE43"
                            }
                          }}
                          className="rolesCheckboxClass"
                        />
                      }
                      label={
                        <Typography
                          className="rolesCheckboxLabelClass"
                          style={{ fontSize: "0.95rem" }}
                        >
                          {label.name}
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Grid>
              ))
            : null}
        </Grid>
        {rolesSelected && rolesSelected.length ? (
          <div style={{ paddingTop: "8px" }}>
            <div style={{ display: "flex", justifyContent: "left" }}>
              <span className="">
                <strong>Selected User Roles</strong>
              </span>
              <span style={{ marginLeft: "20px" }}>
                {rolesSelected && rolesSelected.length ? (
                  <span className="workflow_selectUserRolesAction">
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        setRolesSelected([]);
                        dispatch(requestSelectedRolesSuccess([]));
                      }}
                      sx={{
                        padding: "0.5rem 1rem",
                        ":hover": {
                          bgcolor: "red",
                          color: "white"
                        }
                      }}
                    >
                      Delete All
                    </Button>
                  </span>
                ) : null}
              </span>
            </div>
            <Stack
              direction="row"
              key={roles?.id}
              sx={{ flexWrap: "wrap", width: "97%", margin: "0.5rem auto" }}
            >
              {rolesSelected && rolesSelected.length
                ? rolesSelected.map((label) => (
                    <Chip
                      key={label.vam_role_id}
                      deleteIcon={
                        <CancelOutlinedIcon
                          sx={{
                            "&.MuiChip-deleteIcon": {
                              color: "#df271b",
                              fontSize: "1.4rem",
                              "&:hover": {
                                color: "#df271b"
                              }
                            }
                          }}
                        />
                      }
                      label={label.name}
                      variant="outlined"
                      onDelete={(event) => changeRoleHandler(event, label)}
                      sx={{ margin: "0.5rem" }}
                      className="rolesChipClass"
                    />
                  ))
                : null}
            </Stack>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <Grid item sm={12} md={12} spacing={1}>
      <div className="approvar_workFlowCard">
        <div className="header_container">
          <div className="header_text_container">
            <div className="approvar_workFlowCard_header">
              <span className="workflow_Text">Affiliate</span>
              <span className="workFlow_selected">
                {workFlowType?.toUpperCase()}
              </span>
            </div>
            {/* <div className="approvar_workFlowCard_Text_Container">
              <span className="approval_note">
                <em>Note:</em>
              </span>
              <span className="approval_red"> ** </span>{" "}
              <span className="note_text" >
                <em>
                  Any change in role selection will remove product selection in current session.
                </em>
              </span>
            </div> */}
          </div>
        </div>
        {renderSelectUserRoles()}
      </div>
    </Grid>
  );
};

export default WorkflowCard;
