import React from "react";
import Button from "@mui/material/Button";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import RotateRightIcon from '@mui/icons-material/RotateRight';

const DeactivateModalNotice = ({
  error,
  success,
  message,
  isPending=false,
  toggleToLandingPage,
  twoButton,
  buttonOneText = "NO",
  buttonTwoText = "YES",
  buttonOneAction,
  buttonTwoAction
}) => {
  const successHandler = () => {
    toggleToLandingPage();
  };
  const noClickHandler = () => {
    buttonTwoAction();
  };
  const yesClickHandler = () => {
    buttonOneAction();
  };
  return (
    <div className="deactivate_modalContainer">
      <div
        className={
          error || !success
            ? "deactivate_topBorderError"
            : "deactivate_topBorderSuccess"
        }
      ></div>
      <div className="deactivate_icon">
        {error  ? (
          <div className="deactivate_iconError">
            <HelpOutlineIcon
              sx={{ width: "2.54em", height: "2em", color: "#D52B1E" }}
            />
          </div>
        ) :isPending ? (
          <div className="deactivate_iconSuccess">
            <RotateRightIcon
              sx={{ width: "2.54em", height: "2em", color: "#00AE43" }}
            />
          </div>
        ) : success ? (
          <div className="deactivate_iconSuccess">
            <CheckCircleOutlineIcon
              sx={{ width: "2.54em", height: "2em", color: "#00AE43" }}
            />
          </div>
        ) : (
          <div className="deactivate_iconFail">
            <CancelOutlinedIcon
              sx={{ width: "2.54em", height: "2em", color: "red" }}
            />
          </div>
        )}
      </div>
      <div className="deactivate_message">
        {message.map((messageObject, messageKey) => {
          return (
            <span
              key={messageKey}
            >
              {messageObject}
            </span>
          );
        })}
      </div>
      <div className="deactivate_actionButton">
        {twoButton ? (
          <div className="deactivate_actionButtonError">
            <Button
              className="buttonDanger"
              variant="outlined"
              onClick={noClickHandler}
              color={"error"}
              sx={{
                color: "#D52B1E",
                ":hover": {
                  bgcolor: "#D52B1E",
                  color: "white"
                }
              }}
            >
              {buttonOneText}
            </Button>
            <Button
              className="deactivate_buttonSuccess"
              variant="outlined"
              onClick={yesClickHandler}
              color="success"
              sx={{
                color: "#00AE43",
                ":hover": {
                  bgcolor: "#00AE43",
                  color: "white"
                }
              }}
            >
              {buttonTwoText}
            </Button>
          </div>
        ) : (
          <Button
            className={success ? "deactivate_buttonSuccess" : "buttonDanger"}
            variant="outlined"
            color={success ? "success" : "error"}
            sx={{
              background: "white",
              color: success ? "#00AE43" : "#D52B1E",
              ":hover": {
                bgcolor: success ? "#00AE43" : "#D52B1E",
                color: "white"
              }
            }}
            onClick={successHandler}
          >
            OK
          </Button>
        )}
      </div>
    </div>
  );
};

export default DeactivateModalNotice;
