export const ROLES_TABLE_COLUMNS = {
  columns: [
    { name: "Name", accessor: "name", sortable: true },
    { name: "Description", accessor: "description", sortable: true },
    { name: "Elevated Role", accessor: "isElevatedRole", sortable: true },
    // { name: "Require Products", accessor: "isRequireProducts", sortable: true },
    { name: "User Type", accessor: "roleAudience", sortable: true },
    { name: "License", accessor: "license", sortable: true },
    { name: "Security Profile", accessor: "securityProfile", sortable: true },
    // { name: "Security Policy", accessor: "securityPolicy", sortable: true },
    { name: "Affiliate", accessor: "vamHubAbbreviation", sortable: true },
    { name: "Active", accessor: "isActive", sortable: true },
    { name: "Action", accessor: null, actionable: true, sortable: false }
  ]
};
