import { loader_actionType } from "../actionTypes/loader-actionType";

export function setLoaderActive() {
    return (dispatch) => {
        dispatch({
            type: loader_actionType.SET_LOADER_ON
        });
    };
}

export function setLoaderInActive() {
    return (dispatch) => {
        dispatch({
            type: loader_actionType.SET_LOADER_OFF
        });
    };
}

// admin Dashboard
export function setAdminDashboardLoaderActive() {
    return (dispatch) => {
        dispatch({
            type: loader_actionType.SET_ADMIN_DASHBOARD_LOADER_ON
        });
    };
}

export function setAdminDashboardLoaderInActive() {
    return (dispatch) => {
        dispatch({
            type: loader_actionType.SET_ADMIN_DASHBOARD_LOADER_OFF
        });
    };
}

export function setViewAdminDashboardLoaderActive() {
    return (dispatch) => {
        dispatch({
            type: loader_actionType.SET_VIEW_ADMIN_DASHBOARD_LOADER_ON
        });
    };
}

export function setViewAdminDashboardLoaderInActive() {
    return (dispatch) => {
        dispatch({
            type: loader_actionType.SET_VIEW_ADMIN_DASHBOARD_LOADER_OFF
        });
    };
}

// admin product
export function setAdminProductoaderActive() {
    return (dispatch) => {
        dispatch({
            type: loader_actionType.SET_ADMIN_PRODUCT_LOADER_ON
        });
    };
}

export function setAdminProductoaderInActive() {
    return (dispatch) => {
        dispatch({
            type: loader_actionType.SET_ADMIN_PRODUCT_LOADER_OFF
        });
    };
}

// admin affiliate
export function setAdminAffiliateLoaderActive() {
    return (dispatch) => {
        dispatch({
            type: loader_actionType.SET_ADMIN_AFFILIATE_LOADER_ON
        });
    };
}

export function setAdminAffiliateLoaderInActive() {
    return (dispatch) => {
        dispatch({
            type: loader_actionType.SET_ADMIN_AFFILIATE_LOADER_OFF
        });
    };
}