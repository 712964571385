import React from "react";
import UserDetailsLoader from "../UserDetails/UserDetailsLoader";
import WorkFlowLoader from "../WorkFlow/WorkFlowLoader";

const CreateModifyScreen = ({
  currentTask,
  pageTitle,
  toggleToLandingPage,
  completeState
}) => {
  const allAffiliatesResources = completeState.AffiliateResourcesReducer?.data;
  const userAccessResources = completeState.UserAccessResourcesReducer?.data;

  return (
    <>
      {currentTask >= 0 ? (
        <>
          <UserDetailsLoader
            pageTitle={pageTitle}
            currentTask={currentTask}
            toggleToLandingPage={toggleToLandingPage}
            allAffiliatesResources={allAffiliatesResources}
            userAccessResources={userAccessResources}
          />
        </>
      ) : null}
      {currentTask == 1 ? (
        <>
          <WorkFlowLoader
            pageTitle={pageTitle}
            currentTask={currentTask}
            allAffiliatesResources={allAffiliatesResources}
            userAccessResources={userAccessResources}
          />
        </>
      ) : null}
    </>
  );
};

export default CreateModifyScreen;
