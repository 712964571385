import React from "react";
import { UserDetailsSection } from "./UserDetailsSection";
import { SelectAffiliateSection } from "./SelectAffiliateSection";

export const UserDetails = (props) => {
  const {
    userDetails,
    allAffiliates,
    allAffiliatesResources,
    selectAffiliate,
    pageTitle,
    toggleToLandingPage,
    userAccessResources,
    allUserDetailResources,
    completeState
  } = props;

  const userDetailsSectionProps = {
    userDetails,
    pageTitle,
    toggleToLandingPage,
    allUserDetailResources
  };

  const selectAffiliateSectionProps = {
    allAffiliates,
    selectAffiliate,
    allAffiliatesResources,
    userAccessResources,
    userDetails,
    completeState
  };

  return (
    <div className="userDetails_selectAffiliate_container">
      <UserDetailsSection {...userDetailsSectionProps} />
      <SelectAffiliateSection {...selectAffiliateSectionProps} />
    </div>
  );
};
