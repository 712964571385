import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
// import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import { Link } from "@mui/material";
import "./styles/Header.css";

const headerHeight = "53px";

export default function MenuAppBar() {
  const { loggedUser } = useSelector((state) => ({
    loggedUser: state.LoggedUserReducer.loggedUser
  }));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="transparent">
        <Toolbar
          sx={{
            height: headerHeight,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 24px 0 !important"
          }}
        >
          <div>
            <Link href="/" sx={{}}>
              <img
                src="/vvmc-ams.png"
                style={{ maxHeight: "44px" }}
                alt="Lilly | VVMC Logo"
              />
            </Link>
          </div>
          <div>
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="transparent"
            >
              {loggedUser.name}
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
