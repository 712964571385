// import { setLoaderActive, setLoaderInActive } from "../loaderAction";
import { submitApprovarRequest_actionType } from "../../actionTypes/approvar-actionTypes/submitApprovarRequest-actionType";
import { axiosApigateway } from "../../../api";
import { resetLoader, setLoader } from "./approvar-action";

export function submitApprovarRequest(taskId, requestedData, requestType, taskType) {
    return async (dispatch) => {
        dispatch(setLoader());
        dispatch({ type: submitApprovarRequest_actionType.SUBMIT_ACTION_START });
        try {

            // calling API
            const response = await axiosApigateway.put(`/approval-tasks/${taskId}`, requestedData);
            if (response) {
                dispatch({
                    type: submitApprovarRequest_actionType.SUBMIT_ACTION_SUCCESS,
                    data: response.data.data,
                    requestType: requestType,
                    taskType: taskType
                });

                setTimeout(() => {
                    dispatch(resetLoader());
                }, 2500);
                return { response, success: true };
            }

        } catch (error) {
            dispatch({
                type: submitApprovarRequest_actionType.SUBMIT_ACTION_FAILURE,
                data: error,
                requestType: requestType
            });
            setTimeout(() => {
                dispatch(resetLoader());
            }, 2500);
        }
    };
}