import React from "react";
import WorkflowCard from "./WorkflowCard";
// import { useSelector } from "react-redux";

const Workflow = ({ hubname, selectedRoles, allRoles }) => {
  // const { reducerData } = useSelector((state) => ({ reducerData: state.ApprovarReducer.data }));
  const sortedRoles =
    allRoles.sort((a, b) => a.name.localeCompare(b.name)) || [];
  const stateToPropsWF = {
    workFlowType: hubname || "",
    roles: sortedRoles,
    selectedRoles: selectedRoles || []
  };

  // const stateToPropsWF = {
  //   workFlowType,
  //   roles,
  //   selectedRoles
  // };

  return <WorkflowCard {...stateToPropsWF} />;
};

export default Workflow;
