import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import UserDetailsCard from "./UserDetailsCard";
import ModalMainContainer from "../ModalNotification/ModalMainContainer";
import ApprovarHeader from "./ApprovarHeader";
import Workflow from "./Workflow";
// import Product from "./Product";
import Trainings from "./Trainings";
import CommentsCard from "./CommentsCard";
import SelectedRolesCard from "./SelectedRolesCard";
// import SelectedProductsCard from "./Product/SelectedProductsCard";
import ButtonError from "./ButtonError";
import ButtonSuccess from "./ButtonSuccess";
import { useDispatch, useSelector } from "react-redux";

// import { approvar_actionTypes } from "../utils/approval_actionType";

import "./styles/Approval.css";

import {
  getAllApprovalRoles,
  requestSelectedRolesSuccess,
  updateApprovarRequestData
} from "../../redux/actions/approvar-actions/approvar-action";
import { submitApprovarRequest } from "../../redux/actions/approvar-actions/submitApprovarRequest-action";
import { approvar_actionType } from "../../redux/actionTypes/approvar-actionTypes/approvar-actionType";
import { submitApprovarRequest_actionType } from "../../redux/actionTypes/approvar-actionTypes/submitApprovarRequest-actionType";
import TrainingDateSelector from "./TrainingDateSelector";
// import { axiosMsGraph } from "../../api";

// This will replace after api integration
// const initinalState = {
//   sponserDetails: {
//     // loginId: "123456",
//     // name: "David Clark",
//     // email: "david.clark@lilly.com"
//   }
// };

export default function ApprovarScreen({ actionType, id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editRequest, setEditRequest] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitActive, setIsSubmitActive] = useState(false);
  const [modalMessage, setModalMessage] = useState([]);
  const [disableApproveBtn, setDisableApproveBtn] = useState(true);
  const [isSubmitSuceess, setIsSubmitSuccess] = useState(false);
  const [disableReassignBtn, setDisableReassignBtn] = useState(true);
  // const [isTrainingConfirmed, setIsTrainingConfirmed] = useState(false);
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const {
    reducerData,
    users_access_payload,
    sponsorData,
    hubsData,
    taskId,
    trainingCompletionDate,
    completeState,
    actionButtonState,
    loading,
    userDetails,
    trainings
  } = useSelector((state) => ({
    reducerData: state.ApprovarReducer?.data,
    users_access_payload:
      state.ApprovarReducer?.data?.vam_request?.request_payload
        ?.users_access_payload,
    taskId: state.ApprovarReducer?.data?.taskId,
    completeState: state,
    actionButtonState: state.ApprovarReducer?.actionButtonState,
    loading: state.ApprovarReducer?.loading,
    userDetails: state.ApprovarReducer.data?.userDetails,
    trainings: state.ApprovarReducer?.trainings,
    hubsData: state.ApprovarReducer?.hubsData,
    sponsorData: state.ApprovarReducer?.sponsorData,
    trainingCompletionDate: state.ApprovarReducer?.trainingCompletionDate
  }));

  // const userType = userDetails?.email?.toLowerCase()?.includes("@lilly.com") ? "Lilly" : "Non-Lilly";
  const [approverComments, setApprovarComments] = useState(
    reducerData.approverComments
  );

  const { isRequesetComplete } = useSelector((state) => ({
    isRequesetComplete: state.SubmitApprovarRequestReducer.isRequesetComplete
  }));
  const { isEditOrUpdateFailed } = useSelector((state) => ({
    isEditOrUpdateFailed: state.ApprovarReducer?.isEditOrUpdateFailed
  }));

  useEffect(() => {
    if (
      !loading &&
      actionButtonState?.isRejectHidden &&
      actionButtonState?.isApproveHidden
    ) {
      navigate("/access-denied");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, actionButtonState]);

  useEffect(() => {
    if (isConsentChecked) {
      setDisableApproveBtn(false);
    } else {
      setDisableApproveBtn(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConsentChecked]);

  useEffect(() => {
    if (sponsorData?.employeeId && disableReassignBtn) {
      setDisableReassignBtn(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sponsorData?.employeeId]);

  const handleRejectButton = () => {
    handleOpen(true);
    setIsSubmitActive(false);
    setModalMessage(["Are you sure you want to reject this task?"]);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const onHandleEditRequest = () => {
    dispatch(
      getAllApprovalRoles(
        hubsData,
        completeState?.ApprovarReducer?.selectedProducts
      )
    );
    setEditRequest(true);
  };

  const handleReactivate = () => {
    setIsModalOpen(false);
    submitRequest("reject");
  };

  const onSaveHandler = () => {
    dispatch(updateApprovarRequestData(id, completeState));
  };

  const onCommentsHandler = (e) => {
    setApprovarComments(e.target.value);
  };

  useEffect(() => {
    if (isRequesetComplete) {
      setIsSubmitActive(true);
      if (completeState.SubmitApprovarRequestReducer?.isSubmitSuccess) {
        setIsSubmitSuccess(true);
      } else {
        setIsSubmitSuccess(false);
      }
      setModalMessage(completeState.SubmitApprovarRequestReducer?.message);
      handleOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequesetComplete]);

  useEffect(() => {
    if (isEditOrUpdateFailed) {
      setIsSubmitActive(true);
      setIsSubmitSuccess(false);
      setModalMessage([completeState.ApprovarReducer?.error]);
      handleOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditOrUpdateFailed]);

  const handleSubmitRequest = (event) => {
    const requestType = event.target.id;
    submitRequest(requestType);
  };

  const submitRequest = (requestType) => {
    const loggedUserMail =
      completeState?.LoggedUserReducer?.loggedUser?.username;
    const requestdUserMail = userDetails?.email;
    if (loggedUserMail === requestdUserMail) {
      dispatch({
        type: submitApprovarRequest_actionType.APPROVE_OWN_REQUEST_FAILURE
      });
      return;
    }
    const taskType = reducerData?.taskType;
    let requestedData;
    let actionData;
    requestedData = {
      request_id: id,
      action_type: requestType,
      update_type: "approval"
    };
    actionData = {
      taskAction: requestType,
      taskAssignedTo: reducerData?.taskAssignedTo,
      approverComments: approverComments
    };
    // }
    dispatch(
      submitApprovarRequest(
        taskId,
        { ...requestedData, ...actionData },
        requestType,
        taskType
      )
    );
  };

  const renderSelectedRolesAndTrainingDateSelector = () => {
    return (
      <>
        {
          <Grid
            item
            sm={12}
            md={trainings?.length > 0 ? 6 : 12}
            xs={12}
            height={trainings?.length > 0 ? "315px" : "200px"}
          >
            <SelectedRolesCard
              workflow={users_access_payload?.name}
              selectedRoles={users_access_payload?.vam_roles}
              gridHeight={trainings?.length > 0 ? "300px" : "180px"}
            />
          </Grid>
        }
        {trainings?.length > 0 ? (
          <Grid
            item
            sm={12}
            md={6}
            xs={12}
            style={{ paddingTop: "0px" }}
            height={"315px"}
          >
            {
              <>
                <Trainings trainings={trainings} />
                <TrainingDateSelector
                  selectedDate={trainingCompletionDate}
                  requestId={id}
                  isEditable={
                    actionButtonState?.isEditEnabled &&
                    reducerData?.taskStatus === "active"
                  }
                />
              </>
              // </Grid>
            }
          </Grid>
        ) : (
          ""
        )}
        {/* Requester Comments */}
        <CommentsCard
          name="requesterComments"
          label="Requestor Comments"
          value={reducerData?.vam_request?.requester_comments}
          isDisabled={true}
          approvarComments={false}
        />

        {/* Approvar Comments */}
        <CommentsCard
          name="approvarComments"
          label="Approver Comments"
          value={approverComments}
          onChangeHandler={onCommentsHandler}
          approvarComments={true}
          isDisabled={reducerData?.taskStatus !== "active"}
        />
      </>
    );
  };

  const renderEditWorkflowAndTrainingDateSelector = () => {
    return (
      <>
        <Workflow
          hubname={users_access_payload?.name}
          selectedRoles={completeState.ApprovarReducer?.selectedRoles}
          allRoles={completeState.ApprovarReducer?.allRoles}
        />
      </>
    );
  };

  const renderConfirmCheckBox = () => {
    return (
      <div className="confirmCheckBoxContainer">
        <FormControlLabel
          control={
            <Checkbox
              checked={isConsentChecked}
              onChange={(event) => {
                console.log("event", event);
                const { checked } = event.target;
                setIsConsentChecked(checked);
              }}
              name="check"
              sx={{
                "&.Mui-checked": {
                  color: "#00AE43"
                }
              }}
            />
          }
          label={
            <span
              style={{
                fontSize: "14px",
                fontStyle: "italic"
              }}
            >
              I have confirmed that the access is appropriate and consistent
              with required job functionalities, also confirmed that all
              trainings are completed by user.
            </span>
          }
        />
      </div>
    );
  };

  return (
    <>
      <div className="homeContainer">
        <ApprovarHeader
          handleEditRequest={onHandleEditRequest}
          actionType={actionType}
          editRequest={editRequest}
          isEditEnabled={
            actionButtonState?.isEditEnabled &&
            reducerData?.taskStatus === "active"
          }
          isEditHidden={actionButtonState?.isEditHidden}
          id={reducerData?.vam_request?.request_id}
        />
        <div className="homeContainerCards">
          <div className="card">
            <div>
              <Grid
                container
                spacing={0}
                className="accountSumary_parentSection"
              >
                {/* User Details Section */}
                <UserDetailsCard userDetails={reducerData?.userDetails} />
                {editRequest
                  ? renderEditWorkflowAndTrainingDateSelector()
                  : renderSelectedRolesAndTrainingDateSelector()}
              </Grid>
            </div>
          </div>
        </div>

        {!editRequest && renderConfirmCheckBox()}
        <div className="homeContainerButtons">
          {editRequest ? (
            <>
              <ButtonError
                label="Cancel"
                disabled={false}
                onClickHandler={() => {
                  dispatch(
                    requestSelectedRolesSuccess(users_access_payload?.vam_roles)
                  );
                  setEditRequest(false);
                }}
              />
              <ButtonSuccess
                label="Save"
                disabled={false}
                onClickHandler={onSaveHandler}
              />
            </>
          ) : (
            <>
              {!actionButtonState?.isRejectHidden && (
                <ButtonError
                  id="reject"
                  label="Reject"
                  disabled={
                    !actionButtonState?.isRejectEnabled ||
                    reducerData?.taskStatus !== "active"
                  }
                  onClickHandler={handleRejectButton}
                />
              )}
              {!actionButtonState?.isApproveHidden && (
                <ButtonSuccess
                  id="approve"
                  label="Approve"
                  disabled={
                    !actionButtonState?.isApproveEnabled ||
                    disableApproveBtn ||
                    reducerData?.taskStatus !== "active"
                  }
                  onClickHandler={(evt) => handleSubmitRequest(evt)}
                />
              )}
            </>
          )}
        </div>
        <ModalMainContainer
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isSubmitActive={isSubmitActive}
          modalMessage={modalMessage}
          isSubmitSuceess={isSubmitSuceess}
          reactivate={true}
          handleReactivate={handleReactivate}
          toggleToLandingPage={() => {
            setIsModalOpen(false);
            if (isRequesetComplete) {
              navigate("/");
            }
            if (isEditOrUpdateFailed) {
              dispatch({
                type: approvar_actionType.RESET_ERROR_MODEL
              });
            }
          }}
        />
        {/* <Modal
          open={editSponser}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div style={ModalStyle}>
            <SelectedSponsorPopup
              onClose={handleClose}
              onSetSponsor={handleSetSponsor}
              loggedUser={completeState?.LoggedUserReducer?.loggedUser?.username}
              userMailId={userDetails?.email}

            />
          </div>
        </Modal> */}
      </div>
    </>
  );
}
