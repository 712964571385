import { axiosApigateway } from "../../../../api";
import { reassignAndTerminateRequestList_actionType } from "../../../actionTypes/adminAction-actionTypes/reassignAndTerminate/reassignAndTerminateRequestList-actionType";

export function reassignAndTerminateRequestList() {
  return {
    type: reassignAndTerminateRequestList_actionType.REASSIGN_AND_TERMINATE_REQUEST_LIST
  };
}

export function reassignAndTerminateRequestListSuccess(data) {
  return {
    type: reassignAndTerminateRequestList_actionType.REASSIGN_AND_TERMINATE_REQUEST_LIST_SUCCESS,
    payload: data
  };
}

export function reassignAndTerminateRequestListError(error) {
  return {
    type: reassignAndTerminateRequestList_actionType.REASSIGN_AND_TERMINATE_REQUEST_LIST_ERROR,
    payload: error
  };
}

export function reassignAndTerminateRequestListSelectRequest(data) {
  return {
    type: reassignAndTerminateRequestList_actionType.REASSIGN_AND_TERMINATE_REQUEST_LIST_SELECT_REQUEST,
    payload: data
  };
}

const getUniqueFilterData = (allRequests) => {
  let uniqueAffiliateType = new Set();

  let uniqueAffiliateTypeList = [];
  let filterQuery = filterQueryInitialState;

  // getting unique values
  allRequests.forEach(element => {
    uniqueAffiliateType.add(element?.request_payload?.users_access_payload?.name);
  });

  //converting to list
  uniqueAffiliateType.forEach(element => {
    if (element)
      uniqueAffiliateTypeList.push(element);
  });




  filterQuery[0].values = uniqueAffiliateTypeList.map((object) => {
    return {
      name: object,
      selected: false
    };
  });


  return filterQuery;
};

const filterQueryInitialState = [
  {
    name: "Affiliate Type",
    accessor: "affiliate_type",
    values: [
    ]
  },
  {
    name: "Request Type",
    accessor: "request_type",
    values: [
      { name: "Create Account", value: "create", selected: false },
      { name: "Modify Account", value: "modify", selected: false },
      { name: "Deactivate Account", value: "deactivate", selected: false }
    ]
  }
];

function getReassignAndTerminateRequestList(data) {
  let dataList = [];
  data.forEach((dataObject) => {
    const formattedDataObject = {
      request_id: dataObject?.request_id ?? "",
      request_type: dataObject?.requestType ?? "",
      user_type: dataObject?.request_payload?.user_type ?? "",
      full_name: dataObject?.request_payload?.display_name ?? "",
      system_id: dataObject?.request_payload?.federated_id ?? "",
      veeva_username: dataObject?.request_payload?.veeva_username ?? "",
      affiliate_type:
        dataObject?.request_payload?.users_access_payload?.name ?? "",
      last_action_date: dataObject?.updatedAt
    };
    dataList.push(formattedDataObject);
  });

  return dataList;
}



export function reassignAndTerminateRequestListCall() {
  return async (dispatch) => {
    dispatch(reassignAndTerminateRequestList());

    try {
      const response = await axiosApigateway.get(`/admin-vvmc-reassign-request?request_status=pending`);
      if (response) {
        const responseData = response.data.data;
        const filterData = getUniqueFilterData(responseData);
        dispatch({
          type: reassignAndTerminateRequestList_actionType.SET_REASSIGN_AND_TERMINATE_FILTER_DATA,
          data: filterData
        });
        const data = getReassignAndTerminateRequestList(responseData);
        dispatch(reassignAndTerminateRequestListSuccess(data));

      }
    } catch (error) {
      dispatch(reassignAndTerminateRequestListError(error));
    }
  };
}
