import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./styles/Affiliate.css";

// import CircularProgressLoader from '../../../../../../components/common/CircularProgressLoader';
import FilterInAffiliate from './FilterInAffiliate';
import AffiliateTable from './AffiliateTable';
import { getAllAffilateData } from '../../../../../../redux/actions/adminAccount-actions/affiliate-action';
import ModalAddNewAffiliate from './ModalAddNewAffiliate';
import AdminMainArticleLoader from '../../AdminMainArticleLoader/AdminMainArticleLoader';

function Affliate() {
  const [searchText, setSearchText] = useState("");
  const [isAddNewProductModalOpen, setIsAddNewProductModalOpen] = useState(false);
  const [addAffiliateModaMode, setaddAffiliateModaMode] = useState("create");
  const [currentSelection, setCurrentSelection] = useState();
  const dispatch = useDispatch();
  const { isLoader } = useSelector(state => ({
    isLoader: state?.AdminAffiliateReducer?.isLoader
  }));

  useEffect(() => {
    dispatch(getAllAffilateData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoader) {
      // reset all filter and serach to default
      setSearchText("");
    }
  }, [isLoader]);


  const handelToggleAddNewModal = (mode = "create") => {
    setIsAddNewProductModalOpen(!isAddNewProductModalOpen);
    setaddAffiliateModaMode(mode);
  };

  if (isLoader) {
    return (<AdminMainArticleLoader />);
  }
  return (
    <div className='affiliateContainer'>
      <FilterInAffiliate
        searchText={searchText}
        setSearchText={setSearchText}
        handelToggleAddNewModal={handelToggleAddNewModal}
      />
      <AffiliateTable
        searchText={searchText}
        handelToggleAddNewModal={handelToggleAddNewModal}
        setCurrentSelection={setCurrentSelection}
      />
      <ModalAddNewAffiliate
        isAddNewProductModalOpen={isAddNewProductModalOpen}
        handelToggleAddNewModal={handelToggleAddNewModal}
        addAffiliateModaMode={addAffiliateModaMode}
        currentSelection={currentSelection}
      />
    </div>
  );
}

export default Affliate;