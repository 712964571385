import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import './styles/Affiliate.css';
import Modal from "@mui/material/Modal";
// import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Button from "@mui/material/Button";
import Success from "../SuccessFailureModal/Success";
import Failure from "../SuccessFailureModal/Failure";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { addAffiliate, editAffiliate } from "../../../../../../redux/actions/adminAccount-actions/affiliate-action";
// import { getAllAffilateData } from "../../../../../../redux/actions/adminAccount-actions/affiliate-action";

import { Paper } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { FormControl, Grid } from "@mui/material";
import { affiliate_actionType } from "../../../../../../redux/actionTypes/adminAction-actionTypes/affiliate-actionType";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "45%",
  border: 0,
  borderRadius: "10px",
  backgroundColor: "white",
  outline: "none"
};

function ModalAddNewAffiliate({
  isAddNewProductModalOpen,
  handelToggleAddNewModal,
  addAffiliateModaMode,
  currentSelection
}) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [affiliateName, setAffiliateName] = useState("");
  const [affiliateAbbr, setAffiliateAbbr] = useState("");
  const [description, setDescription] = useState("");
  const [afterSubmitModal, setAfterSubmitModal] = useState(false);
  // const [openToast, setopenToast] = useState(false);
  const dispatch = useDispatch();
  const { isSubmitSuccess, submitResponse, isRequestCompleted } = useSelector((state) => ({
    isRequestCompleted: state?.AdminAffiliateReducer?.isRequestCompleted,
    isSubmitSuccess: state?.AdminAffiliateReducer?.isRequestSuccess,
    submitResponse: state?.AdminAffiliateReducer?.message
  }));

  useEffect(() => {
    if (isRequestCompleted) {
      setAfterSubmitModal(true);

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },
    [isRequestCompleted]);


  useEffect(() => {
    // let data = [];
    switch (addAffiliateModaMode) {
      case "edit":
        setAffiliateAbbr(currentSelection?.abbreviation);
        setDescription(currentSelection?.description);
        setAffiliateName(currentSelection?.name);
        setIsEditMode(true);
        break;
      default:
        setAffiliateAbbr("");
        setDescription("");
        setAffiliateName("");
        setIsEditMode(false);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addAffiliateModaMode]);

  const handleClose = () => {
    handelToggleAddNewModal();
  };
  const handelDataRefresh = () => {
    dispatch({
      type: affiliate_actionType.RESET_AFFILIATE_EDIT_MODAL_STATE
    });
    // dispatch(resetReducerCorrespondingToCountries());
  };

  const handelSave = () => {
    // let response;
    handelToggleAddNewModal();
    if (!isEditMode) {
      dispatch(
        addAffiliate({
          abbreviation: affiliateAbbr,
          name: affiliateName,
          description: description
        })
      );
    }
    if (isEditMode) {
      dispatch(
        editAffiliate(affiliateAbbr, {
          abbreviation: affiliateAbbr,
          name: affiliateName,
          description: description
        })
      );
    }
    // if (response) {
    //   setTimeout(() => {
    //     setAfterSubmitModal(true);
    //   }, 3000);
    // }
  };

  // const handleCloseToast = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setopenToast(false);
  // };

  // const state = {
  //   vertical: "top",
  //   horizontal: "center"
  // };
  // const { vertical, horizontal } = state;
  return (
    <>
      <Modal
        open={isAddNewProductModalOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="fitermodel-affiliate" style={ModalStyle}>
          <div>
            <div>
              <div className="addNewProductContainerCard">
                <div className="adminAddAffliateCardHeader">
                  <span className="cardHeaderText">
                    {isEditMode
                      ? "Update Affiliate - " + affiliateAbbr
                      : "Add Affiliates"}
                  </span>
                  <span>
                    <HighlightOffIcon
                      sx={{
                        mr: "5px",
                        "&:hover": {
                          color: "gray",
                          transition: "color 0.2s"
                        }
                      }}
                      onClick={handleClose}
                    ></HighlightOffIcon></span>
                </div>
                {createHub({
                  affiliateName,
                  setAffiliateName,
                  affiliateAbbr,
                  setAffiliateAbbr,
                  description,
                  setDescription,
                  isEditMode
                })}
              </div>
            </div>
          </div>

          {/* <CancelOutlinedIcon
            className="affiliate_crossModalIcon"
            onClick={handleClose}
          /> */}
          <div className="modalAffiliateFooter">
            <div className="modalAffiliateButtons">
              <Button
                className="buttonDanger"
                variant="outlined"
                onClick={handleClose}
                color="error"
                sx={{
                  width: 100,
                  height: 35,
                  fontSize: 12,
                  ":hover": {
                    bgcolor: "red",
                    color: "white"
                  }
                }}
              >
                {isEditMode ? "Close" : "Cancel"}
              </Button>
              <Button
                className="buttonSuccess"
                variant="outlined"
                onClick={handelSave}
                color="success"
                disabled={affiliateName?.length > 0 && affiliateAbbr?.length > 0 ? false : true}
                sx={{
                  width: 100,
                  height: 35,
                  fontSize: 12,
                  ":hover": {
                    bgcolor: "green",
                    color: "white"
                  }
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {isRequestCompleted ? isSubmitSuccess ? (
        <Success
          isModalOpen={afterSubmitModal}
          setIsModalOpen={setAfterSubmitModal}
          modalMessage={submitResponse}
          refreshDataAction={handelDataRefresh}
          scrollerMessage={false}
        />
      ) : (
        <Failure
          isModalOpen={afterSubmitModal}
          setIsModalOpen={setAfterSubmitModal}
          modalMessage={submitResponse}
          refreshDataAction={handelDataRefresh}
          scrollerMessage={false}
        />
      ) : ''}
      {/* {isSubmitSuccess ? (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={openToast}
          autoHideDuration={1000}
          onClose={handleCloseToast}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%" }}
          >
            Request Success!!!
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={openToast}
          autoHideDuration={1000}
          onClose={handleCloseToast}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%" }}
          >
            Request Failed!!!
          </Alert>
        </Snackbar>
      )} */}
    </>
  );
}

function createHub({
  affiliateName,
  setAffiliateName,
  affiliateAbbr,
  setAffiliateAbbr,
  description,
  setDescription,
  isEditMode
}) {
  const onChangeName = (e) => {
    setAffiliateName(e.target.value);
  };
  const onChangeAbbr = (e) => {
    setAffiliateAbbr(e.target.value);
  };
  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };
  return (
    <div className="adminAddAffliateCardForm">
      <div className="adminAddAffliateCardFormLineTwo">
        <Grid container spacing={1}>
          <Grid item sm={4} md={3.9}>
            <div className="adminAddAffiliateCardFormInputLabel">
              <span>
                <span>Affiliate Code</span>
                <span className="dangerColor">*</span>
              </span>
            </div>
          </Grid>
          <Grid item sm={4} md={7} >
            <FormControl className="dropdownSelectInput" size="small" width={"100%"} disabled={isEditMode} >
              <Paper
                className="adminAddAffiliateCardFormInputField"
                component="form"

                sx={{
                  display: "flex",
                  height: "35px",
                  border: "0.5px solid gray",
                  borderRadius: "5px",
                  boxShadow: 0,
                  background: `${isEditMode ? '#EBEBE4' : ''}`
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Enter Affiliate Code"
                  inputProps={{ "aria-label": "Enter Affiliate Code" }}
                  value={affiliateAbbr}
                  onChange={onChangeAbbr}

                />
              </Paper>
            </FormControl>
          </Grid>
        </Grid>
      </div>
      <div className="adminAddAffliateCardFormLineTwo">
        <Grid container spacing={1}>
          <Grid item sm={4} md={3.9}>
            <div className="adminAddAffiliateCardFormInputLabel">
              <span>
                <span>Affiliate Name</span>
                <span className="dangerColor">*</span>
              </span>
            </div>
          </Grid>
          <Grid item sm={8} md={7}>
            <FormControl className="dropdownSelectInput" width={"100%"} size="small">
              <Paper
                className="adminAddAffiliateCardFormInputField"
                component="form"
                sx={{
                  display: "flex",
                  height: "35px",
                  border: "0.5px solid gray",
                  borderRadius: "5px",
                  boxShadow: 0
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Enter Affiliate Name"
                  inputProps={{ "aria-label": "Enter Affiliate Name" }}
                  value={affiliateName}
                  onChange={onChangeName}
                />
              </Paper>
            </FormControl>
          </Grid>
        </Grid>
      </div>

      <div className="adminAddAffliateCardFormLineTwo">
        <Grid container spacing={1}>
          <Grid item sm={4} md={3.9}>
            <div className="adminAddAffiliateCardFormInputLabel">
              <span>
                <span>Description</span>
              </span>
            </div>
          </Grid>
          <Grid item sm={8} md={7}>
            <FormControl className="dropdownSelectInput" size="small" width={"100%"}>
              <Paper
                // className="adminAddAffiliateCardFormInputField"
                component="form"
                sx={{
                  display: "flex",
                  height: "35px",
                  border: "0.5px solid gray",
                  borderRadius: "5px",
                  boxShadow: 0
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Description"
                  inputProps={{ "aria-label": "Description" }}
                  value={description}
                  onChange={onChangeDescription}
                />
              </Paper>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ModalAddNewAffiliate;
