import React, { useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Card, Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import './styles/Approval.css';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
// import { updateTrainingCompletionDate } from '../../redux/actions/approvar-actions/approvar-action';
// import { approvar_actionType } from '../../redux/actionTypes/approvar-actionTypes/approvar-actionType';
import { changeTrainingCompletionDateHandler } from '../../redux/actions/approvar-actions/approvar-action';
import ModalMainContainer from '../ModalNotification/ModalMainContainer';
import { approvar_actionType } from '../../redux/actionTypes/approvar-actionTypes/approvar-actionType';
// import { Label } from '@mui/icons-material';


export default function TrainingDateSelector({ selectedDate, requestId, isEditable }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
    const dispatch = useDispatch();
    const { trainingCompletionDateMsg,
        isTrainingDateRequestCompleted,
        isTrainingUpdateSuccess } = useSelector(state => ({
            trainingCompletionDateMsg: state.ApprovarReducer?.trainingCompletionDateMsg,
            isTrainingDateRequestCompleted: state.ApprovarReducer?.isTrainingDateRequestCompleted,
            isTrainingUpdateSuccess: state.ApprovarReducer?.isTrainingUpdateSuccess
        }));
    const onDateChange = (date) => {
        const dateString = moment(new Date(date)).format("YYYY-MM-DD");
        // console.log(dateString);
        dispatch(changeTrainingCompletionDateHandler(requestId, dateString));
    };
    const handleReactivate = () => {
        setIsModalOpen(false);
        dispatch({
            type: approvar_actionType.RESET_TRAINING_COMPLETION
        });
    };

    useEffect(() => {

        if (isTrainingDateRequestCompleted) {
            if (isTrainingUpdateSuccess) {
                setIsSubmitSuccess(true);
            } else {
                setIsSubmitSuccess(false);
            }
            setModalMessage(trainingCompletionDateMsg);
            setIsModalOpen(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTrainingDateRequestCompleted, isTrainingUpdateSuccess]);
    // console.log(isDateEditable + '////////');
    return (
        <>
            <Grid item sm={12} md={12} className="approval_mainItemSection" height={'100px'} maxHeight={'100px'} >
                <Card className="approval_userDetailsParent">
                    <div className="approval_header">
                        <Grid container spacing={1}>
                            <Grid item md={5} className="approval_mainHeading">
                                Training Completion Date
                            </Grid>
                            <Grid item md={7} sm={6} spacing={2} style={{ padding: '0px' }}>
                                <div className="approvar_workFlowCard_Text_Container">
                                    <span className="approval_note">
                                        <em>Note:</em>
                                    </span>
                                    <span className="approval_red"> ** </span>{" "}
                                    <span className="note_text">
                                        <em>Please make sure you complete all mandatary trainings, Access will be provided only after completion of trainings. </em>
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="approval_section userDetailsSection">
                        <Grid container spacing={2} height={'30%'}>
                            <Grid item md={12} sm={6} spacing={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '60%' }} >
                                <strong>Training Completion Date: </strong>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker disableFuture={true}
                                        sx={{ width: '50%' }}
                                        disabled={!isEditable}
                                        // defaultValue={dayjs('2022-04-17')}
                                        value={dayjs(selectedDate)}
                                        // value={selectedDate}
                                        onChange={onDateChange}
                                        label={'MM/DD/YYYY'}

                                    />
                                </LocalizationProvider>

                            </Grid>

                        </Grid>
                    </div>
                </Card>
            </Grid >
            <ModalMainContainer
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                isSubmitActive={true}
                modalMessage={[modalMessage]}
                isSubmitSuceess={isSubmitSuccess}
                reactivate={true}
                handleReactivate={handleReactivate}
                toggleToLandingPage={() => {
                    setIsModalOpen(false);
                    dispatch({
                        type: approvar_actionType.RESET_TRAINING_COMPLETION
                    });
                }}
            />
        </>
    );
}
