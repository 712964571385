
import ButtonError from "./ButtonError";
import '../styles/Home.css';
const ApprovarHeader = ({ handleEditRequest, editRequest, id, isEditEnabled, isEditHidden }) => {
  let pageSubTitle = 'Approval Task.';
  console.log(isEditEnabled);
  return (
    <div className="pageTitleContainer">
      <div className="pageTitleText">
        <span className="pageTitleHeader">VVMC Account Request #{id}</span>
        <span className="pageTitleNavigator" style={{ fontSize: '14px' }}>{pageSubTitle}</span>
      </div>
      {(!isEditHidden && !editRequest &&
        <div className="pageTitleIcon">
          <div className="pageTitleIconBox">
            <ButtonError label="Edit" onClickHandler={handleEditRequest}
              disabled={!isEditEnabled}
            />
          </div>
        </div>
      )}
      {/* <ButtonError label="Edit" onClickHandler={handleEditRequest} /> */}
    </div>
  );
};

export default ApprovarHeader;
