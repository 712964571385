import { axiosApigateway } from "../../../api";
import { checkAccountStatusResources_actionType } from "../../actionTypes/userAction-actionTypes/checkAccountStatusResources-actionType";

export function resetCheckAccountStatus() {
  return {
    type: checkAccountStatusResources_actionType.RESET_CHECK_ACCOUNT_STATUS
  };
}

function getAccountStatusData(data) {
  if (data.length === 0) return [];
  return [{
    requestStatus: data.request_status,
    requestName: data.request_id
  }];
}

export function checkAccountStatus(data) {
  // const { requestStatus, requestedFor, requestType } = data;
  const { requestStatus, requestedFor} = data;
  return async (dispatch) => {
    dispatch({
      type: checkAccountStatusResources_actionType.CHECK_ACCOUNT_STATUS
    });

    try {
      // const queryString = `requestType=${requestType}&request_status=${requestStatus}&requested_for=${requestedFor}`;
      const queryString = `request_status=${requestStatus}&requested_for=${requestedFor}`;
      const response = await axiosApigateway.get(`/requests?${queryString}`);
      if (response) {
        const [responseData] = response.data.data;
        const accountStatusData = getAccountStatusData(responseData);
        dispatch({
          type: checkAccountStatusResources_actionType.CHECK_ACCOUNT_STATUS_SUCCESS,
          payload: accountStatusData
        });
      }
      // console.log("checkAccountStatus", responseData);
    } catch (error) {
      dispatch({
        type: checkAccountStatusResources_actionType.CHECK_ACCOUNT_STATUS_ERROR,
        payload: error || "Something went wrong, Please try after sometime!"
      });
    }
  };
}
