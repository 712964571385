import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import "../styles/AdminDashboardTableSearch.css";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import FilterModal from "./FilterModal";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

function AdminDashboardTableSearch({
  setSearchText,
  filterQueryInitialState,
  filterQuery,
  setFilterQuery
}) {
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [searchLocalText, setSearchLocalText] = useState("");
  const [downloadCSV, setDownloadCSV] = useState([]);
  const { allRequest } = useSelector((state) => ({
    allRequest: state.AdminDashboardReducer.requests
  }));

  useEffect(() => {
    let tempAllRequest = allRequest.map((element) => {
      return {
        "Request ID": element.requestID,
        "User Name": element.userName,
        "Full Name": element.fullName,
        "Global ID": element.globalID,
        "User Type": element.userType,
        "Request Type": element.requestType,
        "Current Status": element.currentStatus,
        Affiliate: element.affiliate,
        "Last Action Date": element.lastActionDate
      };
    });

    setDownloadCSV(tempAllRequest);
  }, [allRequest]);

  const handelSearchSelected = () => {
    setSearchText(searchLocalText.trim());
  };
  const onChangeSearchLocalText = (event) => {
    setSearchLocalText(event.target.value);

    if (event.target.value === "") setSearchText("");
  };
  const handelClearSearchText = () => {
    setSearchLocalText("");
    setSearchText("");
  };
  const toggleOpenFilterBox = () => {
    setFilterModalOpen(!filterModalOpen);
  };
  return (
    <div className="adminDashboardTableSearch">
      <div style={{ display: "flex", alignItems: "center" }}>
        <Paper
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            width: 230,
            height: 35,
            borderRadius: "5px 0px 0px 5px",
            boxShadow: 0
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1, fontSize: "15px" }}
            placeholder="Search"
            inputProps={{ "aria-label": "search by full name" }}
            value={searchLocalText}
            onChange={onChangeSearchLocalText}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.target.blur();
                handelSearchSelected();
              }
            }}
          />
          {searchLocalText.length > 0 ? (
            <IconButton
              type="button"
              sx={{ p: "5px" }}
              aria-label="search"
              onClick={handelClearSearchText}
            >
              <CloseIcon sx={{ fontSize: "1.6rem" }} />
            </IconButton>
          ) : null}
        </Paper>
        <Button
          variant="contained"
          className={"reassignAndTerminateSearchUser_searchButton"}
          onClick={handelSearchSelected}
          sx={{ height: "35px", width: "35px" }}
        >
          <SearchOutlinedIcon size="medium" className="searchIcon" />
        </Button>
      </div>

      <div className="filterContainer">
        <div className="reassignAndTerminate_FilterDownload">
          <Button
            variant="contained"
            className="btn btn--filter"
            onClick={toggleOpenFilterBox}
          >
            <FilterAltOutlinedIcon className="filterIcon" />
          </Button>
          <CSVLink data={downloadCSV} filename="requests.csv">
            <Button variant="contained" className="btn btn--download">
              <FileDownloadOutlinedIcon className="donwloadIcon" />
              <span>.csv</span>
            </Button>
          </CSVLink>
        </div>
      </div>
      <FilterModal
        isModalOpen={filterModalOpen}
        toggleOpenFilterBox={toggleOpenFilterBox}
        filterQueryInitialState={filterQueryInitialState}
        filterQuery={filterQuery}
        setFilterQuery={setFilterQuery}
      />
    </div>
  );
}

export default AdminDashboardTableSearch;
