import { React, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import "../styles/AdminDashboardTable.css";

import AdminDashboardTableRecord from "./AdminDashboardTableRecord";
import AdminDashboardTableSearch from "./AdminDashboardTableSearch";
import AdminDashboardTableBox from "./AdminDashboardTableBox";
import AdminMainArticleLoader from '../../AdminMainArticleLoader/AdminMainArticleLoader';

function AdminDashboardTable({
  hadelToggleIsViewAdminDashboardActive,
  setViewAdminRequestId
}) {
  const [searchText, setSearchText] = useState("");
  const [filterQuery, setFilterQuery] = useState([]);
  const { isAdminLoaderActive, filterQueryInitialState } = useSelector((state) => ({
    isAdminLoaderActive: state.LoaderReducer.adminDashboardLoader,
    filterQueryInitialState: state.AdminDashboardReducer.filterQuery
  }));

  // all useEffects
  useEffect(() => {
    setFilterQuery(filterQueryInitialState);
  }, [filterQueryInitialState]);

  if(isAdminLoaderActive)
    return <AdminMainArticleLoader />;
  return (
    <div className='adminDashboardTableContainer'>
        <AdminDashboardTableRecord />
        <AdminDashboardTableSearch 
          setSearchText={setSearchText}
          filterQueryInitialState={filterQueryInitialState}
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
        />
        <AdminDashboardTableBox 
          searchText={searchText} 
          setSearchText={setSearchText}
          filterQuery={filterQuery}
          hadelToggleIsViewAdminDashboardActive={hadelToggleIsViewAdminDashboardActive}
          setViewAdminRequestId={setViewAdminRequestId}
        />
    </div>
  );
}

export default AdminDashboardTable;