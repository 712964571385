import { approvar_actionType } from "../../actionTypes/approvar-actionTypes/approvar-actionType";
const initialState = {
  loading: false,
  data: {},
  trainings: [],
  trainingCompletionDate: null,
  error: undefined,
  isUpdating: false,
  isUpdated: false,
  isEditOrUpdateFailed: false,
  trainingCompletionDateMsg: '',
  isTrainingDateRequestCompleted: false,
  isTrainingUpdateSuccess: false
  // retryExceptionSuccess: false,
  // retryTrainingSuccessMessage: ''
  // isExceptionAccessible: false
  // sponsorData: {
  //   employeeId: null,
  //   displayName: null,
  //   mail: null
  // }
};

const ApprovarReducer = (state = initialState, action) => {
  switch (action.type) {
    case approvar_actionType.GET_REQUEST_DATA_SUCCESS:
      return {
        ...state,
        loading: true
      };
    case approvar_actionType.SET_REQUEST_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        trainingCompletionDate: action.payload?.vam_request?.trainingCompletionDate
      };
    case approvar_actionType.SET_REQUEST_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case approvar_actionType.HUB_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        allRoles: action.payload
      };
    case approvar_actionType.HUB_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allProducts: action.payload
      };
    case approvar_actionType.SELECTED_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedProducts: action.payload
      };
    case approvar_actionType.SELECTED_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedRoles: action.payload
      };
    case approvar_actionType.HUB_ROLES_AND_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        hubDataError: action.payload
      };
    case approvar_actionType.UPDATE_APPROVAR_DATA_REQUEST:
      return {
        ...state,
        isUpdating: true,
        isUpdated: false
      };
    case approvar_actionType.UPDATE_APPROVAR_DATA_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        isUpdated: true
      };


    case approvar_actionType.RESET_LOADER:
      return {
        ...state, loading: false
      };
    case approvar_actionType.SET_LOADER:
      return {
        ...state,
        loading: true
      };
    case approvar_actionType.SET_TRAINGINGS:
      return {
        ...state,
        trainings: action.payload
      };
    case (approvar_actionType.SET_ACTION_BUTTON_FLAGS):
      return {
        ...state, actionButtonState: action.payload
      };


    case approvar_actionType.EDIT_OR_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
        isEditOrUpdateFailed: true,
        loading: false

      };
    case approvar_actionType.RESET_ERROR_MODEL:
      return {
        ...state,
        isEditOrUpdateFailed: false
      };
    case approvar_actionType.SET_HUB_DATA:
      return {
        ...state,
        hubsData: action.payload
      };
    case approvar_actionType.UPDATE_TRAINING_COMPLETION_DATE_SUCCESS:
      return {
        ...state,
        trainingCompletionDate: action.payload,
        trainingCompletionDateMsg: action.msg,
        isTrainingDateRequestCompleted: true,
        isTrainingUpdateSuccess: true
      };
    case approvar_actionType.UPDATE_TRAINING_COMPLETION_DATE_FAILURE:
      return {
        ...state,
        trainingCompletionDateMsg: action.error,
        isTrainingDateRequestCompleted: true,
        isTrainingUpdateSuccess: false
      };
    case approvar_actionType.RESET_TRAINING_COMPLETION:
      return {
        ...state,
        trainingCompletionDateMsg: '',
        isTrainingDateRequestCompleted: false,
        isTrainingUpdateSuccess: false
      };
    default:
      return state;
  }
};

export default ApprovarReducer;
