import { userDetail_actionType } from '../../actionTypes/userAction-actionTypes/userDetail-actionType';

export function resetUserDetailReducer() {
    return(dispatch) => {
        dispatch({
            type: userDetail_actionType.RESET_USER_DETAIL_SUCCESS
        });
    };
}

export function setUserDetail(userDetailObject) {
    return(dispatch) => {
        if(userDetailObject){
            dispatch({
                type: userDetail_actionType.SET_USER_DETAIL_SUCCESS,
                payload: userDetailObject
            });
        }else{
            dispatch({
                type : userDetail_actionType.SET_USER_DETAIL_FAILURE
            });
        }
    };
}