import { group_actionType } from "../../actionTypes/adminAction-actionTypes/group-actionType";

const initialState = {
  groupData: [],
  error: undefined,
  loading: true,
  isRequestCompleted: false,
  isRequestSuccess: false,
  message: []
};

const GroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case group_actionType.GET_GROUP_SUCCESS:
      return {
        ...state,
        groupData: action.data,
        loading: false
      };
    case group_actionType.GET_GROUP_FAILURE:
      return {
        ...state,
        error: action.data,
        loading: false
      };
    case group_actionType.EDIT_REQUEST_START:
      return {
        ...state,
        isRequestCompleted: false,
        isRequestSuccess: false,
        message: []
      };
    case group_actionType.EDIT_REQUEST_SUCCESS:
      return {
        ...state,
        isRequestCompleted: true,
        isRequestSuccess: true,
        message: [action?.data]
      };
    case group_actionType.EDIT_REQUEST_FAILURE:
      return {
        ...state,
        isRequestCompleted: true,
        isRequestSuccess: false,
        message: [[action?.data]]
        // message: ["error"]
      };
    case group_actionType.SET_LOADER:
      return {
        ...state,
        loading: true
      };
    case group_actionType.RESET_LOADER:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default GroupReducer;
