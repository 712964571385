import { createContext, useContext } from "react";
import msalAuthProvider from '../../lib/utils/auth';

const authContext = createContext();

const authContextProvider = ({children}) => {
    return (
        <authContext.Provider value={msalAuthProvider} >
            {children}
        </authContext.Provider>
    );
};

const useAuth = ()=> useContext(authContext);

export {authContextProvider, authContext, useAuth};
