import React, { useState } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Button, Chip, Stack } from "@mui/material";
import { reassignAndTerminateRequestList_actionType } from "../../../../../../../redux/actionTypes/adminAction-actionTypes/reassignAndTerminate/reassignAndTerminateRequestList-actionType";
import { useDispatch, useSelector } from "react-redux";

const ReassignAndTerminateFilterModalNotice = ({ setIsModalOpen }) => {
  const dispatch = useDispatch();
  const { filterData } = useSelector((state) => ({
    filterData:
      state.ReassignAndTerminateReducer.ReassignAndTerminateRequestListReducer
        ?.filterData
  }));
  const [data, setData] = useState(filterData);

  // useEffect(() => {
  //   const localDataAvailable = JSON.stringify(filterOptions);
  //   setData(JSON.parse(localDataAvailable));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isModalOpen]);

  const clearFilterHandler = () => {
    let tempData = [...data];
    tempData.forEach((primaryDataObject) => {
      primaryDataObject.values.forEach(
        (secondaryDataObject) => (secondaryDataObject.selected = false)
      );
    });
    setData(tempData);
    dispatch({
      type: reassignAndTerminateRequestList_actionType.SET_REASSIGN_AND_TERMINATE_FILTER_DATA,
      data: tempData
    });
  };

  const optionHandler = (primaryIndex, secondaryIndex) => {
    let tempData = [...data];
    tempData[primaryIndex].values[secondaryIndex].selected =
      !tempData[primaryIndex].values[secondaryIndex].selected;
    setData(tempData);
    dispatch({
      type: reassignAndTerminateRequestList_actionType.SET_REASSIGN_AND_TERMINATE_FILTER_DATA,
      data: tempData
    });
  };

  const applyFilterHandler = () => {
    // setFilterOptions(data);
    setIsModalOpen(false);
  };

  return (
    <div className="reassignAndTerminate_modalCard">
      <div className="reassignAndTerminate_modalCardHeader">
        <span>Search Filter</span>
        <span>
          <CancelOutlinedIcon
            sx={{ fontSize: "1.5rem", cursor: "pointer" }}
            onClick={() => setIsModalOpen(false)}
          />
        </span>
      </div>
      <div className="reassignAndTerminate_modalCardBody">
        <div className="reassignAndTerminate_Type">
          {data.map((primaryDataObject, primaryIndex) => {
            return (
              <>
                <span className="reassignAndTerminate_TypeHeading">
                  {primaryDataObject.name}
                </span>
                <Stack
                  direction="row"
                  sx={{
                    flexWrap: "wrap",
                    maxHeight: "100px",
                    overflowY: "scroll"
                  }}
                >
                  {primaryDataObject.values?.map(
                    (secondaryDataObject, secondaryIndex) => (
                      <Chip
                        key={secondaryIndex}
                        label={secondaryDataObject.name}
                        variant="outlined"
                        onClick={() =>
                          optionHandler(primaryIndex, secondaryIndex)
                        }
                        className={`reassignAndTerminate_TypeChip ${
                          data[primaryIndex].values[secondaryIndex].selected
                            ? "reassignAndTerminate_TypeChipSelected"
                            : ""
                        }`}
                      ></Chip>
                    )
                  )}
                </Stack>
              </>
            );
          })}
        </div>
      </div>
      <div className="reassignAndTerminate_modalCardFooter">
        <Button
          variant="outlined"
          color="error"
          className="modalBtnClear"
          onClick={clearFilterHandler}
        >
          Clear
        </Button>
        <Button
          variant="outlined"
          color="success"
          className="modalBtnApply"
          onClick={applyFilterHandler}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default ReassignAndTerminateFilterModalNotice;
