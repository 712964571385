import React from "react";
import { useSelector } from "react-redux";

function VAMUserDetail() {
  const { UserDetail } = useSelector((state) => ({
    UserDetail: state.ViewAdminDashboardReducer.UserDetail
  }));
  return (
    <div className="viewAdminCardContainer">
      <div>
        <div className="addNewProductContainerCard">
          <div className="adminAddProductCardHeader">
            <span className="cardHeaderText">User Details</span>
          </div>
          <div className="adminViewDashboardCardForm">
            <div className="adminViewDashboardCardFormColumnFirst">
              <div className="adminViewDashboardCardFormContent">
                <span className="adminViewDashboardCardFormTitle">
                  Veeva Username
                </span>
                <span className="adminViewDashboardCardFormValue">
                  {UserDetail.veeva_username}
                </span>
              </div>
              <div className="adminViewDashboardCardFormContent">
                <span className="adminViewDashboardCardFormTitle">
                  User Type
                </span>
                <span className="adminViewDashboardCardFormValue">
                  {UserDetail.userType}
                </span>
              </div>
            </div>
            <div className="verticalDivider"></div>
            <div className="adminViewDashboardCardFormColumnSecond">
              <div className="adminViewDashboardCardFormContent">
                <span className="adminViewDashboardCardFormTitle">
                  First Name
                </span>
                <span className="adminViewDashboardCardFormValue">
                  {UserDetail.firstName}
                </span>
              </div>
              <div className="adminViewDashboardCardFormContent">
                <span className="adminViewDashboardCardFormTitle">Locale</span>
                <span className="adminViewDashboardCardFormValue">
                  {UserDetail.locale}
                </span>
              </div>
            </div>
            <div className="verticalDivider"></div>
            <div className="adminViewDashboardCardFormColumnThird">
              <div className="adminViewDashboardCardFormContent">
                <span className="adminViewDashboardCardFormTitle">
                  Last Name
                </span>
                <span className="adminViewDashboardCardFormValue">
                  {UserDetail.lastName}
                </span>
              </div>
              <div className="adminViewDashboardCardFormContent">
                <span className="adminViewDashboardCardFormTitle">
                  Timezone
                </span>
                <span className="adminViewDashboardCardFormValue">
                  {UserDetail.timezone}
                </span>
              </div>
            </div>
            <div className="verticalDivider"></div>
            <div className="adminViewDashboardCardFormColumnFourth">
              <div className="adminViewDashboardCardFormContent">
                <span className="adminViewDashboardCardFormTitle">
                  Email ID
                </span>
                <span className="adminViewDashboardCardFormValue">
                  {UserDetail.emailId}
                </span>
              </div>
            </div>
            <div className="verticalDivider"></div>
            <div className="adminViewDashboardCardFormColumnFifth">
              <div className="adminViewDashboardCardFormContent">
                <span className="adminViewDashboardCardFormTitle">
                  Language
                </span>
                <span className="adminViewDashboardCardFormValue">
                  {UserDetail.language}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VAMUserDetail;
