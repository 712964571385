import React, { useState, useEffect, useMemo } from "react";
import { Card, Grid } from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Modal from "@mui/material/Modal";
import "./styles/AccountSummary.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import {
  setCommentsToReducer,
  setTrainingAvailableToReducer,
  setTrainingCompletitionToReducer
} from "../../redux/actions/userAccount-actions/summary-action";
import { resetExistingStateToReducer } from "../../redux/actions/userAccount-actions/summary-action";
import { setUserDetail } from "../../redux/actions/userAccount-actions/userDetail-action";
import { setSelectedWorkFlowRoles } from "../../redux/actions/userAccount-actions/workFlowRoles-action";
import SummaryModal from "./summaryModal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import moment from "moment";

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "600px",
  minHeight: "200px",
  borderRadius: "10px",
  backgroundColor: "white",
  padding: "0px 2px 10px 0px"
};

const AccountSummary = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const { pageTitle, toggleToLandingPage } = props;
  const dispatch = useDispatch();

  const [commentsVal, setComments] = useState("");
  const [selectedDate, setDateSelected] = useState(null);
  const [dateError, setDateError] = useState(null);
  const [summaryData, setSummaryData] = useState({
    trainings: []
  });

  const dateErrorMessage = useMemo(() => {
    switch (dateError) {
      case "disableFuture":
        return "Please select/enter valid completion date.";
      case "invalidDate":
        return "Please select/enter valid date.";
      default:
        return "";
    }
  }, [dateError]);

  const editComponent = (type) => {
    handleOpen(true);
    setActiveModal(type);
  };

  const handleOpen = () => {
    setIsModalOpen(true);
  };
  const handleClose = () => {
    if (activeModal === "userDetails") {
      dispatch(setUserDetail(JSON.parse(existingState)));
    } else if (activeModal === "selectedRolesAndGroups") {
      dispatch(setSelectedWorkFlowRoles(JSON.parse(existingState)));
      let parsedState = JSON.parse(existingState);
      dispatch(setSelectedWorkFlowRoles(parsedState.selectedRolesAndGroups));
    }
    dispatch(resetExistingStateToReducer());
    setIsModalOpen(false);
  };

  const handelSave = () => {
    dispatch(resetExistingStateToReducer());
    setIsModalOpen(false);
  };

  // console.log("selectedDate**", selectedDate === null ? null : moment(new Date(selectedDate)).format("YYYY-MM-DD"));

  const trainingsRows = summaryData.trainings.map(function (columnValObj) {
    return (
      <tr key={columnValObj?.id}>
        <td width="30%" className="accountSummary_tableHeading">
          {columnValObj?.id}
        </td>
        <td width="70%">{columnValObj?.trainingName}</td>
      </tr>
    );
  });

  /* User Details Section Props*/
  const { userDetails } = useSelector((state) => {
    return {
      userDetails: state.UserDetailReducer?.data
    };
  });

  /* Trainings */
  const {
    // trainings,
    commentsReducer,
    existingState,
    trainingAvailable
  } = useSelector((state) => {
    return {
      // trainings: state.SummaryReducer.trainings,
      commentsReducer: state.SummaryReducer?.comments,
      existingState: state.SummaryReducer?.existingState,
      trainingAvailable: state.SummaryReducer?.trainingAvailable
    };
  });

  useEffect(() => {
    setComments(commentsReducer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Selected Affiliate Section Props*/
  const { selectAffiliate } = useSelector((state) => {
    return {
      selectAffiliate: state.SelectAffiliateReducer?.data
    };
  });

  /* Workflow Section Props */
  const { workFlowType, selectedRoles, trainings } = useSelector((state) => {
    return {
      selectedRoles: state.WorkFlowRolesReducer?.selectedRoles,
      workFlowType: state.WorkFlowRolesReducer?.data.workFlowType,
      trainings: state.WorkFlowRolesReducer?.trainings
    };
  });

  useEffect(() => {
    if (selectedDate && !dateErrorMessage)
      dispatch(
        setTrainingCompletitionToReducer(
          moment(new Date(selectedDate)).format("YYYY-MM-DD")
        )
      );
    else dispatch(setTrainingCompletitionToReducer(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, dateErrorMessage]);

  useEffect(() => {
    // if (trainings?.length > 0) {
    let rolesTraingings = trainings
      .filter((o) => o.workFlowType === workFlowType)
      .reduce((unique, o) => {
        if (!unique.some((obj) => obj.id === o.id)) {
          unique.push(o);
        }
        return unique;
      }, []);
    // if (C0103873 and C0103872 both trainings exist then remove C0103872 from trainings list)
    const isBothTrainingsPresent =
      rolesTraingings.filter(
        (trObj) => trObj.id === "C0103873" || trObj.id === "C0103872"
      ).length == 2;
    console.log(isBothTrainingsPresent);
    if (isBothTrainingsPresent) {
      // rolesTraingings;
      rolesTraingings = rolesTraingings.filter(
        (trObj) => trObj.id !== "C0103872"
      );
    }

    if (rolesTraingings.length) {
      dispatch(setTrainingAvailableToReducer(true));
    } else {
      setDateSelected(null);
      dispatch(setTrainingAvailableToReducer(false));
      dispatch(setTrainingCompletitionToReducer(null));
    }
    setSummaryData({ trainings: rolesTraingings });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workFlowType, selectedRoles, trainings]);

  const renderModalComponent = (activeModal) => {
    const summaryModalProps = {
      activeModal,
      pageTitle,
      toggleToLandingPage
    };
    return <SummaryModal {...summaryModalProps} />;
  };

  return (
    <div>
      <Grid container spacing={2} className="accountSumary_parentSection">
        {/* User Details Section */}
        <Grid item sm={12} md={12} className="accountSummary_mainItemSection">
          <Card className="accountSummary_userDetailsParent">
            <div className="accountSummary_header">
              <Grid container spacing={2}>
                <Grid item md={11} className="accountSummary_mainHeading">
                  User Details
                </Grid>
                <Grid
                  item
                  md={1}
                  className="accountSummary_mainHeading accountSummary_affiliateIconStyle"
                >
                  {userDetails.actionType === "CREATE" ? (
                    <EditOutlinedIcon
                      className="accountSummary_editIcon"
                      onClick={() => editComponent("userDetails")}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </div>
            <div className="accountSummary_section userDetailsSection">
              <Grid container spacing={2} className="userDetailsContainer">
                <Grid item md={1.5}>
                  <div>
                    <strong>First Name</strong>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div>{userDetails.firstName}</div>
                </Grid>
                <Grid item md={1}>
                  <div>
                    <strong>Last Name</strong>
                  </div>
                </Grid>
                <Grid item md={2}>
                  <div>{userDetails.lastName}</div>
                </Grid>
                <Grid item md={1}>
                  <div>
                    <strong>Email Id</strong>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div>{userDetails.emailId}</div>
                </Grid>
                <Grid item md={1.5}>
                  <div>
                    <strong>Veeva Username</strong>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div>{userDetails.veevaUserName}</div>
                </Grid>
                <Grid item md={1}>
                  <div>
                    <strong>User Type</strong>
                  </div>
                </Grid>
                <Grid item md={2}>
                  <div>{userDetails.userType}</div>
                </Grid>
                <Grid item md={1}>
                  <div>
                    <strong>Language</strong>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div>{userDetails.languageName}</div>
                </Grid>
                <Grid item md={1.5}>
                  <div>
                    <strong>Timezone</strong>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div>{userDetails.timezone}</div>
                </Grid>
                <Grid item md={1}>
                  <div>
                    <strong>Locale</strong>
                  </div>
                </Grid>
                <Grid item md={3}>
                  <div>{userDetails.localeName}</div>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
        {/* Affiliate Section */}
        <Grid
          item
          sm={12}
          md={12}
          style={{ height: "62px" }}
          className="accountSummary_mainItemSection"
        >
          <Card className="accountSummary_affiliatesParent">
            <div className="accountSummary_header">
              <Grid container spacing={2}>
                <Grid item md={2} className="accountSummary_mainHeading">
                  Selected Affiliate
                </Grid>
                <Grid item md={9} className="accountSummary_headingNoteText">
                  <span className="accountSummary_headingSubNoteText">
                    <em>
                      {selectAffiliate?.selectedAffiliateType?.toUpperCase()}{" "}
                    </em>
                  </span>
                </Grid>
              </Grid>
            </div>
          </Card>
        </Grid>
        {/* Trainings Section*/}
        {trainingAvailable && (
          <Grid
            item
            sm={12}
            md={6}
            className="accountSummary_mainItemSection"
            style={{ height: "190px" }}
          >
            <Card className="accountSummary_parent" style={{ height: "173px" }}>
              <div className="accountSummary_header">
                <Grid container spacing={2}>
                  <Grid item md={3} className="accountSummary_mainHeading">
                    Trainings
                  </Grid>
                  {/* <Grid item md={9} className="accountSummary_trainingsHeadingNoteText" style={{ flexBasis: "70%" }}>
                  <span className="accountSummary_note">
                    <em>Note:</em>
                  </span>{" "}
                  <span className="accountSummary_red"> ** </span>{" "}
                  <span>
                    <em>
                      Access will be provided only after completion of the
                      training.
                    </em>
                  </span>
                </Grid> */}
                </Grid>
              </div>
              <div className="" style={{ height: "107px", display: "block" }}>
                <table width="100%" className="accountSummary_trainingsTable">
                  <thead>
                    <tr align="left">
                      <th width="30%">Curriculum ID</th>
                      <th width="70%">Curriculum Name</th>
                    </tr>
                  </thead>
                  <tbody>{trainingsRows}</tbody>
                </table>
                {/* <div className="accountSummary_trainingsBottomNote">
                <em>(Note: For U.S. Employees seeking a U.S. VVMC account, please contact Kurt Clemenz to add or confirm the VVMC curricula is added to your Learning plan)</em>
              </div> */}
              </div>
            </Card>
          </Grid>
        )}

        {/* Training Completition Date Section*/}
        {trainingAvailable && (
          <Grid item sm={12} md={6} className="accountSummary_mainItemSection">
            <Card className="accountSummary_rolesTrainingParent">
              <div className="accountSummary_header">
                <Grid container spacing={2}>
                  <Grid item md={12} className="accountSummary_mainHeading">
                    Training Completion Date
                  </Grid>
                </Grid>
              </div>
              <div className="accountSummary_section">
                <Grid container style={{ height: "auto !important" }}>
                  <Grid
                    container
                    md={12}
                    className="accountSummary_trainingCompletitionWithPicker"
                  >
                    <Grid item md={6}>
                      <span className="accountSummary_trainingCompletionHeading">
                        Training Completion Date
                      </span>
                      <span className="accountSummary_red">*</span>
                    </Grid>
                    <Grid item md={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          disableFuture={true}
                          onError={(newErr) => setDateError(newErr)}
                          slotProps={{
                            textField: {
                              size: "small",
                              helperText: selectedDate ? dateErrorMessage : "",
                              error:
                                selectedDate && dateErrorMessage ? true : false
                            }
                          }}
                          value={dayjs(selectedDate)}
                          onChange={(newVal) => {
                            setDateSelected(newVal);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                  <Grid item md={12}>
                    <span className="accountSummary_trainingCompletitionInstruction">
                      **Mandatory training must be completed before submitting
                      access request.
                    </span>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </Grid>
        )}

        {/* Roles Section */}
        <Grid
          item
          sm={12}
          md={6}
          style={{ height: "190px" }}
          className="accountSummary_mainItemSection"
        >
          <Card className="accountSummary_rolesTrainingParent">
            <div className="accountSummary_header">
              <Grid container spacing={2}>
                <Grid item md={11} className="accountSummary_mainHeading">
                  Selected Roles
                </Grid>
                <Grid
                  item
                  md={1}
                  className="accountSummary_mainHeading accountSummary_affiliateIconStyle"
                >
                  <EditOutlinedIcon
                    className="accountSummary_editIcon"
                    onClick={() => editComponent("selectedRolesAndGroups")}
                  />
                </Grid>
              </Grid>
            </div>
            <div className="accountSummary_section">
              <table
                width="100%"
                style={{ height: "100px" }}
                className="accountSummary_rolesTable"
              >
                <thead></thead>
                <tbody>
                  <tr
                    align="left"
                    className="accountSummary_rolesRowsSize accountSummary_rolesRows"
                  >
                    <th width="30%">Affiliate</th>
                    <td width="70%">
                      {workFlowType?.charAt(0).toUpperCase() +
                        workFlowType?.slice(1)}
                    </td>
                  </tr>
                  <tr className="accountSummary_rolesRowSize" align="left">
                    <th width="30%">Roles</th>
                    <td width="70%">
                      {selectedRoles
                        ?.filter((rle) => rle.workFlowType === workFlowType)
                        .map((rle) => rle.role.name)
                        .join(" | ")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Card>
        </Grid>

        {/* Requestor comment section */}
        <Grid
          item
          sm={12}
          md={6}
          className="accountSummary_mainItemSection"
          style={{ height: "190px" }}
        >
          <div
            className="accountSummary_commentsParent"
            style={{ height: "173px" }}
          >
            <div className="accountSummary_commentHeader">
              <span className="accountSummary_mainHeading">
                Requestor Comments{" "}
                <em
                  style={{
                    float: "right",
                    marginRight: "10px",
                    fontSize: "12px"
                  }}
                >
                  (limit: {255 - commentsVal?.length})
                </em>
              </span>
            </div>
            <div className="accountSummary_section">
              <TextareaAutosize
                className="acountSummary_commentBox"
                aria-label="minimum height"
                maxRows={6}
                minRows={6}
                maxlength={255}
                placeholder="Add your comment here..."
                value={commentsVal}
                onChange={(event) => {
                  dispatch(setCommentsToReducer(event.target.value));
                  setComments(event.target.value);
                }}
              />
            </div>
          </div>
        </Grid>
      </Grid>

      <Modal
        className="summaryModalParent"
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={ModalStyle}>
          {renderModalComponent(activeModal)}
          <CancelOutlinedIcon
            className="accountSummary_crossModalIcon"
            onClick={() => handleClose()}
          />
          <div className="summaryModalButtons">
            <Button
              className="buttonDanger"
              variant="outlined"
              onClick={handleClose}
              color="error"
              sx={{
                width: 100,
                height: 35,
                fontSize: 12,
                ":hover": {
                  bgcolor: "red",
                  color: "white"
                }
              }}
            >
              Cancel
            </Button>
            <Button
              className="buttonSuccess"
              variant="outlined"
              onClick={handelSave}
              color="success"
              sx={{
                width: 100,
                height: 35,
                fontSize: 12,
                ":hover": {
                  bgcolor: "green",
                  color: "white"
                }
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AccountSummary;
