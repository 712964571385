import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { UserDetailsSection } from "../UserDetails/UserDetailsSection";
import { WorkFlow } from "../WorkFlow/WorkFlow";
import { setExistingStateToReducer } from '../../redux/actions/userAccount-actions/summary-action';
const SummaryModal = (props) => {
    const dispatch = useDispatch();
    const { activeModal, pageTitle, toggleToLandingPage} = props;
    const {
      isExistingDataSet,
      userDetails,
      allUserDetailResources,
      workFlowType,
      roles,
      selectedRoles,
      completeState
    } = useSelector((state) => ({
        userDetails: state.UserDetailReducer.data,
        allUserDetailResources: state.AllUserDetailResourcesReducer.data,
        workFlowType: state.WorkFlowRolesReducer.data.workFlowType,
        roles: state.WorkFlowRolesReducer.data.roles,
        selectedRoles: state.WorkFlowRolesReducer.selectedRoles,
        isExistingDataSet: state.SummaryReducer.isExistingDataSet,
        completeState: state
      }));

    const allAffiliatesResources = completeState.AffiliateResourcesReducer.data;
    const userAccessResources = completeState.UserAccessResourcesReducer.data;
    
    const userDetailsSectionProps = {
      userDetails,
      pageTitle,
      allUserDetailResources,
      toggleToLandingPage
    };

    const selectedRolesAndGroups = {
      workFlowType,
      roles,
      selectedRoles,
      allAffiliatesResources,
      userAccessResources
    };

    const count = 0;

    useEffect(() => {
        if(!isExistingDataSet) {
          if(activeModal === "userDetails") {
            const payload = {
              ...userDetails
            };
            dispatch(setExistingStateToReducer(payload));
          } else if(activeModal === "selectedRolesAndGroups") {
            dispatch(setExistingStateToReducer(selectedRoles));
          }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [count]);

    const renderComponent = (activeModal) => {
      switch (activeModal) {
        case "userDetails":
          return <UserDetailsSection {...userDetailsSectionProps} />;
  
        case "selectedRolesAndGroups":
          return <WorkFlow {...selectedRolesAndGroups} />;
  
        default:
          return null;
      }
    };

    const returnComponent = renderComponent(activeModal);
    return returnComponent;
};

export default SummaryModal;
