import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
// import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Button from "@mui/material/Button";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import {
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup
} from "@mui/material";
import "./styles/Trainings.css";
import { useDispatch } from "react-redux";
import { saveTrainingsRequest } from "../../../../../../redux/actions/adminAccount-actions/trainings-actions";
import { TextFieldInput } from "../../../../../../components/common/TextFieldInput";
// ../../components/common/TextFieldInput";

export default function TrainingsModal({ isModalOpen, toggleOpenTrainingsModal, rowData, isEditRequest }) {
    //    raw data format = {isCurriculam:true/false, trainingId, trainingName, id }
    const [isCurriculam, setIsCurriculum] = useState('no');
    const [trainingId, setTrainingId] = useState('');
    const [trainingName, setTrainingName] = useState('');
    const [trainingDescription, setTrainingDescription] = useState('');
    const dispatch = useDispatch();
    useEffect(() => {
        if (isEditRequest) {
            setIsCurriculum(rowData.isCurriculam ? 'yes' : 'no');
            setTrainingId(rowData.id);
            setTrainingName(rowData.trainingName);
            setTrainingDescription(rowData.description);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditRequest]);


    // key and values are subject to change according to API
    const onSaveHandler = () => {
        const data = [{
            "isCurriculam": isCurriculam === 'yes' ? true : false,
            "id": trainingId,
            "trainingName": trainingName,
            "description": trainingDescription
        }];
        dispatch(saveTrainingsRequest(data, isEditRequest, rowData?.id));
        handleClose();
    };

    const handleClose = () => {
        toggleOpenTrainingsModal();
        clearState();
    };

    const clearState = () => {
        setIsCurriculum('no');
        setTrainingId('');
        setTrainingName('');
        setTrainingDescription('');
    };

    const handleRadioButtonChange = (evt) => {
        setIsCurriculum(evt.target.value);
    };
    const isTrainingIdValid = trainingId.length > 0 ? (/^[a-zA-Z0-9]+$/.test(trainingId)) : true;
    return (
        <Modal
            open={isModalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="trainings_modal" style={{ outline: "none" }}>
                <div className="trainings_modalCard" >
                    <div className="trainings_modalCardHeader">
                        <span>{isEditRequest ? 'Edit Trainings' : 'Add Trainings'}</span>
                        <span>
                            <HighlightOffIcon
                                sx={{
                                    mr: "5px",
                                    "&:hover": {
                                        color: "gray",
                                        transition: "color 0.2s"
                                    }
                                }}
                                onClick={handleClose}
                            ></HighlightOffIcon>
                        </span>
                    </div>
                    <div className="trainings_modalCardBody" style={{ margin: '20px', overflow: 'clip' }}>
                        <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={6}
                            className="training_popup_grid"
                            direction={'column'}
                        >
                            <Grid item sm={12} md={6} className="training_popup_grid_item">
                                <TextFieldInput
                                    id="training-id"
                                    name="trainingId"
                                    label="Training ID"
                                    placeholder='Enter training ID'
                                    disabled={isEditRequest}
                                    value={trainingId}
                                    onChange={(e) => { setTrainingId(e.target.value); }}
                                    error={
                                        !isTrainingIdValid
                                    }
                                    helperText={
                                        !isTrainingIdValid ? 'Training ID should be alpha-numeric without Space.' : ''
                                        // trainingId.length && trainingId?.trim()?.includes(' ') ? 'Training ID should be alpha-numeric without space.' : ''
                                    }

                                />
                            </Grid>
                            <Grid item sm={12} md={6} className="training_popup_grid_item">
                                <TextFieldInput
                                    id="training-name"
                                    name="trainingName"
                                    label="Training Name"
                                    placeholder='Enter training Name'
                                    value={trainingName}
                                    onChange={(e) => { setTrainingName(e.target.value); }}

                                />
                            </Grid>
                            <Grid item sm={12} md={6} className="training_popup_grid_item">
                                <TextFieldInput
                                    id="training-description"
                                    name="trainingDescription"
                                    label="Training Description"
                                    isMultiple={true}
                                    rows={2}
                                    placeholder='Enter training Description'
                                    value={trainingDescription}
                                    onChange={(e) => { setTrainingDescription(e.target.value); }}

                                />

                            </Grid>
                            <Grid item sm={12} md={6} className="training_popup_grid_item">
                                <FormLabel id="radio-buttons-group-label"
                                    style={{
                                        fontSize: " 0.8rem",
                                        fontWeight: '900',
                                        color: 'black'
                                    }}>
                                    Is Curriculum
                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby="radio-buttons-group-label"
                                    value={isCurriculam}
                                    name="radio-buttons-group"
                                    onChange={handleRadioButtonChange}
                                    row

                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </Grid>
                        </Grid>
                    </div>
                    <div
                        className="homeContainerButtons"
                        style={{ marginBottom: '20px' }}
                    >
                        <Button
                            className="buttonDanger"
                            variant="outlined"
                            onClick={handleClose}
                            color="error"
                            sx={{
                                width: 100,
                                height: 35,
                                ":hover": {
                                    bgcolor: "red",
                                    color: "white"
                                }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="buttonSuccess"
                            variant="outlined"
                            onClick={onSaveHandler}
                            color="success"
                            disabled={!(trainingId.trim().length > 0 && trainingName.trim().length > 0 && isTrainingIdValid)}
                            sx={{
                                width: 100,
                                height: 35,
                                ":hover": {
                                    bgcolor: "green",
                                    color: "white"
                                }
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </Modal >

    );
}
