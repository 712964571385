import { viewAdminDashboard_actionType } from "../../actionTypes/adminAction-actionTypes/viewAdminDashboard-actionType";
import {
  setViewAdminDashboardLoaderActive,
  setViewAdminDashboardLoaderInActive
} from "../loaderAction";
import { axiosApigateway } from "../../../api";

const initialState = {
  RequestID: null,
  FullName: "",
  UserDetail: {
    veevaUsername: "",
    userType: "",
    firstName: "",
    locale: "",
    lastName: "",
    location: "",
    emailId: "",
    timezone: "",
    language: ""
  },
  SelectedRoles: {
    workflow: [],
    roles: []
  }
  //   SelectedProducts: []
};

const getRoles = (vam_roles) => {
  let data = [];
  if (vam_roles) {
    vam_roles.forEach((element) => {
      data.push(element.name);
    });
  }

  return data;
};

// const getSelectedProducts = (vvpm_products) => {
//     let data = [];
//     let productCategory = new Set();

//     if (vvpm_products) {
//         vvpm_products.forEach(element => {
//             productCategory.add(element.vamProductGroupId);
//         });
//         productCategory.forEach(element => {
//             data.push({
//                 categoryName: element,
//                 selectedProducts: []
//             });
//         });

//         vvpm_products.forEach(element => {
//             data.forEach(dataElement => {
//                 if (dataElement.categoryName === element.vamProductGroupId) {
//                     dataElement.selectedProducts.push({
//                         productName: element.product_name
//                     });
//                 }
//             });
//         });
//     }

//     return data;
// };

const getRequiredData = (responseStore, viewAdminRequestId) => {
  let data = initialState;
  if (responseStore) {
    data.RequestID = viewAdminRequestId;
    let requestObject = responseStore.data.filter((object) => {
      if (object.request_id === viewAdminRequestId) return true;
      return false;
    })[0];
    data.FullName = requestObject.request_payload?.display_name;
    data.requestModifiedDate = requestObject.updatedAt;
    data.requestCreateDate = requestObject.createdAt;
    data.requesterComment = requestObject.requester_comments;
    data.requestStatus = requestObject.request_status;
    data.UserDetail = {
      veevaUsername: requestObject.request_payload?.email,
      veeva_username: requestObject.request_payload?.veeva_username,
      userType: requestObject.request_payload?.user_type,
      firstName: requestObject.request_payload?.first_name,
      locale: requestObject.request_payload?.locale,
      lastName: requestObject.request_payload?.last_name,
      location: requestObject.request_payload?.location,
      emailId: requestObject.request_payload?.email,
      timezone: requestObject.request_payload?.timezone,
      language: requestObject.request_payload?.language
    };
    data.SelectedRoles.workflow = [
      requestObject.request_payload?.users_access_payload?.name
    ];
    // data.SelectedRoles.roles = getRoles(requestObject.request_payload?.users_access_payload?.vam_roles);
    // data.SelectedProducts = getSelectedProducts(requestObject.request_payload?.users_access_payload?.vvpm_products);
  }

  console.log("data", data);
  return data;
};

const getRequiredDataForRequestStatus = (dataStore, viewRequestDetail) => {
  let data = [];
  dataStore = dataStore.sort((a, b) => a.taskPriority - b.taskPriority);
  dataStore.forEach((element) => {
    let object = {};
    if (element) {
      if (!element.taskAssignedBy) {
        if (element.viewerADGroup)
          object.approverName = element.initialTaskAudience.split("@")[0];
        else object.approverName = element.taskAssignedTo;
      } else {
        object.approverName = element.taskAssignedTo;
      }
      object.cureentStatus = element.taskStatus;
      object.lastModifiedDate = element.updatedAt;
      object.comment = element.approverComments;
      object.taskId = element?.taskId;
      object.approvedOrRejectedBy = element?.taskAssignedTo
        ? element?.taskAssignedTo.split("@lists")[0]
        : element?.initialTaskAudience.split("@lists")[0];
    }
    data.push(object);
  });

  const getCurrentTaskStatus = (requestStatus) => {
    switch (requestStatus) {
      case "complete":
        return "approved";
      case "pending":
        return "pending";
      case "rejected":
        return "rejected";
      case "failed":
        return "rejected";
      case "approved":
        return "approved";
      case "terminated":
        return "rejected";
      default:
        return "pending";
    }
  };
  data.push({
    approverName: "Request Current Status",
    cureentStatus: getCurrentTaskStatus(viewRequestDetail.requestStatus),
    lastModifiedDate: viewRequestDetail.requestModifiedDate,
    comment: "Final Status - " + viewRequestDetail.requestStatus
  });
  return data;
};

export function setAdminDashboardAllRequests(
  responseStore,
  viewAdminRequestId
) {
  return async (dispatch) => {
    dispatch(setViewAdminDashboardLoaderActive());
    let requiredData = getRequiredData(responseStore, viewAdminRequestId);
    try {
      // calling API
      const response = await axiosApigateway.get(
        "/admin-vvmc-approval-tasks?vamRequestRequestId=" + viewAdminRequestId
      );

      if (response) {
        requiredData.SelectedRoles.roles = getRoles(
          response?.data?.data?.[0]?.vam_request?.request_payload
            ?.users_access_payload?.vam_roles
        );
        dispatch({
          type: viewAdminDashboard_actionType.GET_VIEW_DASHBOARD_SUCCESS,
          data: requiredData,
          requestStatus: getRequiredDataForRequestStatus(
            response.data.data,
            requiredData
          )
        });

        dispatch(setViewAdminDashboardLoaderInActive());
        return { response, success: true };
      }
    } catch (error) {
      dispatch(setViewAdminDashboardLoaderInActive());
      return { success: false };
    }
  };
}
