import React from 'react';
import { useSelector } from 'react-redux';

function AdminDashboardTableRecord() {
  const {
    active_users,
    inactive_users,
    total_requests,
    ageing_request
  } = useSelector((state) => ({
    active_users: state.AdminDashboardReducer.active_users,
    inactive_users: state.AdminDashboardReducer.inactive_users,
    total_requests: state.AdminDashboardReducer.total_requests,
    ageing_request: state.AdminDashboardReducer.ageing_request
  }));
  return (
    <div className='adminDashboardTableRecord'>
      <div className='adminDashboardTableRecordOne'>
        <div className='recordRequestType'>Total Requests</div>
        <div className='recordData'>
          <div className='recordDataNumber'>{total_requests.total_count}</div>
          <div className='recordDataDivider'></div>
          <div className='recordDataInfo'>
            <span className='recordDataInfoLineOne'>
              <span>Account Creation</span>
              <span>{total_requests.account_creations}</span>
            </span>
            <span className='recordDataInfoLineTwo'>
              <span>Account Modification</span>
              <span>{total_requests.account_modifications}</span>
            </span>
            {/* <span className='recordDataInfoLineTwo'>
              <span>Account Reactivation</span>
              <span>{total_requests.account_reactivate}</span>
            </span> */}
            <span className='recordDataInfoLineTwo'>
              <span>Account Deactivation</span>
              <span>{total_requests.account_deactivate}</span>
            </span>
          </div>
        </div>
      </div>
      <div className='adminDashboardTableRecordTwo'>
        <div className='recordRequestType'>Pending Requests</div>
        <div className='recordData'>
          <div className='recordDataNumber'>{ageing_request}</div>
        </div>
      </div>
      <div className='adminDashboardTableRecordThree'>
        <div className='recordRequestType'>Active Users</div>
        <div className='recordData'>
          <div className='recordDataNumber'>{active_users.total_count}</div>
          <div className='recordDataDivider'></div>
          <div className='recordDataInfo'>
            <span className='recordDataInfoLineOne'>
              <span>Lilly User</span>
              <span>{active_users.lilly_users}</span>
            </span>
            <span className='recordDataInfoLineTwo'>
              <span>Non-Lilly User</span>
              <span>{active_users.non_lilly_users}</span>
            </span>
          </div>
        </div>
      </div>
      <div className='adminDashboardTableRecordFour'>
        <div className='recordRequestType'>Inactive Users</div>
        <div className='recordData'>
          <div className='recordDataNumber'>{inactive_users.total_count}</div>
          <div className='recordDataDivider'></div>
          <div className='recordDataInfo'>
            <span className='recordDataInfoLineOne'>
              <span>Lilly User</span>
              <span>{inactive_users.lilly_users}</span>
            </span>
            <span className='recordDataInfoLineTwo'>
              <span>Non-Lilly User</span>
              <span>{inactive_users.non_lilly_users}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboardTableRecord;