export const adminPortalPageNavigation_actionType = {
    ADMIN_DASHBOARD: "Admin Dashboard",
    DASHBOARD: "Dashboard",
    VIEW: 'View',
    DATA_CONFIGURATION: "Data Configuration",
    COMPANY: "Company",
    AFFLIATE: "Affiliate",
    GROUPS: "Groups",
    ROLES: "Roles",
    TERMINATE: "Terminate",
    TRAINING: "Training"
};
