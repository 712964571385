import { axiosApigateway } from "../../../api";
import { userAccessResources_actionType } from "../../actionTypes/userAction-actionTypes/userAccessResources-actionType";

export function resetUserAccessResources() {
  return (dispatch) => {
    dispatch({
      type: userAccessResources_actionType.RESET_USER_ACCESS_RESOURCES
    });
  };
}

// const setTrainings = (hubsData, abbreviation) => {
//   let trainings = [];
//   const vam_roles = hubsData.find(o => o.abbreviation === abbreviation).vam_roles;//[0].vam_trainings
//   vam_roles.forEach(_role => {
//     const _trainings = _role.vam_trainings;
//     _trainings.forEach(_training => {
//       trainings.push(_training);
//     });
//   });
//   return trainings;
// };

function getAffType(affilateResponseData) {
  const affiliateTypeObj = {
    name: affilateResponseData?.name,
    abbreviation: affilateResponseData?.abbreviation
  };
  return affiliateTypeObj;
}

function getTrainingsForRole(trainingsResponseData) {
  let data = [];
  trainingsResponseData.forEach(trainingObj => {
    const trainingRoleObj = {
      id: trainingObj?.id,
      trainingName: trainingObj?.trainingName,
      description: trainingObj?.description,
      isCurriculam: trainingObj?.isCurriculam,
      isActive: trainingObj?.isActive
    };
    data.push(trainingRoleObj);
  });
  return data;
}

// function getRolesForParticularAff(rolesResponseData, userType) {
function getRolesForParticularAff(rolesResponseData, genericRolesResponse) {
  let data = [];
  // const actualRoles = rolesResponseData.filter(roleObj => roleObj.roleAudience !== null &&
  //   (roleObj.roleAudience === userType || roleObj.roleAudience === "All"));
  const allRoles = [...rolesResponseData, ...genericRolesResponse];
  allRoles.forEach((actualRoleObj) => {
    const rolesDataObj = {
      vam_role_id: actualRoleObj?.vam_role_id,
      name: actualRoleObj?.name,
      description: actualRoleObj?.description,
      license: actualRoleObj?.license,
      vamHubAbbreviation: actualRoleObj?.vamHubAbbreviation,
      isActive: actualRoleObj?.isActive,
      roleAudience: actualRoleObj?.roleAudience,
      isElevatedRole: actualRoleObj?.isElevatedRole,
      securityProfile: actualRoleObj?.securityProfile,
      securityPolicy: actualRoleObj?.securityPolicy,
      trainings: getTrainingsForRole(actualRoleObj?.vam_trainings)
    };
    data.push(rolesDataObj);
  });
  return data;
}

// function createFormattedData(hubsResponse, userType) {
function createFormattedData(hubsResponse) {
  let data = [];
  const [genericRoles] = hubsResponse.data.filter(hubDataObj => hubDataObj.abbreviation === null);
  // console.log(genericRoles);
  const filteredHubResponse = hubsResponse.data.filter(hubDataObj => hubDataObj.abbreviation !== null );
  filteredHubResponse.forEach((dataObj) => {
    let affiliateType;
    // if(dataObj?.abbreviation !== null){
      affiliateType = getAffType(dataObj);
    // }
    // const roles = getRolesForParticularAff(dataObj?.vam_roles, userType);
    const roles = getRolesForParticularAff(dataObj?.vam_roles, genericRoles?.vam_roles);
    const object = {
      affiliateType,
      roles
    };
    data.push(object);
  });
  return data;
}

export function userAccessResources(data) {
  return async (dispatch) => {
    dispatch({
      type: userAccessResources_actionType.USER_ACCESS_RESOURCES
    });

    try {
      // const { userId, userType } = data;
      const { userId } = data;
      const hubsResponse = await axiosApigateway.get(`/hubs`);
      const userAccessResponse = await axiosApigateway.get(`/vmmc-useraccess/${userId}`);

      if(hubsResponse && userAccessResponse){
        const affResources = createFormattedData(hubsResponse.data);
        // const hubData = responseHub.data.data;
        // const responseData = response.data;
        // const userAccessResources = responseData.data
        //   .map((item) => ({
        //     user: {
        //       userHubId: item.userHubId,
        //       vamHubAbbreviation: item.vamHubAbbreviation,
        //       vamUserUserId: item.vamUserUserId
        //     },
        //     roles: item.vam_roles
        //       .map((role) => {
        //         return {
        //           vam_role_id: role.vam_role_id,
        //           name: role.name,
        //           description: role.description,
        //           license: role.license,
        //           isRequireProducts: role.isRequireProducts,
        //           vamHubAbbreviation: role.vamHubAbbreviation,
        //           isActive: role.isActive,
        //           roleAudience: role.roleAudience,
        //           isElevatedRole: role.isElevatedRole,
        //           securityProfile: role.securityProfile,
        //           user_hub_roles_jn: {
        //             vamRoleVamRoleId: role.user_hub_roles_jn.vamRoleVamRoleId,
        //             vamusersVamhubsJunctionUserHubId:
        //               role.user_hub_roles_jn.vamusersVamhubsJunctionUserHubId
        //           }
        //           // trainings: setTrainings(hubData, role.vamHubAbbreviation)
        //         };
        //       })
        //   }));
        const userAccessRoles = userAccessResponse
                                .data
                                .data.vam_roles.map(usrRoleObj => usrRoleObj.vam_role_id);
        const userAccessResources = affResources
          .map((item) => ({
            user: {
              vamHubAbbreviation: item.affiliateType.abbreviation,
              vamUserUserId: userId
            },
            roles: item.roles.filter(roleObj => userAccessRoles.includes(roleObj.vam_role_id))
          }));
        // console.log(userAccessResources, "userAccessResources**");
        dispatch({
          type: userAccessResources_actionType.USER_ACCESS_RESOURCES_SUCCESS,
          payload: userAccessResources
        });
      }
     
    } catch (error) {
      dispatch({
        type: userAccessResources_actionType.USER_ACCESS_RESOURCES_ERROR,
        payload: error || "Something went wrong, Please try after sometime!"
      });
    }
  };
}


