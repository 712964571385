import { setLoaderActive, setLoaderInActive } from "../loaderAction";
import { submitUserAccountRequest_actionType } from "../../actionTypes/userAction-actionTypes/submitUserAccountRequest-actionType";
import { axiosApigateway } from "../../../api";

const fetchUserAccessPayLoad = (completeState) => {
    const userAccessPayLoad = {
        "abbreviation": completeState.SelectAffiliateReducer.data.selectedAffiliateTypeAbbrebiation,
        "name": completeState.SelectAffiliateReducer.data.selectedAffiliateType,
        "vam_roles": completeState.WorkFlowRolesReducer.selectedRoles.filter(Object => Object.workFlowType === completeState.WorkFlowRolesReducer.data.workFlowType).map((Object) => {
            return {
                "vam_role_id": Object.role.vam_role_id,
                "name": Object.role.name,
                "description": Object.role.description,
                "isActive": Object.role.isActive,
                "roleAudience": Object.role.roleAudience,
                "isElevatedRole": Object.role.isElevatedRole,
                "vamHubAbbreviation": Object.role.vamHubAbbreviation
            };
        })
    };

    return userAccessPayLoad;
};

const fetchRequiredDataForCreateScreen = (completeState) => {
    const payLoad = {
        "first_name": completeState.UserDetailReducer.data.firstName,
        "last_name": completeState.UserDetailReducer.data.lastName,
        "display_name": completeState.UserDetailReducer.data.firstName + " " + completeState.UserDetailReducer.data.lastName,
        "email": completeState.UserDetailReducer.data.emailId,
        "federated_id": completeState.UserDetailReducer.data.systemID,
        "globalId": completeState.UserDetailReducer.data.globalId,
        "veeva_username": completeState.UserDetailReducer.data.veevaUserName,
        "salesforce_username": completeState.UserDetailReducer.data.veevaUserName,
        "timezone": completeState.UserDetailReducer.data.timezone,
        "language": completeState.UserDetailReducer.data.language,
        "locale": completeState.UserDetailReducer.data.locale,
        "user_type": completeState.UserDetailReducer.data.userType,
        "users_access_payload": fetchUserAccessPayLoad(completeState)
    };

    return payLoad;
};

const fetchRequiredDataForModifyScreen = (completeState) => {
    const payLoad = {
        "user_id": completeState.UserDetailReducer.data.id,
        "users_access_payload": fetchUserAccessPayLoad(completeState)
    };

    return payLoad;
};

const fetchRequiredDataForDeactivateScreen = (completeState) => {
    const payLoad = {
        "user_id": completeState.UserDetailReducer.data.id
    };

    return payLoad;
};

const fetchRequestPayLoad = (completeState, requestType) => {
    switch (requestType) {
        case "create":
            return fetchRequiredDataForCreateScreen(completeState);
        case "modify":
            return fetchRequiredDataForModifyScreen(completeState);
        case "deactivate":
            return fetchRequiredDataForDeactivateScreen(completeState);
    }
};

const fetchRequiredData = (completeState, requestType) => {
    const requiredData = {
        "requested_for": completeState.UserDetailReducer.data.emailId,
        "requested_by": completeState.LoggedUserReducer.loggedUser.username,
        "requested_for_name": completeState.UserDetailReducer.data.firstName + " " + completeState.UserDetailReducer.data.lastName,
        "requested_by_name": completeState.LoggedUserReducer.loggedUser.name.split("-")[0].trim(),
        "requestType": requestType,
        "requester_comments": completeState.SummaryReducer.comments,
        "request_payload": fetchRequestPayLoad(completeState, requestType),
        "trainingCompletionDate": completeState.SummaryReducer.trainingCompletionDate
    };

    return requiredData;
};

export function submitUserAccountRequest(completeState, requestType) {
    return async (dispatch) => {
        dispatch(setLoaderActive());

        try {
            const requiredData = fetchRequiredData(completeState, requestType);

            // calling API
            const response = await axiosApigateway.post('/requests', requiredData);
            if (response) {
                dispatch({
                    type: submitUserAccountRequest_actionType.SUBMIT_ACTION_SUCCESS,
                    data: response.data.data.request_id,
                    requestType: requestType
                });

                setTimeout(() => {
                    dispatch(setLoaderInActive());
                }, 3000);
                return { response, success: true };

            }
        } catch (error) {
            dispatch({
                type: submitUserAccountRequest_actionType.SUBMIT_ACTION_FAILURE,
                data: error
            });
            setTimeout(() => {
                dispatch(setLoaderInActive());
            }, 3000);
        }
    };
}
