import { axiosApigateway } from "../../../api";
import { affiliateResources_actionType } from "../../actionTypes/userAction-actionTypes/affiliateResources-actionType";

export function resetAffiliateResources() {
  return (dispatch) => {
    dispatch({
      type: affiliateResources_actionType.RESET_ALL_AFFILIATE_RESOURCES
    });
  };
}

function getAffType(affilateResponseData) {
  const affiliateTypeObj = {
    name: affilateResponseData?.name,
    abbreviation: affilateResponseData?.abbreviation
  };
  return affiliateTypeObj;
}

function getTrainingsForRole(trainingsResponseData) {
  let data = [];
  trainingsResponseData.forEach(trainingObj => {
    const trainingRoleObj = {
      id: trainingObj?.id,
      trainingName: trainingObj?.trainingName,
      description: trainingObj?.description,
      isCurriculam: trainingObj?.isCurriculam,
      isActive: trainingObj?.isActive
    };
    data.push(trainingRoleObj);
  });
  return data;
}

// function getRolesForParticularAff(rolesResponseData, userType) {
function getRolesForParticularAff(rolesResponseData, genericRolesResponse) {
  let data = [];
  // const actualRoles = rolesResponseData.filter(roleObj => roleObj.roleAudience !== null &&
  //   (roleObj.roleAudience === userType || roleObj.roleAudience === "All"));
  const allRoles = [...rolesResponseData, ...genericRolesResponse];
  allRoles.forEach((actualRoleObj) => {
    const rolesDataObj = {
      vam_role_id: actualRoleObj?.vam_role_id,
      name: actualRoleObj?.name,
      description: actualRoleObj?.description,
      license: actualRoleObj?.license,
      vamHubAbbreviation: actualRoleObj?.vamHubAbbreviation,
      isActive: actualRoleObj?.isActive,
      roleAudience: actualRoleObj?.roleAudience,
      isElevatedRole: actualRoleObj?.isElevatedRole,
      securityProfile: actualRoleObj?.securityProfile,
      securityPolicy: actualRoleObj?.securityPolicy,
      trainings: getTrainingsForRole(actualRoleObj?.vam_trainings)
    };
    data.push(rolesDataObj);
  });
  return data;
}

// function createFormattedData(hubsResponse, userType) {
function createFormattedData(hubsResponse) {
  let data = [];
  const [genericRoles] = hubsResponse.data.filter(hubDataObj => hubDataObj.abbreviation === null);
  // console.log(genericRoles);
  const filteredHubResponse = hubsResponse.data.filter(hubDataObj => hubDataObj.abbreviation !== null );
  filteredHubResponse.forEach((dataObj) => {
    let affiliateType;
    // if(dataObj?.abbreviation !== null){
      affiliateType = getAffType(dataObj);
    // }
    // const roles = getRolesForParticularAff(dataObj?.vam_roles, userType);
    const roles = getRolesForParticularAff(dataObj?.vam_roles, genericRoles?.vam_roles);
    const object = {
      affiliateType,
      roles
    };
    data.push(object);
  });
  return data;
}

export function affiliateResources() {
  return async (dispatch) => {
    dispatch({
      type: affiliateResources_actionType.ALL_AFFILIATE_RESOURCES
    });

    try {
      // const userType = data.userType;
      const response = await axiosApigateway.get("/hubs");
      if (response) {
        // const affResources = createFormattedData(response.data, userType);
        const affResources = createFormattedData(response.data);
        // console.log(affResources, "affre**");
        dispatch({
          type: affiliateResources_actionType.ALL_AFFILIATE_RESOURCES_SUCCESS,
          payload: affResources
        });
      }
    } catch (error) {
      dispatch({
        type: affiliateResources_actionType.ALL_AFFILIATE_RESOURCES_ERROR,
        payload: error || "Something went wrong, Please try after sometime!"
      });
    }
  };
}
