import { trainings_actionType } from "../../actionTypes/adminAction-actionTypes/trainings-actionType";

const initialState = {
    trainingsData: [],
    error: undefined,
    isRequestCompleted: false,
    isRequestSuccess: false,
    isLoader: true,
    message: []
};

const TrainingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case trainings_actionType.GET_TRAININGS_START:
            return {
                ...state,
                isRequestCompleted: false,
                isRequestSuccess: false,
                isLoader: true
            };
        case trainings_actionType.GET_TRAININGS_SUCCESS:
            return {
                ...state,
                trainingsData: action.data,
                isLoader: false
            };
        case trainings_actionType.GET_TRAININGS_FAILURE:
            return {
                ...state,
                message: action.data,
                isRequestCompleted: true,
                isRequestSuccess: false,
                isLoader: false
            };
        case trainings_actionType.EDIT_REQUEST_START:
            return {
                ...state,
                isRequestCompleted: false,
                isRequestSuccess: false,
                message: '',
                isLoader: true

            };

        case trainings_actionType.EDIT_TRAININGS_REQUEST_SUCCESS:
            return {
                ...state,
                isRequestCompleted: true,
                isRequestSuccess: true,
                message: action?.data,
                isLoader: false
            };
        case trainings_actionType.EDIT_TRAININGS_REQUEST_FAILURE:
            return {
                ...state,
                isLoader: false,
                isRequestCompleted: true,
                isRequestSuccess: false,
                message: action?.data
            };
        default:
            return state;
    }
};

export default TrainingsReducer;