import { axiosApigateway } from "../../../api";
import { allUserDetailResources_actionType } from "../../actionTypes/userAction-actionTypes/allUserDetailResources-actionType";


export function resetAllUserDetailResources() {
  return (dispatch) => {
    dispatch({
      type: allUserDetailResources_actionType.RESET_ALL_USER_DETAIL_RESOURCES
    });
  };
}

function getAllTimezones(timezoneResponse) {
  let data = [];
  timezoneResponse?.forEach((timezoneObj) => {
    const timezoneDataObj = {
      name: timezoneObj?.name,
      label: timezoneObj?.label,
      isTZActive: timezoneObj?.isTZActive
    };
    data.push(timezoneDataObj);
  });
  return data;
}

function getAllLanguages(languageResponse) {
  let data = [];
  languageResponse?.forEach((languageObj) => {
    const languageDataObj = {
      abbreviation: languageObj?.abbreviation,
      name: languageObj?.name,
      isLanguageActive: languageObj?.isLanguageActive,
      description: languageObj?.description,
      vvmclocales: getAllLocaleForParticularLang(languageObj?.vvmclocales)
    };
    data.push(languageDataObj);
  });
  return data;
}

function getAllLocaleForParticularLang(localeResponse) {
  let data = [];
  localeResponse?.forEach((localeObj) => {
    const localeDataObj = {
      code: localeObj?.code,
      name: localeObj?.name,
      isLocaleActive: localeObj?.isLocaleActive,
      description: localeObj?.description,
      language: localeObj?.language
    };
    data.push(localeDataObj);
  });
  return data;
}

export function createFormattedData(response) {
  let data = [];
  const dataObj = response?.data;
  const timezones = getAllTimezones(dataObj?.timezones);
  const languages = getAllLanguages(dataObj?.languages);
  const object = {
    timezones,
    languages
  };
  data.push(object);
  return data;
}

export function allUserDetailResources() {
  return async (dispatch) => {
    dispatch({
      type: allUserDetailResources_actionType.ALL_USER_DETAIL_RESOURCES
    });
    try {
      const response = await axiosApigateway.get("/vvmc-vam-configuration");
      if (response) {
        const allUserDetailResources = createFormattedData(response.data);
        dispatch({
          type: allUserDetailResources_actionType.ALL_USER_DETAIL_RESOURCES_SUCCESS,
          payload: allUserDetailResources
        });
      }

    } catch (error) {
      dispatch({
        type: allUserDetailResources_actionType.ALL_USER_DETAIL_RESOURCES_ERROR,
        payload: error || "Something went wrong, Please try after sometime!"
      });
    }
  };
}
