import axios from "axios";
import auth from "../lib/utils/auth";
import currentEnvVars from '../lib/utils/getEnvVars';


const axiosApigateway = axios.create({
  baseURL: currentEnvVars.apiGatewayUrl
});

axiosApigateway.interceptors.request.use(async function (requestConf) {
  const { accessToken } = await auth.getGatewayAccessToken();
  // const { accessToken: accessTokenMS  } = await auth.getAccessToken();
  requestConf.headers.Authorization = `Bearer ${accessToken}`;
  // requestConf.headers.MSAuthorization = `Bearer ${accessTokenMS}`;
  return requestConf;
}, function (error) {
  return Promise.reject(error);
});


const axiosMsGraph = axios.create({
  baseURL: "https://graph.microsoft.com/v1.0"
});

axiosMsGraph.interceptors.request.use(async function (requestConf) {
  const { accessToken } = await auth.getAccessToken();
  requestConf.headers.Authorization = `Bearer ${accessToken}`;
  requestConf.headers.ConsistencyLevel = "eventual";
  return requestConf;
}, function (error) {
  return Promise.reject(error);
});

const axiosMsGraphBeta = axios.create({
  baseURL: "https://graph.microsoft.com/beta"
});

axiosMsGraphBeta.interceptors.request.use(async function (requestConf) {
  const { accessToken } = await auth.getAccessToken();
  requestConf.headers.Authorization = `Bearer ${accessToken}`;
  requestConf.headers.ConsistencyLevel = "eventual";
  return requestConf;
}, function (error) {
  return Promise.reject(error);
});

export { axiosApigateway, axiosMsGraph, axiosMsGraphBeta };