import { useEffect } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  ClickAwayListener,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Tooltip,
  Modal
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import React, { useRef, useState } from "react";
import Home from "../Home";
import "./styles/searchUser.css";
import { resetUserAccountReducer } from "../../redux/actions/userAccount-actions/resetUserAccount-action";
import { useDispatch, useSelector } from "react-redux";
import { searchUserResources } from "../../redux/actions/userAccount-actions/searchUserResources-action";

const SearchUser = () => {
  const dispatch = useDispatch();
  const { loading, data, error } = useSelector((state) => {
    return state.SearchUserResourcesReducer;
  });
  const adminAllRoles = ["superAdmin", "admin"];
  const { userRole } = useSelector((state) => {
    return {
      userRole: state.CheckUserRoleReducer.role
    };
  });
  const searchTextFieldRef = useRef();
  const [userList, setUserList] = useState([]);
  const [searchedUser, setSearchUser] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLandingPageActive, setIsLandingPageActive] = useState(true);
  const [dataToSendToHomePage, setDataToSendToHomePage] = useState(null);
  const userSearchDisabled = !searchedUser || searchedUser.length < 3;
  const [openPopUp, setOpenPopUp] = useState(false);
  const handleOpen = () => setOpenPopUp(true);
  const handleClose = () => setOpenPopUp(false);
  const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "600px",
    minHeight: "200px",
    borderRadius: "10px",
    backgroundColor: "white",
    padding: "25px"
  };
  let AD_DB_USERS_DROPDOWN = [];

  useEffect(() => {
    setUserList(data);
  }, [data]);

  useEffect(() => {
    if (isLandingPageActive) {
      clearSearchBoxHandler();
      dispatch(resetUserAccountReducer());
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isLandingPageActive]);

  if (userList && Array.isArray(userList) && userList.length) {
    userList.map((user) => {
      let id, mail, employeeId, displayName;

      mail = user.isExist ? user.email : user.mail;
      id = user.isExist ? user.user_id : user.id;
      displayName = user.isExist ? user.display_name : user.displayName;
      employeeId = user.employeeId;

      const dropDown = {
        id: id,
        title: `${displayName} ${employeeId ? `( ${employeeId} )` : ""} ${
          mail ? mail : ""
        }`
      };

      AD_DB_USERS_DROPDOWN.push(dropDown);
    });
  }

  const searchUserHandler = () => {
    if (searchedUser) {
      setUserList([]);
      dispatch(searchUserResources(searchedUser.trim()));
      setAnchorEl(searchTextFieldRef.current);
      setOpen(true);
    }
  };

  const togglePage = () => {
    setIsLandingPageActive(!isLandingPageActive);
  };

  const clearSearchBoxHandler = () => {
    setSearchUser("");
    setSelectedUser(null);
  };

  const getParticularUser = (user) => {
    return userList.find((usr) => {
      return (
        usr?.id?.toString() === user?.id?.toString() ||
        usr?.user_id?.toString() === user?.id?.toString()
      );
    });
  };

  const selectedUserHandler = (user) => {
    setSelectedUser(user);
    setSearchUser(user.title);
    setOpen(false);
    setDataToSendToHomePage(getParticularUser(user));
    togglePage();
  };

  if (!isLandingPageActive)
    return <Home user={dataToSendToHomePage} togglePage={togglePage} />;

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        spacing={0}
        className="searchUser_Main"
      >
        <Grid item sm={12} md={5} className="searchUser_imageWrapper">
          <img
            src="/vam-vvmc-banner.png"
            alt="main-image"
            className="searchUser_mainImage"
          />
        </Grid>
        <Grid item sm={12} md={7} className="searchUser_welcomeSearchWrapper">
          <div className="searchUser_welcomeWrapper">
            <h1 className="searchUser_welcomeHeadline">
              Welcome to the Veeva Vault MedComms (VVMC) Account Management
              (VAM) system!
            </h1>
            <p className="searchUser_welcomeText">
              Use VAM to request a new VVMC account, or make changes to an
              existing account (e.g. change roles, deactivate account), Please
              refer to this{" "}
              <Link
                href="https://collab.lilly.com/:w:/r/sites/GMITrainingQuality/_layouts/15/Doc.aspx?sourcedoc=%7BE0E1F621-A55D-4AE4-9711-4722447BA229%7D&file=JOB%20AID-VVMC%20Access%20Request.docx&action=default&mobileredirect=true"
                target="_blank"
              >
                user guide
              </Link>{" "}
              for more details.
            </p>
          </div>
          <Link
            className="searchUser_nonOnboardedLink"
            onClick={handleOpen}
            underline="always"
            sx={{ p: 0 }}
          >
            Click here for request information
          </Link>
          <Modal
            open={openPopUp}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div style={ModalStyle}>
              <CancelOutlinedIcon
                className="accountSummary_crossModalIcon"
                onClick={() => handleClose()}
              />
              <div className="searchUser_instruction">
                <p className="searchUser_instructionText">
                  *VVMC account requests require secondary approval based on
                  request type (refer to guide for details). Requests will be
                  fulfilled within 1 business day of completed approvals. For
                  exceptions/urgent requests please contact the VVMC Helpdesk
                  directly.
                </p>
              </div>
            </div>
          </Modal>
          {/* <div className="searchUser_welcomeWrapper">
            <p className="searchUser_welcomeText mt-10">
              To begin select the Lilly user for this account request or for
              non-onboarded users (agency) click the link below.
            </p>
          </div> */}
          {adminAllRoles.includes(userRole) ? (
            <div className="searchUser_adminButtonWrapper">
              <Link href="/admin" underline="none">
                <Button variant="contained" className="searchUser_adminButton">
                  Admin Portal
                </Button>
              </Link>
            </div>
          ) : null}

          <div className="searchUser_searchWrapper">
            <span className="searchUser_searchText">Search User</span>
            <div className="searchUser_textFieldWithButton">
              <TextField
                id="search-user-input"
                size="small"
                variant="outlined"
                className="searchUser_textField"
                focused={false}
                placeholder="Type Name / Email Id To Search User"
                value={searchedUser}
                ref={searchTextFieldRef}
                onChange={(event) => {
                  setSearchUser(event.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    searchUserHandler();
                  }
                }}
                InputProps={{
                  sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
                  endAdornment: (
                    <React.Fragment>
                      {(selectedUser && selectedUser.title) ||
                      searchedUser.length ? (
                        <IconButton
                          edge="end"
                          size="medium"
                          className="searchUser_crossIcon"
                          onClick={clearSearchBoxHandler}
                        >
                          <ClearIcon />
                        </IconButton>
                      ) : null}
                    </React.Fragment>
                  )
                }}
              />
              <Button
                variant="contained"
                className={
                  !userSearchDisabled
                    ? "searchUser_searchButton"
                    : "searchUser_searchButtonDisabled"
                }
                disabled={userSearchDisabled}
                onClick={searchUserHandler}
              >
                <SearchOutlinedIcon size="medium" />
              </Button>
              <Popper open={open} anchorEl={anchorEl}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <Paper
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: "-14rem"
                    }}
                  >
                    {loading ? (
                      <div className="loader-container">
                        <CircularProgress />
                      </div>
                    ) : error ? (
                      <div className="error-container">
                        <p className="error-text">{error}</p>
                      </div>
                    ) : null}
                    {AD_DB_USERS_DROPDOWN && AD_DB_USERS_DROPDOWN.length ? (
                      <div
                        className={
                          AD_DB_USERS_DROPDOWN.length > 5
                            ? "searchUser_userList"
                            : ""
                        }
                      >
                        {AD_DB_USERS_DROPDOWN.map((user, index) => (
                          <MenuItem
                            key={index}
                            size="large"
                            onClick={() => {
                              selectedUserHandler(user);
                            }}
                            sx={{
                              width: `${
                                AD_DB_USERS_DROPDOWN.length > 5
                                  ? "30.5rem"
                                  : "31.5rem"
                              }`,
                              // whiteSpace: "normal",
                              fontSize: "0.95rem",
                              color: "#000000"
                            }}
                          >
                            {user.title && user.title.length > 55 ? (
                              <>
                                {user.title.substr(0, 55)}
                                <Tooltip
                                  title={user.title}
                                  arrow
                                  placement="left"
                                  componentsProps={{
                                    tooltip: {
                                      sx: {
                                        color: "#000",
                                        backgroundColor: "#fff",
                                        fontSize: "0.9rem",
                                        border: "2px solid #707070"
                                      }
                                    }
                                  }}
                                >
                                  <strong>...</strong>
                                </Tooltip>
                              </>
                            ) : (
                              user.title
                            )}
                          </MenuItem>
                        ))}
                      </div>
                    ) : null}
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </div>
            {/* <Link
              className="searchUser_nonOnboardedLink"
              onClick={onNonOnboardedLinkClick}
              underline="always"
              sx={{ p: 0 }}
            >
              Click here to raise New Account request for non-onboarded users
            </Link> */}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SearchUser;
