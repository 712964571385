import React, { useEffect, useState } from "react";
import ReassignAndTerminateTableHeader from "./ReassignAndTerminateTableHeader";
import ReassignAndTerminateTableBody from "./ReassignAndTerminateTableBody";
import { REASSIGN_AND_TERMINATE_TABLE_COLUMNS } from "./ReassignAndTerminateTableColumns";
import "./styles/ReassignAndTerminateTable.css";
import { useSelector } from "react-redux";
import { TableContainer, Paper } from "@mui/material";

const ReassignAndTerminateTable = ({
  searchKeyword,
  // setSearchKeyword,
  // filterOptions,
  setIsModalOpen,
  setActiveModalNotice
}) => {
  const { allRequest, filterOptions } = useSelector(state => {
    return {
      allRequest: state.ReassignAndTerminateReducer
        .ReassignAndTerminateRequestListReducer.data,
      filterOptions: state.ReassignAndTerminateReducer
        .ReassignAndTerminateRequestListReducer?.filterData
    };
  });

  const [rows, setRows] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    let tempRows = [];
    if (allRequest.length) {
      tempRows = [...allRequest];
    }
    setRows(tempRows);
  }, [allRequest]);

  useEffect(() => {
    let tempFilteredData = [...rows];
    setFilteredData(tempFilteredData);
  }, [rows]);

  useEffect(() => {
    let tempFilterData = [...rows];
    const filteredIndexSet = new Set();
    let filteredDataAvailable = false;

    // if (filterOptions?.length > 0) {
    //   filterOptions.forEach(filterOptionObj => {
    //     filterOptionObj.values.forEach(element => {
    //       if (element.selected) {
    //         filteredDataAvailable = true;
    //         switch (filterOptionObj.accessor) {
    //           case "affiliate_type":
    //             tempFilterData.map((dataObj, dataObjIdx) => {
    //               if (dataObj["affiliate_type"].toLowerCase() === element.name.toLowerCase())
    //                 filteredIndexSet.add(dataObjIdx);
    //             });
    //             break;
    //           // case "user_type":
    //           //   tempFilterData.map((dataObj, dataObjIdx) => {
    //           //     if (dataObj["user_type"].toLowerCase() === element.value.toLowerCase())
    //           //       filteredIndexSet.add(dataObjIdx);
    //           //   });
    //           //   break;
    //           case "request_type":
    //             tempFilterData.map((dataObj, dataObjIdx) => {
    //               if (dataObj["request_type"].toLowerCase() === element.value.toLowerCase())
    //                 filteredIndexSet.add(dataObjIdx);
    //             });
    //             break;
    //         }
    //       }
    //     });
    //   });
    // }

    if (filterOptions?.length > 0) {
      tempFilterData.filter((dataObj, dataObjIdx) => {
        if (dataObjRequestTypeSelected(dataObj["request_type"].toLowerCase()) && dataObjAffiliateTypeSelected(dataObj["affiliate_type"].toLowerCase())) {
          filteredIndexSet.add(dataObjIdx);
        }
      });
      filteredDataAvailable = true;
    }

    if (filteredDataAvailable) {
      tempFilterData = tempFilterData.filter((filterDataObj, filterDataObjIdx) => {
        if (filteredIndexSet.has(filterDataObjIdx))
          return true;
        return false;
      });
    }

    if (searchKeyword.length) {
      tempFilterData = tempFilterData.filter(filterDataObj => {
        return Object.values(filterDataObj).join(" ").toLowerCase().includes(searchKeyword.toLowerCase());
      });
    }

    setFilteredData(tempFilterData);
    // eslint-disable-next-line
  }, [rows, searchKeyword, filterOptions]);

  const dataObjRequestTypeSelected = (dataObjRequestType) => {
    const selectedFilter = filterOptions[1]?.values?.filter(valArr =>
      valArr.selected);
    if (selectedFilter.length > 0) {
      return selectedFilter?.some(selectedFilter => {
        if (selectedFilter?.value?.toLowerCase() === dataObjRequestType) {
          return true;
        } return false;
      });
    } else {
      return true;
    }

  };

  const dataObjAffiliateTypeSelected = (dataObjAffiliateType) => {
    const selectedFilter = filterOptions[0]?.values?.filter(valArr =>
      valArr.selected);
    if (selectedFilter.length > 0) {
      return selectedFilter?.some(selectedFilter => {
        if (selectedFilter?.name?.toLowerCase() === dataObjAffiliateType) {
          return true;
        } return false;
      });
    } else {
      return true;
    }

  };


  const sortHandler = (sortField, sortBy) => {
    if (sortField) {
      const sorted = [...filteredData].sort((a, b) => {
        if (a[sortField] === "") return 1;
        if (b[sortField] === "") return -1;
        if (a[sortField] === "" && b[sortField] === "") return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true
          }) * (sortBy === "asc" ? 1 : -1)
        );
      });
      setFilteredData(sorted);
    }
  };
  return (
    <div className='terminateTableContainer'>
      <div className="terminateTableHeader">
        <span>Existing Requests</span>
      </div>
      <div className='terminateTableSectionContainer'>
        <div className='terminateTableSection'>
          <TableContainer sx={{
            maxHeight: "65vh",
            "::-webkit-scrollbar": {
              height: "4px"
            }, overflowX: "auto"
          }} component={Paper} >
            <table className="reassignAndTerminate_recordTable">
              <ReassignAndTerminateTableHeader
                columns={REASSIGN_AND_TERMINATE_TABLE_COLUMNS.columns}
                sortHandler={sortHandler}
              />
              <ReassignAndTerminateTableBody
                rows={filteredData}
                columns={REASSIGN_AND_TERMINATE_TABLE_COLUMNS.columns}
                setIsModalOpen={setIsModalOpen}
                setActiveModalNotice={setActiveModalNotice}
              />
            </table>
          </TableContainer>
        </div>
      </div></div>
  );
};

export default ReassignAndTerminateTable;
