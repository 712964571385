import { Paper, Table, TableBody, TableContainer, TableRow, TableHead } from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { visuallyHidden } from '@mui/utils';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import { toLower } from 'lodash';

import "./styles/Trainings.css";
import { useSelector } from 'react-redux';
// import { deleteGroupRequest } from '../../../../../../redux/actions/adminAccount-actions/group-actions';



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#f9ccb5',
        color: theme.palette.common.black,
        fontWeight: 600,
        fontSize: 14,
        borderRight: '1px solid #80808040',
        borderBottom: '0px',
        padding: 3
        // paddingLeft: '0.3rem'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        color: 'black',
        fontWeight: 400,
        borderRight: '1px solid #80808040',
        borderBottom: '0px',
        padding: 3
        // paddingLeft: '0.3rem'
    }
}));
const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'rgb(0 255 205 / 4%)'
    }
    // hide last border
    // '&:last-child td, &:last-child th': {
    //     border: 0
    // }
}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'trainingsId',
        label: "Training ID/ Curriculum ID"
    },
    {
        id: 'trainingsName',
        label: "Training Name"
    },
    {
        id: 'isCurriculam',
        label: 'Is Curriculum'
    }

];
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead >
            <StyledTableRow >
                {headCells.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
                <StyledTableCell>Action</StyledTableCell>
            </StyledTableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired
};

const TrainingsTable = ({ openTrainingsModal, searchText }) => {
    // const dispatch = useDispatch();
    const [rows, setRows] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [order, setOrder] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const { trainingsData } = useSelector((state) => ({
        trainingsData: state?.TrainingsReducer?.trainingsData
    }));

    const handleEdit = (rowData) => {
        openTrainingsModal(rowData);
    };

    // const handleDelete = (trainingsId) => {
    //     dispatch(deleteTrainingsRequest(trainingsId));
    // };

    useEffect(() => {
        let rows = [];
        if (trainingsData?.length > 0) {
            trainingsData.forEach(element => {
                let row = element;
                // row.action = <Stack direction={"row"} spacing={1}>
                //     <EditOutlinedIcon onClick={() => handleEdit(row)} />
                //     <DeleteForeverOutlinedIcon onClick={() =>handleDelete(row.id)} />
                // </Stack>;
                rows.push(row);
            });
            setRows(rows);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trainingsData]);

    useEffect(() => {
        let tempFilterData = [...rows];
        setFilterData(tempFilterData);
    }, [rows]);

    //search filter
    useEffect(() => {
        let tempFilterData = [...rows];
        if (searchText?.length > 0) {
            tempFilterData = tempFilterData.filter((obj) => {
                return toLower(obj.trainingName).includes(toLower(searchText));
            });
        }
        setFilterData(tempFilterData);
    }, [rows, searchText]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const visibleRows = useMemo(() =>
        stableSort(filterData, getComparator(order, orderBy)),
        [order, orderBy, filterData]
    );
    const trHeigth = 27;
    const tableHeigth = (filterData.length * trHeigth) + trHeigth;
    console.log(tableHeigth);
    return (
        <div className='trainingsTableContainer'>
            <div className="trainingTableHeader">
                <span>Existing Trainings</span>
            </div>
            <div className='trainingTableSectionContainer'>
                <div className='trainingTableSection'>
                    <TableContainer sx={{ maxHeight: "68vh" }} component={Paper} >
                        <Table stickyHeader>
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                            />
                            <TableBody>
                                {visibleRows && visibleRows.map((row) => (
                                    <StyledTableRow key={row.id} >
                                        {/* <StyledTableCell component="th" scope='row' >{row.id}</StyledTableCell>    */}
                                        <StyledTableCell style={{ width: '200px' }}>{row.id}</StyledTableCell>
                                        <StyledTableCell >{row.trainingName}</StyledTableCell>
                                        <StyledTableCell style={{ width: '120px' }} >{row.isCurriculam ? 'True' : 'False'}</StyledTableCell>
                                        <StyledTableCell style={{ width: '70px' }} align='center'>
                                            <EditOutlinedIcon className={"clickable"} alignItems={"center"} onClick={() => handleEdit(row)} />
                                            {/* <VisibilityIcon className={"clickable"} alignItems={"center"} onClick={() => handleEdit(row)} /> */}
                                            {/* <DeleteForeverOutlinedIcon className={"clickable"} onClick={() => handleDelete(row.id)} /> */}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                                {visibleRows?.length <= 0 ? <StyledTableRow key={"noRecordsFound"}>
                                    <StyledTableCell key="noRecordsFound" style={{
                                        textAlign: "center"
                                    }} colSpan={4}>No records found!
                                    </StyledTableCell>
                                </StyledTableRow> : ''}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div >
    );
};
export default TrainingsTable;