import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import "./styles.css";

const SelectDropdownInput = (props) => {
  const { id, label, selectedOption, options, className, type, ...rest } =
    props;
  return (
    <div>
      <label htmlFor={id} className="displayLabelSelectDropdown">
        {label}
      </label>
      <FormControl className="dropdownSelectInput" size="small">
        <InputLabel id={id}></InputLabel>
        <Select
          labelId={id}
          id="select"
          value={selectedOption === null ? "" : selectedOption}
          {...rest}
          sx={{ fontSize: "0.9rem", backgroundColor: "white" }}
          className={`dropdownSelectInput ${className}`}
        >
          {options.map((option, index) => (
            <MenuItem
              key={index}
              value={option?.value}
              className={
                type === "userDetailsMenuList"
                  ? "menuListItem"
                  : "summaryModalMenuList"
              }
            >
              {option?.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectDropdownInput;
