import React, { useEffect, useState } from "react";
// import ClearIcon from "@mui/icons-material/Clear";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "./styles/Roles.css";
import InputBase from "@mui/material/InputBase";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton, Paper } from "@mui/material";
import { CSVLink } from "react-csv";
import RolesTable from "./RolesTable";
import { useDispatch, useSelector } from "react-redux";
import { rolesRequestListCall } from "../../../../../../redux/actions/adminAccount-actions/roles-action/rolesRequestList-action";
import RolesModal from "./Modals/RolesModal";
import RolesFilterModalNotice from "./Modals/RolesFilterModalNotice";
import AddUpdateRoleModalNotice from "./Modals/AddUpdateRoleModalNotice";
import { groupsRequestListCall } from "../../../../../../redux/actions/adminAccount-actions/roles-action/groupsRequestList-action";
import { trainingsRequestListCall } from "../../../../../../redux/actions/adminAccount-actions/roles-action/trainingsRequestList-action";
import MessageModalNotice from "./Modals/MessageModalNotice";
import {
  deleteRoleRequestCall,
  rolesRequestReset
} from "../../../../../../redux/actions/adminAccount-actions/roles-action/rolesRequest-action";
// import CircularProgressLoader from "../../../../../../components/common/CircularProgressLoader";
import { getAllAffilateData } from "../../../../../../redux/actions/adminAccount-actions/affiliate-action";
import AdminMainArticleLoader from "../../AdminMainArticleLoader/AdminMainArticleLoader";

const Roles = () => {
  const dispatch = useDispatch();
  const {
    allRoles: { loading, allRoles, filteredOptionsInitialState, csvData },
    allAffiliteData,
    allGroups,
    allTrainings,
    // allDAC,
    roleRequest: { isSuccess, isRequestCompleted, message }
  } = useSelector((state) => {
    return {
      allRoles: {
        loading: state.RolesReducer.RolesListReducer.loading,
        allRoles: state.RolesReducer.RolesListReducer.data,
        filteredOptionsInitialState:
          state.RolesReducer.RolesListReducer.filterOptions,
        csvData: state.RolesReducer.RolesListReducer.csvData
      },
      allGroups: state.RolesReducer.GroupsRequestListReducer.data,
      allTrainings: state.RolesReducer.TrainingsRequestListReducer.data,
      allAffiliteData: state.AdminAffiliateReducer.data,
      roleRequest: {
        isSuccess: state.RolesReducer.RolesRequestReducer.isSuccess,
        isRequestCompleted:
          state.RolesReducer.RolesRequestReducer.isRequestCompleted,
        message: state.RolesReducer.RolesRequestReducer.message
      }
    };
  });
  const [searchInput, setSearchInput] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [modalMessage, setModalMessage] = useState([]);
  const [twoButton, setTwoButton] = useState(false);
  const [mode, setMode] = useState("");
  const [activeModalNotice, setActiveModalNotice] = useState("");
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");

  useEffect(() => {
    dispatch(rolesRequestListCall());
    dispatch(groupsRequestListCall());
    dispatch(trainingsRequestListCall());
    dispatch(getAllAffilateData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilterOptions(filteredOptionsInitialState);
  }, [filteredOptionsInitialState]);

  const clearSearchBoxHandler = () => {
    setSearchInput("");
    setSearchKeyword("");
  };

  const searchInputHandler = () => {
    setSearchKeyword(searchInput);
  };

  const filterActionHandler = () => {
    setIsModalOpen(true);
    setActiveModalNotice("filter");
  };

  const addRolesActionHandler = () => {
    setActiveModalNotice("addUpdate");
    setMode("create");
    setIsModalOpen(true);
  };

  const buttonOneClick = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isRequestCompleted) {
      switch (mode) {
        case "create":
          if (isSuccess) {
            setIsError(false);
            setModalMessage([
              "Role successfully created.",
              "Please create modify request to",
              " attach this role with Groups, Trainings."
            ]);
          } else {
            setIsError(true);
            setModalMessage(message);
          }
          setTwoButton(false);
          setIsModalOpen(true);
          break;
        case "delete":
          if (isSuccess) {
            setIsError(false);
            setModalMessage(["Role successfully deleted."]);
          } else {
            setIsError(true);
            setModalMessage(message);
          }
          setTwoButton(false);
          setIsModalOpen(true);
          break;
        case "edit":
          if (isSuccess) {
            setIsError(false);
            setModalMessage(["Role successfully modified."]);
          } else {
            setIsError(true);
            setModalMessage(message);
          }
          setTwoButton(false);
          setIsModalOpen(true);
          break;
        default:
          setIsError(false);
          setModalMessage([]);
          setTwoButton(false);
          setIsModalOpen(false);
      }
      dispatch(rolesRequestReset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestCompleted]);

  const deleteRoleHandler = () => {
    dispatch(deleteRoleRequestCall(selectedRole.vamRoleId));
  };

  const buttonTwoClick = () => {
    if (mode === "delete") {
      deleteRoleHandler();
    }
    setIsModalOpen(false);
  };

  function renderModalNotice() {
    switch (activeModalNotice) {
      case "filter":
        return (
          <RolesFilterModalNotice
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            filteredOptionsInitialState={filteredOptionsInitialState}
          />
        );
      case "addUpdate":
        return (
          <AddUpdateRoleModalNotice
            isModalOpen={isModalOpen}
            selectedRole={selectedRole}
            allRoles={allRoles}
            allGroups={allGroups}
            allTrainings={allTrainings}
            allAffiliteData={allAffiliteData}
            setActiveModalNotice={setActiveModalNotice}
            setIsModalOpen={setIsModalOpen}
            mode={mode}
            setMode={setMode}
          />
        );
      case "message":
        return (
          <MessageModalNotice
            error={isError}
            success={!isError}
            twoButton={twoButton}
            setIsModalOpen={setIsModalOpen}
            message={modalMessage}
            buttonOneClick={buttonOneClick}
            buttonTwoClick={buttonTwoClick}
          />
        );
    }
  }

  if (loading) {
    return <AdminMainArticleLoader />;
  }

  return (
    <div className="roles_container">
      <RolesModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
        {renderModalNotice()}
      </RolesModal>

      <div className="roles_searchFilterDownload">
        <div className="rolesSearchUser_textFieldWithButton">
          <Paper
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              width: 300,
              height: 35,
              borderRadius: "5px 0px 0px 5px",
              boxShadow: 0
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1, fontSize: "15px" }}
              placeholder="Search By Role Name"
              inputProps={{ "aria-label": "search by full name" }}
              value={searchInput}
              onChange={(event) => {
                setSearchInput(event.target.value);
                if (event.target.value === "") {
                  clearSearchBoxHandler();
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  e.target.blur();
                  searchInputHandler();
                }
              }}
            />
            {searchInput.length > 0 ? (
              <IconButton
                type="button"
                sx={{ p: "5px" }}
                aria-label="search"
                onClick={clearSearchBoxHandler}
              >
                <CloseIcon sx={{ fontSize: "1.6rem" }} />
              </IconButton>
            ) : null}
          </Paper>
          <Button
            variant="contained"
            className={"roleSearchUser_searchButton"}
            onClick={searchInputHandler}
          >
            <SearchOutlinedIcon size="medium" className="searchIcon" />
          </Button>
        </div>
        <div className="roles_FilterDownload">
          <Button
            variant="contained"
            className="btn btn--filter roles_addBtn"
            sx={{
              height: 35,
              width: 90
            }}
            onClick={addRolesActionHandler}
          >
            Add
          </Button>
          <Button
            variant="contained"
            className="btn btn--filter"
            onClick={filterActionHandler}
          >
            <FilterAltOutlinedIcon className="filterIcon" />
          </Button>
          <CSVLink
            data={csvData.data ?? []}
            headers={csvData.headers ?? []}
            filename="roles.csv"
          >
            <Button variant="contained" className="btn btn--download">
              <FileDownloadOutlinedIcon className="donwloadIcon" />
              <span>.csv</span>
            </Button>
          </CSVLink>
        </div>
      </div>
      <RolesTable
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        filterOptions={filterOptions}
        setIsModalOpen={setIsModalOpen}
        setSelectedRole={setSelectedRole}
        setMode={setMode}
        setIsError={setIsError}
        setTwoButton={setTwoButton}
        setModalMessage={setModalMessage}
        setActiveModalNotice={setActiveModalNotice}
      />
    </div>
  );
};

export default Roles;
