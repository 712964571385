import { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SelectDropdownInput from "../../components/common/SelectDropdownInput";
import { useDispatch } from "react-redux";
import { setSelectAffiliate } from "../../redux/actions/userAccount-actions/selectAffiliate-action";
import {
  setSelectedWorkFlowRoles,
  setUserAccessRolesModified,
  setworkFlowRoles
} from "../../redux/actions/userAccount-actions/workFlowRoles-action";

export const SelectAffiliateSection = (props) => {
  const dispatch = useDispatch();
  const {
    selectAffiliate,
    // userDetails,
    allAffiliatesResources,
    userAccessResources,
    allAffiliates,
    completeState
  } = props;

  const [selectedAffiliate, setSelectedAffiliate] = useState("");
  const [affAbbreviation, setAffAbbreviation] = useState("");
  // const userDeactivated = userDetails.deactivated;
  const selectedRoles = completeState.WorkFlowRolesReducer?.selectedRoles;
  const userAccessRolesModified =
    completeState.WorkFlowRolesReducer?.userAccessRolesModified;

  let userAccessRoles = [];
  let ALL_AFFILIATES_DROPDOWN = [];

  // console.log("allAff", allAffiliates);
  // console.log("allAffRe", allAffiliatesResources);

  function getAffiliateWorkFlowRoles(selectedAffiliate) {
    const roles =
      allAffiliatesResources?.find(
        (affiliate) => affiliate.affiliateType.name === selectedAffiliate
      )?.roles ?? [];
    return roles;
  }

  /*

  function getAffiliateNameFromAbbr(affiliateAbbr) {
    return (
      allAffiliatesResources?.find(
        (affiliate) => affiliate.affiliateType.abbreviation === affiliateAbbr
      )?.affiliateType?.name ?? ""
    );
  }
  
  */

  function getAffiliateAbbrFromName(selectedAffiliate) {
    return (
      allAffiliatesResources?.find(
        (affiliate) => affiliate.affiliateType.name === selectedAffiliate
      )?.affiliateType?.abbreviation ?? ""
    );
  }

  if (
    userAccessResources &&
    Array.isArray(userAccessResources) &&
    userAccessResources.length &&
    allAffiliatesResources &&
    Array.isArray(allAffiliatesResources) &&
    allAffiliatesResources.length
  ) {
    userAccessResources.map((resource) => {
      const affiliate = allAffiliatesResources.find(
        (affiliate) =>
          affiliate?.affiliateType?.abbreviation ===
          resource?.user?.vamHubAbbreviation
      );
      const affiliateType = affiliate?.affiliateType?.name;

      resource.roles.map((role) => {
        const roleMapToWorkFlowType = {
          workFlowType: affiliateType,
          role: role
        };
        userAccessRoles.push(roleMapToWorkFlowType);
      });
    });
  }

  // const userAffiliateAccess = userAccessResources?.map(
  //   (aff) => aff.user.vamHubAbbreviation
  // );

  useEffect(() => {
    //Default set up - Global Affiliate
    let GLOBAL = "Global";
    let GLOBAL_ABBR = "GL";

    if (
      userAccessResources &&
      Array.isArray(userAccessResources) &&
      userAccessResources.length
    ) {
      //edit
      setSelectedAffiliate(
        selectAffiliate.selectedAffiliateType.length
          ? selectAffiliate.selectedAffiliateType
          : // : userDeactivated
            //   ? getAffiliateNameFromAbbr(userAffiliateAccess[0])
            GLOBAL
      );
      setAffAbbreviation(
        selectAffiliate.selectedAffiliateTypeAbbrebiation.length
          ? selectAffiliate.selectedAffiliateTypeAbbrebiation
          : // : userDeactivated
            //   ? userAffiliateAccess[0]
            GLOBAL_ABBR
      );
      if (!userAccessRolesModified) {
        dispatch(setSelectedWorkFlowRoles(userAccessRoles));
      }
    } else {
      //first load and create
      setSelectedAffiliate(
        selectAffiliate.selectedAffiliateType.length
          ? selectAffiliate.selectedAffiliateType
          : GLOBAL
      );
      setAffAbbreviation(
        selectAffiliate.selectedAffiliateTypeAbbrebiation.length
          ? selectAffiliate.selectedAffiliateTypeAbbrebiation
          : GLOBAL_ABBR
      );
    }
    //common case
    dispatch(
      setworkFlowRoles({
        workFlowType: selectAffiliate.selectedAffiliateType.length
          ? selectAffiliate.selectedAffiliateType
          : // : userDeactivated
            //   ? getAffiliateNameFromAbbr(userAffiliateAccess[0])
            GLOBAL,
        roles: getAffiliateWorkFlowRoles(
          selectAffiliate.selectedAffiliateType.length
            ? selectAffiliate.selectedAffiliateType
            : // : userDeactivated
              //   ? getAffiliateNameFromAbbr(userAffiliateAccess[0])
              GLOBAL
        )
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //affiliate dropdown
  if (allAffiliates && Array.isArray(allAffiliates) && allAffiliates.length) {
    allAffiliates.map((affiliate) => {
      const dropDown = {
        id: affiliate.abbreviation,
        title: affiliate.name,
        value: affiliate.name
      };
      ALL_AFFILIATES_DROPDOWN.push(dropDown);
    });
  }

  const modifiedAffiliate = JSON.stringify({
    selectedAffiliateType: selectedAffiliate,
    selectedAffiliateTypeAbbrebiation: affAbbreviation
  });

  useEffect(() => {
    const modifiedAffiliateParsed = JSON.parse(modifiedAffiliate);
    const payload = { ...modifiedAffiliateParsed };
    dispatch(setSelectAffiliate(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedAffiliate]);

  const modifiedWorkFlow = JSON.stringify({
    roles: selectedRoles
  });

  useEffect(() => {
    if (userAccessRolesModified) {
      const modifiedWorkFlowParsed = JSON.parse(modifiedWorkFlow);
      const payload = modifiedWorkFlowParsed.roles;
      dispatch(setSelectedWorkFlowRoles([...payload]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedWorkFlow]);

  const selectedAffiliateHandler = (event) => {
    //common case
    const selectedAffiliate = event.target.value;
    dispatch(
      setworkFlowRoles({
        workFlowType: selectedAffiliate,
        roles: getAffiliateWorkFlowRoles(selectedAffiliate)
      })
    );
    dispatch(setSelectedWorkFlowRoles([]));
    dispatch(setUserAccessRolesModified(false));
    setSelectedAffiliate(selectedAffiliate);
    setAffAbbreviation(getAffiliateAbbrFromName(selectedAffiliate));
    if (
      userAccessResources &&
      Array.isArray(userAccessResources) &&
      userAccessResources.length
    ) {
      dispatch(setSelectedWorkFlowRoles(userAccessRoles));
    }
  };

  return (
    <>
      {/* Select affiliate section */}
      <div className="selectAffiliateCard">
        <div className="selectAffiliateCard_header">
          <div className="affiliate_textInput">
            <span>Select Affiliate</span>
            <span style={{ marginTop: "-0.5rem" }}>
              <SelectDropdownInput
                id="affiliate"
                label=""
                className="selectAffiliate_dropdown"
                sx={{ background: "white" }}
                options={ALL_AFFILIATES_DROPDOWN}
                selectedOption={selectedAffiliate}
                onChange={selectedAffiliateHandler}
              />
            </span>
          </div>

          {/* Select affiliate tooltip initially hidden */}
          <div className={"affiliate_InfoDetails tooltip"}>
            <InfoOutlinedIcon />
            <div className="tooltipContent">
              <div className="afffiliate_quickNoteGuidelines">
                <div className="affiliate_quckNote">
                  <h4>Quick Note</h4>
                  {/* <div className="affiliate_globalUS">
                    <div className="affiliate_global">
                      <div className="affiliate_globalCol">
                        <span className="affiliate_globalCol_head">Global</span>
                      </div>
                      <div className="affiliate_globalCol">
                        Own, Coordinate, Approve/ Review Materials For Global
                        Brand Teams (Excludes Affiliates)
                      </div>
                    </div>
                    <div className="affilicate_US">
                      <div className="affiliate_globalCol_head">
                        <span className="affiliate_globalCol_US">US</span>
                      </div>
                      <div className="affiliate_globalCol">
                        Own, Coordinate, Approve/ Review Materials For
                        FCAP/PCA/Key Content
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="affiliate_guideLines">
                  <p
                    className="affiliate_guideLinesText"
                    style={{ paddingTop: "0px", marginTop: "0px" }}
                  >
                    <span className="affiliate_stars">**</span> If a user needs
                    access to perform tasks for more than one affiliate or
                    region, they will need to complete a request for both in two
                    separate requests.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
